
import React, { useEffect, useLayoutEffect, useState } from 'react';
import {
    Box,
} from '@material-ui/core';
import { find, isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client'
import { GET_LOOKUP } from '../../graphql/Queries/getGlobalLookup'
import { getIsImpersonated } from '../../store/user/user.selectors';
import './banner.scss';
import ImpersonationHeader from '../Impersonation/ImpersonationHeader';
import OutageBanner from '../OutageBanner/OutageBanner';

const adjustPosition = () => {
    const bannerHeight = document?.getElementById('banner-container')?.offsetHeight;
    if (document.getElementById('navbar-container')) {
        const div: HTMLDivElement = document.getElementById('navbar-container') as HTMLDivElement;
        div.style.top = bannerHeight + 'px';
    }
    if (document.getElementById('navbar-container-exp')) {
        const div: HTMLDivElement = document.getElementById('navbar-container-exp') as HTMLDivElement;
        div.style.top = bannerHeight + 'px';
    }

    if (document.getElementById('app-container')) {
        const div: HTMLDivElement = document.getElementById('app-container') as HTMLDivElement;
        div.style.top = bannerHeight + 'px';
    }

    if (document.getElementById('navbar-toggle-container')) {
        const div: HTMLDivElement = document.getElementById('navbar-toggle-container') as HTMLDivElement;
        div.style.top = `${(bannerHeight ?? 0) + 24}px`;
    }

    if (document.getElementById('header-profile-section')) {
        const div: HTMLDivElement = document.getElementById('header-profile-section') as HTMLDivElement;
        div.style.top = bannerHeight + 'px';
    }
}

interface BannerProps {
    key: string,
    component: React.FunctionComponent,
    priority: number,
}

const Banner = () => {
    const isImpersonated = useSelector(getIsImpersonated);
    const [banners, setBanners] = useState<BannerProps[]>([]);
    const [outageBannerMessage, setOutageBannerMessage] = useState([])
    const { data: lookUpArray } = useQuery(GET_LOOKUP)
    const outageBannerStatus = localStorage.getItem('outageBannerStatus');

    useEffect(() => {
        if (lookUpArray?.getAllGloballookups) {
            setOutageBannerMessage((lookUpArray?.getAllGloballookups ?? []).filter(
                (item: any) => item.lookupid === 'Bannermessage'
            ))
        }
    }, [lookUpArray])

    useEffect(() => {
        if (lookUpArray?.getAllGloballookups && isEmpty((lookUpArray?.getAllGloballookups ?? []).filter(
            (item: any) => item.lookupid === 'Bannermessage'
        ))) {
            localStorage.removeItem('outageBannerStatus')
        }
    }, [lookUpArray])
    
    useLayoutEffect(() => {
        adjustPosition();
    });

    useEffect(() => {
        window.addEventListener('resize',  () => { adjustPosition(); });
        return () => document.removeEventListener("resize", adjustPosition);
    }, []);

    useEffect(() => {
        if(!isEmpty(outageBannerMessage) && outageBannerStatus !== 'hide') {
            setBanners(prev => find(prev, {key: 'outage'}) ? prev : [...prev, {
                key: 'outage',
                component: () => <OutageBanner onClose={() => removeBanner('outage')} outageBannerMessage={outageBannerMessage} />,
                priority: 10,
            }]);
        }
        if (isImpersonated) {
            setBanners(prev => find(prev, {key: 'impersonation'}) ? prev : [...prev, {
                key: 'impersonation',
                component: ImpersonationHeader,
                priority: 100,
            }]);
        }
        else {
            setBanners(prev => prev.filter(item => item.key !== 'impersonation'));
        }
    }, [isImpersonated, outageBannerMessage, outageBannerStatus]);

    const removeBanner = (key: string) => {
        setBanners(prev => prev.filter(item => item.key !== key));
    }

    return (
        <Box px={4} className='banner-container' data-testid='mpc-app-banner' id='banner-container'>
            {banners.sort((a, b) => b.priority - a.priority ).slice(0, 2).map((item, index) => {
                const Component = item.component;
                return <Component key={index} />
            })}
            
        </Box>
    );
}
export default Banner;