import { gql } from '@apollo/client';

export const GET_LOOKUP = gql`
    query{
        getAllGloballookups {
            lookupid,
            lookupchar,
            lookupval,
            lookupnumrangemax,
            lookupnumrangemin
        }
    }
`;