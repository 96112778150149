import { gql } from '@apollo/client';

export const IMPERSONATE = gql`
    mutation impersonation(
        $roleType: String
        $impersonateEmail: String
        $salesforcecustomerno: String
    ) {
        impersonation(
            roleType: $roleType,
            impersonateEmail: $impersonateEmail,
            salesforcecustomerno: $salesforcecustomerno
    )
}`