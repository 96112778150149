export const NotificationTemplates = {
    CreatedOrderToCustomer: "CreatedOrderToCustomer",
    CreatedOrderToPlanner: "CreatedOrderToPlanner",
    CreatedOrderToCarrier: "CreatedOrderToCarrier",
    AssignCarrier: "AssignCarrier",
    TenderUpdateToCarrier: "TenderUpdateToCarrier",
    TenderUpdateToCustomer: "TenderUpdateToCustomer",
    CarrierConfirmed: "CarrierConfirmed",
    CarrierReConfirmToCarrier: "CarrierReConfirmToCarrier",
    CarrierReConfirmToCustomer: "CarrierReConfirmToCustomer",
    CancelledToCarrier: "CancelledToCarrier",
    CancelledToCustomer: "CancelledToCustomer",
    CancelledToPlanner: "CancelledToPlanner",
    BulkUploadToCustomer: "BulkUploadToCustomer",
    BulkUploadToPlanner: "BulkUploadToPlanner",
    PalletsSpacesDifferenceToCustomer: "PalletsSpacesDifferenceToCustomer",
    PalletsSpacesDifferenceToCarrier: "PalletsSpacesDifferenceToCarrier",
    PalletsSpacesDifferenceToPlanner: "PalletsSpacesDifferenceToPlanner",
    PendingMoreThanOneHourToCarrier: "PendingMoreThanOneHourToCarrier",
    PendingMoreThanOneHourToPlanner: "PendingMoreThanOneHourToPlanner",
    DeliveryDateOrTimeChangedToCustomer: "DeliveryDateOrTimeChangedToCustomer",
    DeliveryDateOrTimeChangedToCarrier: "DeliveryDateOrTimeChangedToCarrier",
    ProductDamagedToCustomer: "ProductDamagedToCustomer",
    ProductDamagedToPlanner: "ProductDamagedToPlanner",
    ProductDamagedToCarrier: "ProductDamagedToCarrier",
    DeliveredToCustomer: "DeliveredToCustomer",
    DeliveredToPlanner: "DeliveredToPlanner",
    POPToPlanner: "POPToPlanner",
    POPToCustomer: "POPToCustomer",
    CarrierConfirmedToDCUser: "CarrierConfirmedToDCUser",
    CarrierReConfirmToDCUser: "CarrierReConfirmToDCUser",
    CarrierDeclinedToPlanner: "CarrierDeclinedToPlanner",
    CarrierDeclinedToCustomer: "CarrierDeclinedToCustomer",
    TMSTenderLoadCancel: "TMSTenderLoadCancel"
}