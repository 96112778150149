import { gql } from '@apollo/client';

export const GET_LOAD_LIST_COUNT = gql`
query(
  $filterCommodity: [String]
  $filterCustomers: [String]
  $loadCollectionStartDate: String
  $loadCollectionEndDate: String
  $loadDeliveryStartDate: String
  $isTenderActioned: Boolean
  $filterOrigins: [String]
  $filterDestinations: [String]
  $filterLoadStatus: [String]
  $loadDeliveryEndDate: String
  $isFavourite: Boolean
  $searchKeyWord: String
  $isPodNotSubmitted: Boolean,
  $missingPodDocument: Boolean,
  $variationsInPallets: Boolean,
  $variationsInSpaces: Boolean,
  $collectionDateTimeNotConfirmed: Boolean,
  ) {
    getLoadListCount(
        filterCommodity: $filterCommodity
        filterCustomers: $filterCustomers
        loadCollectionStartDate:  $loadCollectionStartDate
        loadCollectionEndDate: $loadCollectionEndDate
        loadDeliveryStartDate: $loadDeliveryStartDate
        isTenderActioned: $isTenderActioned
        filterOrigins: $filterOrigins
        filterDestinations: $filterDestinations
        filterLoadStatus: $filterLoadStatus
        loadDeliveryEndDate: $loadDeliveryEndDate
        isFavourite: $isFavourite
        searchKeyWord: $searchKeyWord
        isPodNotSubmitted: $isPodNotSubmitted
        missingPodDocument: $missingPodDocument
        variationsInPallets: $variationsInPallets
        variationsInSpaces: $variationsInSpaces
        collectionDateTimeNotConfirmed: $collectionDateTimeNotConfirmed
    ) 
}
`;