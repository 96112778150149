import React, { useState, useRef } from 'react';
import Logo from '../../assets/Primary_Connect_Logo_Mini.png';
import { NavLink, useLocation } from "react-router-dom";
import 'remixicon/fonts/remixicon.css';
import './NavBar.scss';
import { navItems } from './NavItems';
import { useHasPermission } from '../utils/permission/';
import { GetDefaultUrl } from '../utils/helper';
import { useSelector } from 'react-redux';
import { getHomePage, selectUserDetails } from '../../store/user/user.selectors';
import Tooltip from './Tooltip/Tooltip';

interface NavBarExpandedProps {
    small?: boolean
}

const NavBarExpanded = ({ small }: NavBarExpandedProps) => {
    const homepage = useSelector(getHomePage);
    const userDetails = useSelector(selectUserDetails);
    const [subMenuOpen, setSubMenuOpen] = useState(false);
    const [showNavBar, setShowNavBar] = useState(!small);
    const [indexVal, setIndexVal] = useState(0);
    const toggleRef = useRef() as React.MutableRefObject<HTMLLIElement>;
    const { hasSectionPermission } = useHasPermission();

    let defaultState: string = '';
    const { pathname } = useLocation();
    if (pathname.includes('/booking/report')) {
        defaultState = 'report';
    }else if (pathname.includes('/booking') || pathname === '/preplanning') {
        defaultState = 'bookings';
    }
    else if (pathname.includes('/loads')){
         defaultState = 'bookings';
    }
    else if (pathname.includes('/report')) {
        defaultState = 'report';
    }
    else if (pathname.includes('/admin/leadtime')) {
        defaultState = 'bookings';
    }
    else if (pathname.includes('/admin/gatehouse')) {
        defaultState = 'gatehouse';
    }
    else if (pathname.includes('/admin/watchlist')) {
        defaultState = 'watchlist';
    }
    else if (pathname.includes('/admin')) {
        defaultState = 'admin';
    } else if (pathname.includes('/gatehouse')) {
        defaultState = 'truck-booking';
    }
    else {
        defaultState = 'dashboard';
    }


    const toggleSubMenu = (index: number) => {
        setSubMenuOpen(index === indexVal ? !subMenuOpen : true);
        setIndexVal(index)
    }

    const resetNavStates = () => {
        small && setShowNavBar(false);
        setSubMenuOpen(false);
    }

    return (
        <div className='navbar-expanded'>
            {small &&
                <div className='navbar-toggle-container' id="navbar-toggle-container">
                    <button data-testid='navbar-toggle-container' onClick={() => setShowNavBar(true)}><i className='ri-menu-fill'></i></button>
                </div>
            }
            {showNavBar &&
                <div data-testid={small ? 'navbar-container-small' : 'navbar-container'} className='navbar-container' id="navbar-container-exp" >
                    <div className='navbar-expanded-container-header'>
                        {small && <div onClick={() => resetNavStates()} data-testid='nav-menu-close' className='nav-container-close'><i className='ri-close-fill'></i></div>}
                        <NavLink onClick={() => resetNavStates()} exact to={GetDefaultUrl(homepage)}>
                            <Tooltip position={{ top: 15, left: 118 }} textVariant='body1' theme='light' content="Home" dataTestId={'Home-tooltip'} >
                                <img className='primary-connect-logo' alt='Primary connect logo' src={Logo} />
                            </Tooltip>
                        </NavLink>
                    </div>
                    <ul className='navbar-large'>
                    {navItems?.map((item, index) => {
                        if (!item.permissions || item.permissions.some(temp => hasSectionPermission(temp))) {
                            if ((!item.users || item.users?.includes(userDetails?.user?.userid as string)) && (!item.isVisible || item.isVisible(userDetails))) {
                                return (
                                    item.subMenu ?
                                        <ul key={index}>
                                            <li ref={toggleRef} data-testid={`${item.testId}-icon`} onClick={() => toggleSubMenu(index)}
                                                className={`nav-submenu  ${(subMenuOpen && indexVal === index) ? 'active-item-icon' : ''} ${((subMenuOpen && indexVal === index) || defaultState === item.testId) ? 'active-item' : ''}`}>
                                                <i className={`${item.icon} layout-icon`}></i>
                                                <span className='layout-name'>{item.name}</span>
                                            </li>
                                            {indexVal === index &&
                                                <ul data-testid='nav-submenu' className={`nav-submenu-container ${subMenuOpen ? 'submenu-open' : ''}`}>
                                                    {
                                                        item?.subMenuItems?.map((item, index) => {
                                                            if ((!item.permission || hasSectionPermission(item.permission)) && (!item.isVisible || item.isVisible(userDetails))) {
                                                                return (
                                                                    <NavLink key={index} onClick={() => resetNavStates()} exact activeClassName='active-item' to={`${item.url}`}>
                                                                        <li>{item.name}</li>
                                                                    </NavLink>
                                                                )
                                                            }
                                                            return '';
                                                        })
                                                    }
                                                </ul>
                                            }
                                        </ul> :
                                        <NavLink key={index} onClick={() => resetNavStates()} exact activeClassName='active-item' to={`${item.url}`}>
                                            <li data-testid={`${item.testId}-icon`}>
                                                <i className={`${item.icon} layout-icon`}></i>
                                                <span className='layout-name'>{item.name}</span>
                                            </li>
                                        </NavLink>
                                )
                            }
                            return '';
                        }
                        return '';
                    })}
                    </ul>

                </div>
            }
        </div>
    )
}

export default NavBarExpanded;