import './Unauthorised.scss';
import { Box, Typography } from '@material-ui/core';
import logo from '../../assets/Primary-Connect.png';
import png from '../../assets/InPage-Error.png';
import { PageTitle } from '../../models/page-title';

function UnauthorisedAccess() {
    document.title = PageTitle.UNAUTHORISED;
    return (
        <Box className="unauthorised-access" data-testid="unauthorised-access">
            <Box ><img src={logo} className='primary-connect-logo' alt="logo" /></Box>
            <Box><img src={png} className="in-page-error"  alt="logo" /></Box>
            <Box className="statement"><Typography variant="h3" component="h3">Unauthorised access!</Typography></Box>
            <Box><Typography variant="body1" className="statement-start">You do not have permission to view this page.
            Please visit the <a href="/"> <br/>MyPrimaryConnect Dashboard</a></Typography></Box>
            <Box className="statement-end"><Typography variant="body2">Contact us by phone <a href="tel:1300000000">1800 PRIMARY</a> or email <a href="mailto:support@primaryconnect.com.au">support@primaryconnect.com.au</a></Typography></Box>
        </Box>
    );
}
export default UnauthorisedAccess;


