import React, { useRef, useState, useEffect, useCallback } from 'react';
import 'remixicon/fonts/remixicon.css';
import './NavBar.scss';
import Logo from '../../assets/Primary_Connect_Logo_Small.png';
import Tooltip from './Tooltip/Tooltip';
import { NavLink, useLocation } from "react-router-dom";
import { navItems } from './NavItems';
import { useHasPermission } from '../utils/permission/';
import { GetDefaultUrl } from '../utils/helper';
import { useSelector } from 'react-redux';
import { getHomePage, selectUserDetails } from '../../store/user/user.selectors';

const NavBar = () => {
    const homepage = useSelector(getHomePage);
    const userDetails = useSelector(selectUserDetails);
    const [subMenuOpen, setSubMenuOpen] = useState(false);
    const [indexVal, setIndexVal] = useState(0);
    const [topValue, setTopValue] = useState(0);
    const [activeState, setActiveState] = useState<string | undefined>('');
    const menuRef = useRef() as React.MutableRefObject<HTMLUListElement>;
    const navBarRef = useRef() as React.MutableRefObject<HTMLDivElement>;
    const toggleRef = useRef() as React.MutableRefObject<HTMLLIElement>;
    const { pathname } = useLocation();
    const { hasSectionPermission } = useHasPermission();

    let defaultState: string;
    if (pathname.includes('/booking/report')) {
        defaultState = 'report';
    }else if (pathname.includes('/booking') || pathname === '/preplanning') {
        defaultState = 'bookings';
    }
    else if (pathname.includes('/load')) {
        defaultState ='bookings';
    }
    else if (pathname.includes('/report')) {
        defaultState = 'report';
    }
    else if (pathname.includes('/admin/leadtime')) {
        defaultState = 'bookings';
    }
    else if (pathname.includes('/admin/gatehouse')) {
        defaultState = 'gatehouse';
    }
    else if (pathname.includes('/admin/watchlist')) {
        defaultState = 'watchlist';
    }
    else if (pathname.includes('/admin')) {
        defaultState = 'admin';
    } else if (pathname.includes('/gatehouse')) {
        defaultState = 'truck-booking';
    }
    else {
        defaultState = 'dashboard';
    }

    const openSubMenu = useCallback((event: any) => {
        event.stopPropagation();
        const navSubMenuOpen: HTMLElement | null = navBarRef.current;
        if (navSubMenuOpen && !navSubMenuOpen.contains(event?.target)) {
            setSubMenuOpen(false);
        }
    }, []);

    useEffect(() => {
        window.addEventListener('click', openSubMenu);
        if (!subMenuOpen) {
            setActiveState(defaultState);
        }
        return () => {
            window.removeEventListener('click', openSubMenu);
        };
    }, [defaultState, openSubMenu, subMenuOpen]);

    const toggleSubMenu = (index: number, activeState: string, currentItem: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        setIndexVal(index)
        setSubMenuOpen(!subMenuOpen);
        setActiveState(activeState);
        setTopValue(currentItem.currentTarget.getBoundingClientRect().top);
    }

    return (
        <div data-testid='navbar-container' className='navbar-container' ref={navBarRef} id="navbar-container">
            <ul>
                <NavLink onClick={() => setSubMenuOpen(false)} to={GetDefaultUrl(homepage)}>
                <Tooltip position={{ top: 15, left: 60 }} textVariant='body1' theme='light' content="Home" dataTestId={'Home-tooltip'} >
                    <li className='logo'><img className='primary-connect-logo' alt='Primary connect logo' src={Logo} data-testid="Home-icon" /></li>
                    </Tooltip>
                </NavLink>
                { navItems?.map((item, index) => {
                    if (!item.permissions || item.permissions.some(temp => hasSectionPermission(temp))) {
                        if ((!item.users || item.users?.includes(userDetails?.user?.userid as string)) && (!item.isVisible || item.isVisible(userDetails))) {
                            return (
                                item.subMenu ?
                                    <Tooltip position={{ top: 15, left: 60, display: indexVal === index ? 'none' : 'block' }} textVariant='body1' theme='light' key={index} dataTestId={`${item.testId}-tooltip`} content={item.name}>
                                        <li ref={toggleRef} data-testid={`${item.testId}-icon`} onClick={(e) => toggleSubMenu(index, item.testId, e)} className={`nav-submenu ${(activeState === item.testId || defaultState === item.testId) ? 'active-item' : ''}`}>
                                            <i className={item.icon}></i>
                                        </li>
                                    </Tooltip> :
                                    <NavLink onClick={() => setSubMenuOpen(false)} key={index} exact activeClassName='active-item' to={`${item.url}`}>
                                        <Tooltip position={{ top: 15, left: 60 }} textVariant='body1' theme='light' dataTestId={`${item.testId}-tooltip`} content={item.name}>
                                            <li data-testid={`${item.testId}-icon`}>
                                                <i className={item.icon}></i>
                                            </li>
                                        </Tooltip>
                                    </NavLink>
                            )
                        }
                        
                        return '';
                    }
                    return '';
                })}
            </ul>
            {subMenuOpen &&
                navItems?.map((item, index) => {
                    if ((!item.permissions || item.permissions.some(tmp => hasSectionPermission(tmp))) && (!item.isVisible || item.isVisible(userDetails))) {
                        return (indexVal === index &&
                            <ul key={index} style={{ top: topValue }} data-testid='nav-submenu' className='nav-submenu-container' ref={menuRef}>
                                {
                                    item?.subMenuItems?.map((item, index) => {
                                        if ((!item.permission || hasSectionPermission(item.permission)) && (!item.isVisible || item.isVisible(userDetails))) {
                                            return (
                                                <NavLink key={index} onClick={() => setSubMenuOpen(false)} to={`${item.url}`}><li>{item.name}</li></NavLink>
                                            )
                                        }
                                        return '';
                                    })
                                }
                            </ul>)
                    }
                    
                    return '';
                })
            }
        </div>
    )
}

export default NavBar;