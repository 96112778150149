import {useContext, useState} from 'react';
import { Box, Button, Typography, IconButton, CircularProgress } from '@material-ui/core';
import '../Dashboard.scss';
import { Tag } from "csp-common-ui";
import { useMutation } from '@apollo/client';
import dayjs from "dayjs";
import { Link, useHistory } from 'react-router-dom';
import { AlertContext } from '../../../contexts/alert-context';
import { GetflagorUnFlagLoad } from '../../../models/api/getLoadList';
import { FAVOURITE_FLAG_LOAD } from '../../../graphql/Queries/flagorUnFlagLoad';
import { getCurrentTimeZone } from '../../utils/time';
import { TransportOrderLoad } from '../../../models/transport-order-load';

 const FavrouriteLoadModel = ({ data, total, onUnFlag }: {
    data?: TransportOrderLoad[], total: number, onUnFlag: () => void }) => {  
    const { alert } = useContext(AlertContext);
    const history = useHistory();
    const [favouriteIcon] = useMutation<GetflagorUnFlagLoad>(FAVOURITE_FLAG_LOAD);
    const [loading, setLoading] = useState<{[key: string]: boolean}>({});
    
    const handleFavouriteIcon = (newStatus: boolean, orderId:string) => {
        setLoading(prev => ({ ...prev, [orderId]: true}));
        favouriteIcon({
            variables: {
                loadIds: orderId,
                isFavourite: newStatus
            }
        })
        .then(() => {
            onUnFlag();
        })
        .catch(() => {
            setLoading(prev => ({ ...prev, [orderId]: false}));
            alert({ message: "Failed to update favourites", severity: 'error'})
        })
    }

    return (
        <Box data-testid="fav-load-data">
            {total > 0 ?  (
                <Box>
                    <Box className='favourite-content-default' >
                        {  data?.map((item) => (
                            <Box key={item.loadId} display="flex" flexDirection="row" justifyContent='space-between' alignItems="center" p={1} className="favourite-modal" >
                                <Link
                                    to={`/load/details/${item?.loadId}`}
                                    color="textPrimary"
                                >
                                    <Box display="flex" alignItems="center" data-testid={`favourite-order-${item.loadId}`}>
                                        <Typography variant="subtitle2" >LID#</Typography>&nbsp;&nbsp;
                                        <Typography variant="body2" >{item?.loadId}</Typography>
                                    </Box>
                                </Link>
                                <Box width={145} minWidth={145} display="flex" justifyContent="center">
                                    <Tag
                                        closeAction={false}
                                        type={item?.loadStatus?.toLowerCase() as any}
                                        size="small"
                                    />
                                </Box>
                                <Box width={180}>
                                    <Typography variant="body2" color="textPrimary">Delivery:
                                        {item?.loadDeliveryDetailsDto?.loadDestination?.plannedDeliveryDateUTC
                                            ? dayjs(getCurrentTimeZone(
                                                    item?.loadDeliveryDetailsDto?.loadDestination?.plannedDeliveryDateUTC,
                                                    item?.loadDeliveryDetailsDto?.loadDestination?.plannedDeliveryTimeZone
                                                )).format("DD MMM YYYY, hh:mm A")
                                            : '-'}
                                    </Typography>
                                </Box>
                                <Box pr={2}>
                                    {loading[item.loadId]
                                        ? <CircularProgress color="secondary" size={22} />
                                        : <IconButton size="small"  data-testid={`favourite-active-icon-${item.loadId}`} className="ri-star-fill star-color" onClick={() => handleFavouriteIcon(false,item.loadId)}></IconButton>
                                    }
                                </Box>   
                            </Box>
                        ))}
                    </Box>
                    
                    <Box display="flex" className="see-all-default">
                        <Button
                            variant="text"
                            onClick={() => history.push('/load/list?favourites=isFavourite')}
                            endIcon={<i className="ri-arrow-right-line" />}
                            data-testid="see-all-button"
                        >
                            See all
                        </Button>
                    </Box>
                </Box>
                
            ) :  (
                <Box className="no-data-fav-modal-wrapper" data-testid='no-data-error-page'>
                    <Box display='flex' flexDirection='column' alignItems='center' pt={8} pb={8} pl={8} pr={8} color='secondary.main'>
                    <Box bgcolor='action.hover' borderRadius={1}><IconButton ><i className="ri-inbox-fill ri-xl"></i></IconButton></Box>
                        <Typography variant='body1'>{`Sorry, no matching records found`}</Typography>
                    </Box>
                </Box>
            )}  
        </Box>
    );
}
export default FavrouriteLoadModel ;