import { getScreenPermissions, getSectionPermissions, getIsRoleInfoUpdated } from '../../../store/user/user.selectors';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AccessTypeProps } from '../../../models/user';
import { dispatchEvent } from '../../../events/dispatchEvent';
import { eventsToDispatch } from '../../../events/events';

// Custom hook for checking permission and Permission component for permission management
export const useHasPermission = () => {
    
    const history = useHistory();
    const screenPermissions = useSelector(getScreenPermissions);
    const sectionPermissions = useSelector(getSectionPermissions);
    const isRoleInfoUpdated = useSelector(getIsRoleInfoUpdated);

    const hasScreenPermission = (permission: string, redirect: boolean = true): boolean=> {
        if (!screenPermissions?.[permission]) {
            if (redirect && isRoleInfoUpdated) {
                dispatchEvent(eventsToDispatch.LOG_ERROR, { message: "Unauthorized Access", url: window.location.href });
                history.push('/UnauthorisedAccess');
            }
            return false;
        }
        return true;
    }
    const hasSectionPermission = (permission: string, accessType: AccessTypeProps = 'FULL'): boolean=> {
        return sectionPermissions?.[permission] === accessType;
    }
    
    return { hasScreenPermission,  hasSectionPermission }
}

