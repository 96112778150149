import { createSelector } from "@reduxjs/toolkit";
import { State } from "..";
import { PreferenceTypes } from "../../models/preference-type-mapping";
import { RoleType } from "../../models/user-roles";

const selectUserState = (state: State) => state.user;

export const selectUserDetails = createSelector(
    selectUserState,
    (state) => state
);

export const getIsRoleInfoUpdated = createSelector(
    selectUserState,
    (state) => state.isRoleInfoUpdate
)

export const getScreenPermissions = createSelector(
    selectUserState,
    (state) => state.screenPermissions
)

export const getSectionPermissions = createSelector(
    selectUserState,
    (state) => state.sectionPermissions
)

export const getCustomerDetails = createSelector(
    selectUserState,
    (state) => state.defaultCustomer
)

export const getCarrierDetails = createSelector(
    selectUserState,
    (state) => state.defaultCarrier
)

export const getCompanyName = createSelector(
    selectUserState,
    (state) => state.user?.roletype==="Customer"
        ? state.defaultCustomer?.customername  
        : (state.user?.roletype==="Carrier"
            ? state.defaultCarrier?.carriername
            : "Primary Connect"
        )    
)

export const getCompanyCode = createSelector(
    selectUserState,
    (state) => state.user?.roletype==="Customer"
        ? state.defaultCustomer?.tmsref  
        : (state.user?.roletype==="Carrier"
            ? state.defaultCarrier?.tmsref
            : ""
        )    
)


export const getCompanyNumber = createSelector(
    selectUserState,
    (state) => state.user?.roletype==="Customer"
        ? state.defaultCustomer?.customerno  
        : (state.user?.roletype==="Carrier"
            ? state.defaultCarrier?.salesforcecarrierno
            : ""
        )    
)


export const getIsCarrierMapped = createSelector(
    selectUserState,
    (state) => {
        switch(state.user?.roletype.toLowerCase()) {
            case RoleType.CARRIER.toLowerCase():
                return state.carrierReferenceLists && state.carrierReferenceLists.length > 0;
            case RoleType.CUSTOMER.toLowerCase():
                return state.customerReferenceLists && state.customerReferenceLists.length > 0;
            default:
                return true;
        }
    }
)

export const getUserLastActiveTIme = createSelector(
    selectUserState,
    (state) => state.lastActive
)

export const getIsImpersonated = createSelector(
    selectUserState,
    (state) => state.impersonated
)


export const getHasMultipleAccounts = createSelector(
    selectUserState,
    (state) => state.hasMultipleAccounts
)


export const getActualUserDetails = createSelector(
    selectUserState,
    (state) => state.parentUserDetails
)

export const getHomePage = createSelector(
    selectUserState,
    (state) => state.userSettingsList.find(item => item.preference_type_no === PreferenceTypes.HOME_PAGE)?.field_value
)

export const getTimeZone = createSelector(
    selectUserState,
    (state) => state.userSettingsList.find(item => item.preference_type_no === PreferenceTypes.TIMEZONE)?.field_value
)

export const getBookingType = createSelector(
    selectUserState,
    (state) => state.userSettingsList.find(item => item.preference_type_no === PreferenceTypes.BOOKING_TYPE)?.field_value
)

export const getUserSettings = createSelector(
    selectUserState,
    (state) => state.userSettingsList
)

export const getFieldMapping = createSelector(
    selectUserState,
    (state) => state.fieldSeqIdMapping
)