import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
} from '@react-pdf/renderer';
import dayjs from 'dayjs';
import { getBrowserName } from '../utils/browser';

const SIZES = [100, 400, 200, 250, 250, 250, 170, 170, 170, 180, 190, 160, 150, 170, 250, 150, 120, 240];
const styles = StyleSheet.create({
    page: { padding: 24 },
    title: { paddingBottom: 16, fontSize: 20 },
    row: { display: 'flex', flexDirection: 'row', fontSize: 7 },
    colourRow: { display: 'flex', backgroundColor: '#f3f3f3', flexDirection: 'row', fontSize: 7 },
    cell: { textAlign: 'center', justifyContent: 'center' },
    section: { textAlign: 'center', margin: 30 },
    titleRow: { backgroundColor: '#2d4154', color: 'white', display: 'flex', flexDirection: 'row', fontSize: 9, fontWeight: 800, fontStyle: 'bold' },
    titleCell: { height: 36, textAlign: 'center', justifyContent: 'center', borderLeft: '0.5px solid #ECEFF1 ' },
    timestamp: { fontSize: 8 },
    titles: { display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }
});

const splitIntoPages = (declineOrders: any[]) => {
    let pages = [];
    var i, j, pageOfOrders, chunk = 19;
    for (i = 0, j = declineOrders.length; i < j; i += chunk) {
        pageOfOrders = declineOrders.slice(i, i + chunk);
        pages.push(pageOfOrders);
        chunk = 20;
    }
    return pages;
}

export const PDFDocument = ({
    feedbackData,
}: {
    feedbackData: any[],
}) => {

    const getCurrentTimeZone = (str1: string, location: string) => {
        return new Date(
            new Date(str1).toLocaleString("en-US", { timeZone: location })
        );
    };

    return (
        <Document>
            {splitIntoPages(feedbackData).map((pageOfOrders, i) => (
                <Page size="A4" orientation="landscape" style={styles.page} wrap={false}>
                    {i === 0 && <View style={styles.titles}>
                        <Text style={styles.title}>Primary Connect</Text>
                        {/* <Text style={styles.timestamp}>{userDetails?.firstname} {userDetails?.lastname}, {companyName ?companyName+', ' :''}{dayjs().format("DD-MMM-YYYY h:m A")}</Text> */}
                    </View>}
                    <View style={styles.titleRow}>
                        <View style={[styles.titleCell, { width: SIZES[0] }]}><Text>Ratting</Text></View>
                        <View style={[styles.titleCell, { width: SIZES[1] }]}><Text>Feedback</Text></View>
                        <View style={[styles.titleCell, { width: SIZES[2] }]}><Text>Email</Text></View>
                        <View style={[styles.titleCell, { width: SIZES[3] }]}><Text>Type</Text></View>
                        <View style={[styles.titleCell, { width: SIZES[4] }]}><Text>Date</Text></View>
                        <View style={[styles.titleCell, { width: SIZES[5] }]}><Text>Time</Text></View>
                        <View style={[styles.titleCell, { width: SIZES[6] }]}><Text>TimeZone</Text></View>
                        <View style={[styles.titleCell, { width: SIZES[7] }]}><Text>Device</Text></View>
                        <View style={[styles.titleCell, { width: SIZES[8] }]}><Text>Browser</Text></View>
                    </View>
                    {pageOfOrders.map((list, index) => {
                        let style = styles.row;
                        if (index % 2 === 0)
                            style = styles.colourRow;
                        return (
                            <View style={style}>
                                <View style={[styles.cell, { width: SIZES[0] }]}><Text>{list?.rating}</Text></View>
                                <View style={[styles.cell, { width: SIZES[1] }]}><Text>{list?.usercomment === '' ? '-' : list?.usercomment }</Text></View>
                                <View style={[styles.cell, { width: SIZES[2] }]}><Text>{list?.userid}</Text></View>
                                <View style={[styles.cell, { width: SIZES[3] }]}><Text>{list?.roletype}</Text></View>
                                <View style={[styles.cell, { width: SIZES[4] }]}><Text>{dayjs(getCurrentTimeZone(list?.datetime, list?.timeZone)).format('DD MMM YYYY') ?? ''}</Text></View>
                                <View style={[styles.cell, { width: SIZES[5] }]}><Text>{dayjs(getCurrentTimeZone(list?.datetime, list?.timezone)).format('hh:mm A') ?? ''}</Text></View>
                                <View style={[styles.cell, { width: SIZES[6] }]}><Text>{list?.timezone.split('/')[1]}</Text></View>
                                <View style={[styles.cell, { width: SIZES[7] }]}><Text>{list.deviceinfo}</Text></View>
                                <View style={[styles.cell, { width: SIZES[8] }]}><Text>{getBrowserName(list?.systeminfo)}</Text></View>
                            </View>
                        )
                    })}
                </Page>
            ))}
        </Document>
    );
};
