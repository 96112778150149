import { RoleType } from '../../models/user-roles';
import { UserState } from '../../store/user/user.reducer';
import { FRUITANDVEG } from '../utils/bookingType';
import { Sections } from '../utils/permission/';

interface SubMenuType {
    name: string,
    url: string,
    permission?: string,
    isVisible?: (user: UserState) => boolean
}

interface NavItemsType {
    name: string,
    url?: string,
    icon: string,
    subMenu: boolean,
    subMenuItems?: SubMenuType[],
    testId: string,
    permissions?: string[],
    users?: string[],
    isVisible?: (user: UserState) => boolean
}


const isHideLeadtimeForFandVCustomer = (user: UserState) => {
    return user?.user?.roletype?.toLowerCase() !== RoleType.CUSTOMER.toLowerCase() || !user.userCommodityLists?.find(item => item.commoditytype === FRUITANDVEG);
}

export const navItems: NavItemsType[] = [
    {
        name: 'Dashboard',
        url: '/',
        icon: 'ri-layout-grid-fill',
        subMenu: false,
        testId: 'dashboard',
        permissions: [Sections.NAV_PANE_DBRD]
    },
    {
        name: 'Transport Bookings',
        icon: 'ri-survey-fill',
        testId: 'bookings',
        subMenu: true,
        permissions: [Sections.NAV_PANE_VIEWTO,Sections.NAV_PANE_CUST_ALL_LOADS, Sections.NAV_PANE_VIEW_ALL_LOADS, Sections.NAV_PANE_CRTTO, Sections.NAV_PANE_CONFIRM, Sections.NAV_PANE_PLAN, Sections.NAV_PANE_TMSFILE, Sections.NAV_PANE_LEAD_TIMES],
        subMenuItems: [
            {
                name: 'Bookings & Loads',
                url: '/booking/list',
                permission: Sections.NAV_PANE_VIEWTO,
            },
            {
                name: 'All Bookings',
                url: '/booking/list',
                permission: Sections.NAV_PANE_CUST_ALL_LOADS,
            },
            {
                name: 'All Loads',
                url: '/load/list',
                permission: Sections.NAV_PANE_VIEW_ALL_LOADS,
            },
            {
                name: 'Create Booking',
                url: '/booking/create',
                permission: Sections.NAV_PANE_CRTTO
            },
            // {
            //     name: 'Bookings to Confirm',
            //     url: '/booking/await',
            //     permission: Sections.NAV_PANE_CONFIRM
            // },
            {
                name: 'Transport Lead Time',
                url: '/admin/leadtime',
                permission: Sections.NAV_PANE_LEAD_TIMES,
                isVisible: isHideLeadtimeForFandVCustomer
                
            },
            // {
            //     name: 'Bookings to Plan',
            //     url: '/booking/unplanned',
            //     permission: Sections.NAV_PANE_PLAN
            // },
            // {
            //     name: 'Generate TMS file',
            //     url: '/booking/tms',
            //     permission: Sections.NAV_PANE_TMSFILE
            // },
        ]
    },
    {
        name: 'Reports',
        icon: 'ri-bar-chart-fill',
        testId: 'report',
        subMenu: true,
        permissions: [Sections.NAV_PANE_REPORT, Sections.NAV_PANE_CAN_REPORT, Sections.NAV_PANE_DEC_REPORT, Sections.NAV_PANE_CANCEL_LOAD_REPORT],
        subMenuItems: [
            {
                name: 'Compliance',
                url: '/report/compliance',
                permission: Sections.NAV_PANE_REPORT
            },
            {
                name: 'Cancelled Bookings',
                url: '/booking/report/cancellation',
                permission: Sections.NAV_PANE_CAN_REPORT
            },
            {
                name: 'Cancelled Loads',
                url: '/load/report/cancellation',
                permission: Sections.NAV_PANE_CANCEL_LOAD_REPORT
            },
            {
                name: 'Load Exceptions',
                url: '/load/report/compliance',
                permission: Sections.NAV_PANE_COMPLIANCE_LOAD_REPORT
            },
            {
                name: 'Tax Invoice Report ',
                url: '/load/report/taxinvoice',
                permission: Sections.NAV_PANE_TAX_INVOICE_REPORT
            },
            {
                name: 'Declined Loads',
                url: '/booking/report/decline',
                permission: Sections.NAV_PANE_DEC_REPORT
            },
        ]
    },
    {
        name: 'Truck bookings',
        icon: 'ri-truck-fill',
        testId: 'truck-booking',
        subMenu: true,
        permissions: [Sections.NAV_PANE_TRUCK_CRT_BOOKING,Sections.NAV_PANE_TRUCK_ALL_BOOKINGS,Sections.NAV_PANE_TRUCK_BULK_CRT],
        subMenuItems: [
            {
                name: 'All truck bookings',
                url: '/gatehouse/alltruckbookings',
                permission: Sections.NAV_PANE_TRUCK_ALL_BOOKINGS
            },
            {
                name: 'Submit truck booking',
                url: '/gatehouse/create',
                permission: Sections.NAV_PANE_TRUCK_CRT_BOOKING
            },
            {
                name: 'Bulk truck bookings',
                url: '/gatehouse/bulk/upload',
                permission: Sections.NAV_PANE_TRUCK_BULK_CRT
            },
        ]
    },
    {
        name: 'Settings',
        icon: 'ri-database-2-line',
        testId: 'admin',
        subMenu: true,
        permissions: [Sections.NAV_PANE_ADMIN_ACCOUNT_MGMT,Sections.NAV_PANE_ADMIN_TMS_UPLOAD, Sections.NAV_PANE_ADMIN_USER_LIST],
        subMenuItems: [
            {
                name: 'Account Management',
                url: '/admin/accounts',
                permission: Sections.NAV_PANE_ADMIN_ACCOUNT_MGMT
            },
            {
                name: 'User Management',
                url: '/admin/users',
                permission: Sections.NAV_PANE_ADMIN_USER_LIST
            },
            {
                name: 'Import TMS Master Data',
                url: '/admin/upload',
                permission: Sections.NAV_PANE_ADMIN_TMS_UPLOAD
            },
        ]
    },
    {
        name: 'Gatehouse view',
        url: '/admin/gatehouse',
        icon: 'ri-survey-fill',
        subMenu: false,
        testId: 'gatehouse',
        permissions: [Sections.NAV_PANE_GATEHOUSE]
    },
    {
        name: 'All Drivers',
        url: '/admin/watchlist',
        icon: 'ri-truck-fill',
        subMenu: false,
        testId: 'watchlist',
        permissions: [Sections.NAV_PANE_WATCHLIST]
    },
    {
        name: 'Bulk User Onboarding',
        url: '/booking/onboard',
        icon: 'ri-book-2-fill',
        testId: 'bulk-user-on-boarding',
        subMenu: false,
        users: ["rraviraman@tcs.woolworths.com.au", "rm9@tcs.woolworths.com.au", "dnagappan@tcs.woolworths.com.au", "rs4@tcs.woolworths.com.au"]
    },
]