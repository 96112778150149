export const MEAT: string = "Same day collection";
export const AMBIENT: string = "Next day collection Amb";
export const CHILLED: string = "Next day collection TC";
export const RAIL: string = "Custom";
export const SAMEDAY: string = "Same day collection weekdays";
export const FRUITANDVEG: string = "Fruit and Veg";

export const MappingToOldBookingTypes: { [key: string]: string } = {
    "Same day collection": "Meat",
    "Next day collection Amb": "Ambient",
    "Next day collection TC": "Chilled",
    "Custom": "Rail",
    "Same day collection weekdays": "Same Day",
    "Fruit and Veg": "Fruit and Veg"
}