export const parseSearchTag = (tag: string): {
    transportOrderId: string,
    type: "CR" | "TB" | "LDID" | "SHID" | "PO",
    matchId: string,
} => {
    var myRegexp = new RegExp(/^TB-(\d+)-(SHID|LDID|CR|PO)-(.*)$/, "g");
    const result = myRegexp.exec(tag);
    if (result !== null) {
        return {
            transportOrderId: result[1],
            type: result[2] as any,
            matchId: result[3],
        }
    } else {
        return {
            transportOrderId: tag,
            type: "TB",
            matchId: tag,
        }
    }
}