import { gql } from "@apollo/client";

export const GET_ALL_FIELD_MASTER = gql`
query GET_ALL_FIELD_MASTER {
  getAllFieldDetails
  {
    seq_field_id
    component_desc
    field_desc
    screen_id
  }
}
`;

