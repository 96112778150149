import React, { useEffect } from 'react'; 
import { MicroFrontend } from './Interface/MicroFrontend';
import { useHistory } from 'react-router-dom';

interface AdminAppProps {
    client: any
}
const name = 'adminapp';

const { REACT_APP_ADMINAPP_HOST: adminAppHost } = process.env;
export const FetchAdminApp = ({client}: AdminAppProps) => {
  const history = useHistory();

  useEffect(() => {
    return () => {
      (window as { [key: string]: any })[`unmount${name}`] && (window as { [key: string]: any })[`unmount${name}`](`${name}-container`);
    }
  }, []);
  
  return (
  <MicroFrontend
    history={history}
    host={adminAppHost}
    name={name}
    client={client}
    parentDivId="micro-apps"
  />
  )
};