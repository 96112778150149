import React from 'react';
import { useHasPermission } from './rolePermissionsHook';
import { AccessTypeProps } from '../../../models/user';

export const ScreenPermission = (props: {
    permission: string,
    children: React.ReactNode
}) => {
    const { hasScreenPermission } = useHasPermission();

    const hasPermission = hasScreenPermission(props.permission);

    return (
        <React.Fragment>{hasPermission && props.children}</React.Fragment>
    );
}

export const SectionPermission = ({
    permissions,
    combination = 'or',
    accessType = 'FULL',
    children
}: {
    permissions: string[],
    combination?: 'and' | 'or'
    accessType?: AccessTypeProps,
    children: React.ReactNode,
}) => {
    const { hasSectionPermission } = useHasPermission();

    const hasPermission = combination  === 'and'
        ? permissions.every((permission) => hasSectionPermission(permission, accessType))
        : permissions.some((permission) => hasSectionPermission(permission, accessType));

    return (
        <React.Fragment>{hasPermission && children}</React.Fragment>
    );
}