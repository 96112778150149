import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    Button,
    IconButton,
    Avatar
} from '@material-ui/core';
import { getActualUserDetails } from '../../store/user/user.selectors';

import './Impersonation.scss';
import { useSelector } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import { CLEAR_USER_SESSION } from '../../graphql/Queries/UserDetailsLogin';
import StopImpersonationConfirm from './stop-impersonation-confirm';

const ImpersonationHeader = () => {
    const userDetails = useSelector(getActualUserDetails);
    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    
    const [clearUserSession, { data: logoutSuccess }] = useLazyQuery(CLEAR_USER_SESSION);

    useEffect(() => {
        if (logoutSuccess) {
            window.location.href = '/dashboard';
        }
    }, [logoutSuccess]);

    return (                  
        <Box display='flex' justifyContent='space-between' className='impersonation-header-container'>
            {showConfirm ? (
                <StopImpersonationConfirm onClose={() => setShowConfirm(false)} onConfirm={() => clearUserSession()} />
            ) : ''}
            {userDetails && (
                <Box display='flex' alignItems='center'>
                    <Box display='flex' justifyContent='space-between'>
                        <Box display='flex' alignItems='center' className="imp-user-icon">
                            <Typography variant="body1"><i className="ri-group-fill"></i></Typography>
                        </Box>
                        <Box display='flex' alignItems='center' className="imp-title" >
                            <Typography variant="subtitle1">Impersonation</Typography>
                        </Box>
                        <Box display='flex' alignItems='center' className="imp-helper-icon" color="white">
                            <Typography variant="body1"><i className="ri-error-warning-fill"></i></Typography>
                        </Box>
                        <Box display='flex' alignItems='center' className="imp-pipe">
                            <Typography variant="body1">|</Typography>
                        </Box>
                        <Box display='flex' alignItems='center' className="imp-avatar">
                            <IconButton className="avatar-button user-profile"
                                disableRipple
                            >
                                <Avatar className="avatar-icon">{userDetails.firstname?.charAt(0)} {userDetails.lastname?.charAt(0)}</Avatar>
                            </IconButton>
                        </Box>
                        <Box display='flex' alignItems='center' className="imp-profile-name">
                            <Box>
                                <Typography variant="body1" className='name' noWrap data-testid='admin-name'>{userDetails.firstname} {userDetails.lastname}</Typography>
                                <Typography variant="body2" className='company' noWrap color="textSecondary">Primary Connect</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            )}
            <Box display='flex' alignItems='center' pl={2}>
                <Box sx={{ display: { xs: 'none', sm: 'block' }}} >
                    <Button variant="contained" color="secondary" data-testid='stop-impersonation' classes={{ root: 'text-transform-none' }} onClick={() => setShowConfirm(true)}>
                        Stop impersonation
                    </Button>
                </Box>
                <Box sx={{ display: { xs: 'block', sm: 'none' }}} >
                    <Button variant="contained" color="secondary" classes={{ root: 'text-transform-none' }} onClick={() => setShowConfirm(true)}>
                        Stop
                    </Button>
                </Box>
            </Box>
        </Box >
    );
}
export default ImpersonationHeader;