import { useContext, useState} from 'react';
import { Box, Button, Typography ,IconButton, CircularProgress} from '@material-ui/core';
import '../Dashboard.scss';
import { GetflagorUnFlagTransportOrder} from "../../../models/api/get-orderlist";
import { Tag } from "csp-common-ui";
import { useMutation } from '@apollo/client';
import dayjs from "dayjs";
import { Link, useHistory } from "react-router-dom";
import { FAVOURITE_FLAG_DETAILS } from '../../../graphql/Queries/flagorUnFlagTransportOrder';
import { AlertContext } from '../../../contexts/alert-context';
import { TransportOrder } from '../../../models/transport-order';

const FavouritePOModal = ({ data, total, onUnFlag }: {
        data?: TransportOrder[], total: number, onUnFlag: () => void }) => {   
    const { alert } = useContext(AlertContext);
    const history = useHistory();
    const [favouriteIcon] = useMutation<GetflagorUnFlagTransportOrder>(FAVOURITE_FLAG_DETAILS);
    const [loading, setLoading] = useState<{[key: string]: boolean}>({});
    
    const handleFavouriteIcon = (newStatus: boolean, orderId:string) => {
        setLoading(prev => ({ ...prev, [orderId]: true}));
        favouriteIcon({
            variables: {
                transportOrderIds: orderId,
                isFavourite: newStatus
            }
        }).then(() => {
            onUnFlag();
        })
        .catch(() => {
            alert({ message: "Failed to update favourites", severity: 'error'})
            setLoading(prev => ({ ...prev, [orderId]: false}));
        })
    }
   
    return (
        <>
            {total > 0 ?  (
                <Box>
                    <Box className='favourite-content-default'>
                        { data?.map((item) => (
                            <Box key={item.transportOrderId} display="flex" flexDirection="row" justifyContent='space-between' alignItems="center" p={1} className="favourite-modal">
                                <Link
                                    to={`/booking/detail/${item?.transportOrderId}`}
                                    color="textPrimary"
                                >
                                    <Box display="flex" alignItems="center" data-testid={`favourite-order-${item.transportOrderId}`}>
                                        <Typography variant="subtitle2" >PO#</Typography>&nbsp;&nbsp;
                                        <Typography variant="body2" >{item?.transportOrderId}</Typography>
                                    </Box>
                                </Link>
                                <Box width={145} minWidth={145}>
                                    <Tag
                                        closeAction={false}
                                        type={item?.orderStatus?.toLowerCase() as any}
                                        size="small"
                                    />
                                </Box>
                                <Box width={180}>
                                    <Typography variant="body2" color="textPrimary">
                                        Delivery: {dayjs(item?.deliveryDetails?.destination?.deliveryDate, 'DD-MM-YYYY').format('DD MMM YYYY')},{item?.deliveryDetails?.destination?.deliveryTime}
                                    </Typography>
                                </Box>
                                <Box pr={2}>
                                    {loading[item.transportOrderId]
                                        ? <CircularProgress color="secondary" size={22} />
                                        : <IconButton size="small"  data-testid={`favourite-active-icon-${item.transportOrderId}`} className="ri-star-fill star-color" onClick={() => handleFavouriteIcon(false,item.transportOrderId)}></IconButton>}
                                </Box>
                            </Box>
                        ))}
                    </Box>
                    <Box display="flex" className="see-all-default">
                        <Button
                            variant="text"
                            onClick={() => history.push('/booking/list?favourites=isFavourite')}
                            endIcon={<i className="ri-arrow-right-line" />}
                            data-testid="see-all-button"
                        >
                            See all
                        </Button>
                    </Box>
                </Box>               
            ) :  (
                <Box className="no-data-fav-modal-wrapper" data-testid='no-data-error-page'>
                    <Box display='flex' flexDirection='column' alignItems='center' pt={8} pb={8} pl={8} pr={8} color='secondary.main'>
                    <Box bgcolor='action.hover' borderRadius={1}><IconButton ><i className="ri-inbox-fill ri-xl"></i></IconButton></Box>
                        <Typography variant='body1'>{`Sorry, no matching records found`}</Typography>
                    </Box>
                </Box>
            )}  
        </>
    );
}
export default FavouritePOModal ;