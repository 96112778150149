import { UserPrefFieldMap } from "../../models/api/get-field-by-screen-id";
import { GetUserDetails } from "../../models/user";
import { UserSettings } from "../../models/user-settings"


export const updateUserSettings = (settings: UserSettings[]) => {
    if (window?.updateUserSettings) {
        window.updateUserSettings(settings);
    }
}

export const setPrefFields = (preferenceList: UserPrefFieldMap[]) => {
    if (window.setPrefFields) {
        window.setPrefFields(preferenceList);
    }
}

export const setUserDetails = (userDetails: GetUserDetails) => {
    if (window.setUserDetails) {
        window.setUserDetails(userDetails);
    }
}