import { gql } from '@apollo/client';

export const GET_COMPLIANCE_REPORT = gql`
    query GET_COMPLIANCE_REPORT(
        $delFromDate: String,
        $delToDate: String,
    ) {
        getCompliancePercentage(
            request: {
              delFromDate: $delFromDate,
              delToDate: $delToDate
            }
        )
    }
`;
