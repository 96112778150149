import { 
    ApolloClient,
    InMemoryCache,
    HttpLink,
    from,
    ApolloLink
  } from '@apollo/client';
  import { onError } from "@apollo/client/link/error";
import { eventsToDispatch } from '../../events/events';
import { dispatchEvent } from '../../events/dispatchEvent';

  const addDataLink = new ApolloLink((operation, forward) => {
    return forward(operation).map(response => {
      // We can add condtional triggering of event if we don't want to update 
      document.dispatchEvent(new Event("UpdateSessionTimer"));
      return response;
    });
  });
  
  const httpLink = addDataLink.concat(new HttpLink({
    uri: process.env.REACT_APP_APOLLO_CLIENT_URL
  }));

  const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors){
      graphQLErrors.forEach(({ message, locations, path, extensions }) => {
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, Extension code: ${extensions?.code}`,
        );

        if (operation.operationName !== "generateErrorLog" && extensions?.code === 'GRAPHQL_VALIDATION_FAILED') {              
          dispatchEvent(eventsToDispatch.LOG_ERROR, { message, url: window.location.href });
        }

        switch (extensions?.code) {
          case 'UNAUTHENTICATED':
            if (window.location.pathname !== '/') {
              localStorage.setItem("redirect-path", window.location.pathname+window.location.search);
            }
            window.location.href = '/login';
            break;
          case 'GRAPHQL_VALIDATION_FAILED':
            if(extensions?.exception?.stacktrace?.[0].includes("GlobalSearchResponse")){
              console.log("404 error")
            }
            else{
              window.location.href = '/not-found';
            }
            break;
          // default:
          //   window.location.href = '/not-found';
          //   break;
        }
      });
    }
  });

  const link = from([errorLink, httpLink]);
  
  export const client = new ApolloClient({
    cache: new InMemoryCache(),
    link
  });
