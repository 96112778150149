export const getTimeStampOfDate = (date: Date, endOfDay:boolean = false): string | null => {
    if (date) {
        date.setHours(endOfDay ? 23 : 0);
        date.setMinutes(endOfDay ? 59 : 0);
        date.setSeconds(endOfDay ? 59 : 0);
        date.setMilliseconds(0);
        return (date.getTime() / 1000).toString();
    }
    else {
        return null;
    }
}


export function getDefaultDate(): Date {
    const d = new Date();
    return new Date(d.getTime() + d.getTimezoneOffset() * 60000  + (3600000 * 11));
}

export const currentAustDate = () => {
      const date = new Date(
        new Date().toLocaleString("en-US", { timeZone: "Australia/Sydney" })
      );
    
      return date;
};

export const getAustTimeZone = (str1: any) => {
      return new Date(
        new Date(str1).toLocaleString("en-US", { timeZone: "Australia/Sydney" })
      );
};

export const getCurrentTimeZone = (str1: any, location: any) => {
      return new Date(
        new Date(str1).toLocaleString("en-US", { timeZone: location })
      );
};


export const monthNames = ["January", "February", "March", "April", "May", "June",
"July", "August", "September", "October", "November", "December"
];
