import './switch-account.scss';
import LimitedHeader from './limited-header';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { ServiceTypes } from '../../models/service-types';
import { useSelector } from 'react-redux';
import { selectUserDetails } from '../../store/user/user.selectors';
import { useMutation } from '@apollo/client';
import { SetDomesticActiveCustomerRequest, SetDomesticActiveCustomerResponse } from '../../models/api/setDomesticActiveCustomer';
import { UPDATE_CURRENT_ACCOUNT } from '../../graphql/Queries/setCurrentAccount';
import { useContext, useEffect, useState, useRef } from 'react';
import { AlertContext } from '../../contexts/alert-context';
import { useHistory } from 'react-router';
import { Slide, Fade } from '@mui/material';
import { orderBy } from 'lodash';
import { PageTitle } from '../../models/page-title';
import { clearUserSessionStorage } from '../utils/helper';

interface AccountSwitchProps {
  displayBack?: boolean,
}

export const SwitchAccount = ({ displayBack }: AccountSwitchProps ) => {
  document.title = PageTitle.SELECT_ACCOUNT;
  const userDetails = useSelector(selectUserDetails);
  const history = useHistory();
  const { alert } = useContext(AlertContext);
  const [animationIn, setAnimationIn] = useState<boolean>(false);
  const containerRef = useRef(null);
  const listContainer = useRef(null);
  const [onSelectSalesForceCustomer, { loading }] = useMutation<SetDomesticActiveCustomerResponse, SetDomesticActiveCustomerRequest>(UPDATE_CURRENT_ACCOUNT);

  const SelectDomesticAccount = (sfCustomerNo: string) => {
    onSelectSalesForceCustomer({
        variables: {
            salesforceCustomerNo: sfCustomerNo
        }
    }).then((res) => {
      if (res.data?.setDomesticActiveCustomer) {
        // onSuccess();
        // history.push('/dashboard?loginSuccess=1');
        clearUserSessionStorage();
        window.location.href = '/dashboard?loginsuccess=1';
      } else {
        alert({ message: "Something went wrong!", severity: 'error'});
      }
    }).catch(() => {
      alert({ message: "Something went wrong!", severity: 'error'});
    });
  }

  const handleInternational = () => {
    onSelectSalesForceCustomer({
      variables: {
          salesforceCustomerNo: ""
      }
    }).then((res) => {
      if (res.data?.setDomesticActiveCustomer) {
        clearUserSessionStorage();
        window.location.href = process.env.REACT_APP_INTERNATIONAL_LOGIN_LINK ?? '';   
      } else {
        alert({ message: "Something went wrong!", severity: 'error'});
      }
    }).catch(() => {
      alert({ message: "Something went wrong!", severity: 'error'});
    });   
  }

  const handleBackButton = () => {
    history.goBack();
  }

  useEffect(() => {
    setTimeout(() => setAnimationIn(true), 200);
  }, []);
  
  const domesticAccounts = orderBy(userDetails?.customerReferenceLists?.filter((item, index) => item.servicetype === ServiceTypes.DOMESTIC), [item => item.customername?.toLowerCase()], ['asc']);
  const internationAccountCount = userDetails?.customerReferenceLists?.filter((item, index) => item.servicetype === ServiceTypes.INTERNATIONAL)?.length;
  
  return (
    <Box className="switch-account-type" data-testid = "switch-account-section">
      <LimitedHeader />
        <Box display="flex" justifyContent="center" px={4}>
          <Box className="switch-account-section" maxWidth={domesticAccounts?.length === 1 ? "930px" : "1240px"} >
            <div className="title-cont" ref={containerRef}>
              {displayBack ? (
                <Box className="title-back-desk">
                  <Button variant="outlined"  size="medium" startIcon={<i className='ri-arrow-left-s-line' />} data-testid="domestic" onClick={handleBackButton} >Back</Button>
                </Box>
              ): ''}
              
              <Slide direction="up" in={animationIn} mountOnEnter unmountOnExit  timeout={500} container={containerRef.current}>
                <Box className={displayBack ? "title-text" : ""}>
                  <Fade in={animationIn}  timeout={500}>
                    <Box>
                      <Box className="title">
                        <Typography variant='h1' align="center" color="textPrimary" data-testid="title-user">Hi {userDetails?.user?.firstname}!</Typography>
                      </Box>
                      <Box className="sub-title">
                        <Typography variant='body1' align="center" color="textPrimary"  data-testid ="account-user">Which account would you like to use?</Typography>
                      </Box>
                    </Box>
                  </Fade>
                </Box>
              </Slide>
              {displayBack ? (
                <Box className="title-back-mob">
                  <Button variant="outlined" disabled={loading} size="medium" startIcon={<i className='ri-arrow-left-s-line' />} data-testid="domestic" onClick={handleBackButton} >Back</Button>
                </Box>
              ): ''}
            </div>
            <div ref={listContainer}>
              <Box display="flex" justifyContent="center" >
                <Box display="flex" justifyContent="center" >
                  <Grid container justifyContent="center">
                    <Grid className='selection-boxes' item xs={12}>
                      <Grid container className={`account-cont ${domesticAccounts?.length > 1 ? "more-items" : ''}`} spacing={3} >
                        {domesticAccounts.map((account, index) => (
                          <Grid item key={index} classes={{ item: 'switch-account-grid-item'}}>
                            <Fade in={animationIn}  style={{ transitionDelay: `${(index + 1) * 100}ms` }} timeout={500} key={index}>
                              <Box className={`selection selection-dom slide-up-fade-in animation-delay-slow`}>
                                <Box p={3} pb={1}>
                                  <Box className="title-box">
                                    <Box className={`icon-box icon-box-dom`} mr={1}><i className='ri-truck-line' /></Box>
                                    <Typography variant='subtitle2' color="textPrimary" align="center">Domestic transport</Typography>
                                  </Box>
                                  <Box className="account-name">
                                    <Typography variant='h3' color="textPrimary" align="center">{account.customername}{account.bigW ? " - BigW" : ""}</Typography>

                                  </Box>
                                  <Box className="account-number">
                                    <Typography variant='body2' color="textSecondary" align="center">Customer number {account.suppliernumber}</Typography>

                                  </Box>
                                </Box>
                                <Box className="action">
                                  <Button variant="outlined" disabled={loading} size="small" data-testid="domestic" onClick={() => SelectDomesticAccount(account?.salesforcecustomerno ?? '')}>Select</Button>
                                </Box>
                              </Box>
                            </Fade>
                          </Grid>
                        ))}
                        {internationAccountCount > 0 ? (
                          <Grid item classes={{ item: 'switch-account-grid-item'}}>
                            <Fade in={animationIn} style={{ transitionDelay: `${(domesticAccounts?.length + 1 ) * 100}ms` }}  timeout={500}>
                              <Box className={`selection selection-int slide-up-fade-in animation-delay-slow`}>
                                <Box p={3} pb={1}>
                                <Box className="title-box">
                                  <Box className={`icon-box icon-box-int`} mr={1}><i className="ri-plane-line" /></Box>
                                  <Typography variant='subtitle2' color="textPrimary" align="center">International</Typography>
                                </Box>
                                <Box className="account-name">
                                  <Typography variant='h3' color="textPrimary" align="center">International accounts</Typography>

                                </Box>
                                <Box className="account-number">
                                  <Typography variant='body2' color="textSecondary" align="center">{internationAccountCount} {internationAccountCount > 1 ? 'accounts' : 'account'} </Typography>

                                </Box>
                                </Box>
                                <Box className="action">
                                  <Button variant="outlined" disabled={loading} endIcon={<i style={{fontSize: 14}} className='ri-external-link-line'/>} size="small" data-testid="domestic" onClick={handleInternational}>Select</Button>
                                </Box>
                              </Box>
                            </Fade>
                          </Grid>
                        ) : (
                        <Grid item classes={{ item: 'switch-account-grid-item'}}>
                          <Fade in={animationIn} style={{ transitionDelay: `${(domesticAccounts?.length + 1 ) * 100}ms` }}  timeout={500}>
                            <Box className={`selection selection-dis slide-up-fade-in animation-delay-slow`}>
                              <Box p={3} pb={1}>
                                <Box className="title-box">
                                  <Box className={`icon-box icon-box-dis`} mr={1}><i className="ri-plane-line" /></Box>
                                  <Typography variant='subtitle2' color="textPrimary" align="center">International</Typography>
                                </Box>
                                <Box className="account-name">
                                  <Typography variant='h3' color="textPrimary" align="center">No accounts</Typography>

                                </Box>
                                <Box className="account-number">
                                  <Typography variant='body2' color="textSecondary" align="center">0 accounts </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Fade>
                        </Grid>)}
                        <Grid item classes={{ item: 'switch-account-grid-item'}}>
                          <Fade in={animationIn} style={{ transitionDelay: `${(domesticAccounts?.length + 2) * 100}ms` }}  timeout={500}>
                            <Box className={`selection selection-dis slide-up-fade-in animation-delay-slow`}>
                              <Box p={3} pb={1}>
                              <Box className="title-box">
                                <Box className={`icon-box icon-box-dis`} mr={1}><i className="ri-building-3-line" /></Box>
                                <Typography variant='subtitle2' color="textPrimary" align="center">Warehousing</Typography>
                              </Box>
                              <Box className="account-name">
                                <Typography variant='h3' color="textPrimary" align="center">No accounts</Typography>

                              </Box>
                              <Box className="account-number">
                                <Typography variant='body2' color="textSecondary" align="center">0 accounts</Typography>

                              </Box>
                              </Box>
                              <Box className="action">
                                <Button variant="outlined" disabled={loading}  size="small" data-testid="domestic" target="_blank" href={process.env.REACT_APP_ENQUIRE_LINK ?? ''}>Enquire</Button>
                              </Box>
                            </Box>
                          </Fade>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
            </Box>
              </div>
          </Box>
        </Box>
    </Box>
  );
}
