import { combineReducers, configureStore } from "@reduxjs/toolkit";
import * as order from "./order/order.reducer";
import * as user from "./user/user.reducer";

export interface State {
    order: order.OrderState;
    user: user.UserState;
}


const rootReducer = combineReducers({
    order: order.orderReducer,
    user: user.userReducer
});


const initialState: State = {
    order: order.initialState,
    user: user.initialState
};


export const store = configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV !== 'production',
    preloadedState: initialState
});