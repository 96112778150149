import React, { Component } from 'react'

export default class Footer extends Component {
    render() {
        return (
            <div data-testid='mpc-app-footer'>
                
            </div>
        )
    }
}
