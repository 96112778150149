import { gql } from '@apollo/client';

export const SAVE_REVIEW = gql`
    mutation saveReview(
        $systemInfo: String
        $datetime: String
        $timezone: String
        $Rating: String
        $comment: String
        $deviceInfo: String
    ) {
    saveReview(
        systemInfo: $systemInfo,
        datetime: $datetime,
        Rating: $Rating,
        comment: $comment,
        timezone: $timezone,
        deviceInfo: $deviceInfo
    ) {
        datetime
        deviceinfo
        rating
        roletype
        systeminfo
        usercomment
        userid
        timezone
    }
}`