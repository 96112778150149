import './switch-service-type.scss';
import LimitedHeader from './limited-header';
import { Box, Button, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { selectUserDetails } from '../../store/user/user.selectors';
import International from '../../assets/international.png';
import Domestic from '../../assets/domestic.png';
import Warehousing from '../../assets/Warehousing.png';
import { useContext } from 'react';
import { useMutation } from '@apollo/client';
import { AlertContext } from '../../contexts/alert-context';
import { SetDomesticActiveCustomerRequest, SetDomesticActiveCustomerResponse } from '../../models/api/setDomesticActiveCustomer';
import { UPDATE_CURRENT_ACCOUNT } from '../../graphql/Queries/setCurrentAccount';

export const SwitchServiceTypeInterface = () => {
    const userDetails = useSelector(selectUserDetails);
    const { alert } = useContext(AlertContext);
    const [onSelectSalesForceCustomer] = useMutation<SetDomesticActiveCustomerResponse, SetDomesticActiveCustomerRequest>(UPDATE_CURRENT_ACCOUNT);

    const handleDomestic = () => {
      onSelectSalesForceCustomer({
          variables: {
              salesforceCustomerNo: ""
          }
      }).then((res) => {
        if (res.data?.setDomesticActiveCustomer) {
          // history.push('/dashboard?loginsuccess=1');
          window.location.href = '/dashboard?loginsuccess=1';
        } else {
          alert({ message: "Something went wrong!", severity: 'error'});
        }
      }).catch(() => {
        alert({ message: "Something went wrong!", severity: 'error'})
      });
    }

    const handleInternational = () => {
      window.location.href = process.env.REACT_APP_INTERNATIONAL_INTERNAL_LOGIN_LINK ?? '';        
    }
  
    return (
      <Box className="switch-service-type" data-testid ="switch-services-section">
        <LimitedHeader />
        <Box className="switch-service-section">
            <Box className="title">
                <Typography variant='h1' align="center" color="textPrimary" data-testid = "title-username">Hi {userDetails?.user?.firstname}!</Typography>
            </Box>
            <Box className="sub-title">
                <Typography variant='body1' align="center" color="textPrimary" data-testid = "account-username">It’s nice to see you again. What would you like to do today?</Typography>
            </Box>
            <Box className="selection-boxes">
                <Box className="selection">
                    <Box className="title-box" color="secondary.main"> 
                        <Typography variant='h3' color="inherit" align="center">Domestic</Typography>
                    </Box>
                    <Box className="image-cntr">
                        <img src={Domestic} alt="Domestic" />
                    </Box>
                    <Box className="action">
                        <Button variant="outlined" size="small" data-testid="domestic" onClick={handleDomestic}>Select</Button>
                    </Box>
                </Box>
                <Box className="selection">
                    <Box className="title-box" color="secondary.main">
                        <Typography variant='h3' color="inherit" align="center">International logistics</Typography>
                    </Box>
                    <Box className="image-cntr">
                        <img src={International} alt="Domestic" />
                    </Box>
                    <Box className="action">
                        <Button variant="outlined" size="small" data-testid="international"  onClick={handleInternational}>Select</Button>
                    </Box>
                </Box>
                <Box className="selection disabled">
                    <Box className="title-box">
                        <Box color="action.active" pr={1}><i className="ri-lock-2-fill" /></Box>
                        <Typography variant='h3' color="textSecondary" align="center">Warehousing</Typography>
                    </Box>
                    <Box className="image-cntr">
                        <img src={Warehousing} alt="Domestic" />
                    </Box>
                    <Box className="action">
                        <Button variant="outlined" size="small" data-testid="coming-soon" disabled>Coming soon</Button>
                    </Box>
                </Box>
            </Box>
        </Box>
      </Box>
    );
  }