export enum LoadStatus {
    ARRIVED = "ARRIVED",
    CANCELLED = "CANCELLED",
    CONFIRMED = 'CONFIRMED',
    DELIVERED = "DELIVERED",
    INCOMING = "INCOMING",
    INTRANSIT = "INTRANSIT",
    INVOICED = "INVOICED",
    PLANNED = "PLANNED",
    PROCESSING = "PROCESSING",
    REJECTED = "REJECTED",
    SUBMITTED = "SUBMITTED",
}