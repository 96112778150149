import {
    Avatar,
    Box,
    Grid,
    Typography,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import './UserProfilePage.scss';
import { useSelector, useDispatch } from 'react-redux';
import {
    getCompanyName, getCompanyCode, getCompanyNumber, selectUserDetails,
    getHomePage, getBookingType
} from '../../store/user/user.selectors';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { userUpdateDetails } from '../../store/user/user.actions';
import { useHasPermission, Screens } from '../utils/permission';
import { SelectBox, SelectOptionProps } from 'csp-common-ui';
import { RoleType } from '../../models/user-roles';
import { GET_LOOKUP } from '../../graphql/Queries/getGlobalLookup';
import { LookUpApiResponse } from '../../models/api/get-lookup-api';
import { PreferenceTypes } from '../../models/preference-type-mapping';
import { UPDATE_USER_SETTINGS } from '../../graphql/Queries/updateUserSettings';
import { UpdateUserSettingsRequest, UpdateUserSettingsResponse } from '../../models/api/update-user-setings';
import { GetUserDetailsResponse } from '../../models/api/get-sso-userdetails';
import { GET_SSO_USERDETAILS } from '../../graphql/Queries/UserDetailsLogin';
import ContactPrimaryConnect from '../ContactPrimaryConnect/ContactPrimaryConnect';


const UserProfilePage = () => {
    const { hasScreenPermission } = useHasPermission();

    const dispatch = useDispatch();
    const userDetails = useSelector(selectUserDetails);
    const companyName = useSelector(getCompanyName);
    const companyCode = useSelector(getCompanyCode);
    const companyNo = useSelector(getCompanyNumber);
    const homePageInitial = useSelector(getHomePage) ?? Screens.DBRD;
    // const timeZoneInitial = useSelector(getTimeZone);
    const bookingTypeInitial = useSelector(getBookingType);

    const userFirstName = (userDetails?.user?.firstname ?? '').charAt(0).toUpperCase();
    const userLastName = (userDetails?.user?.lastname ?? '').charAt(0).toUpperCase();
    const [homePage, setHomePage] = useState(homePageInitial);
    // const [timezone, setTImeZone] = useState(timeZoneInitial);
    const [bookingType, setBookingType] = useState(bookingTypeInitial);
    // const [close, setClose] = useState(localStorage.getItem('userSettingsUpdateAlert') === 'hide');

    const isRoleDetailsAvailable = userDetails?.user?.roletype?.toLowerCase() !== RoleType.INTERNAL?.toLowerCase();

    // const [timeZoneOptions, setTimeZoneOptions] = useState<Array<{ label: string, value: string }>>([]);
    const [bookingTypeOptions, setBookingTypeOptions] = useState<Array<{ label: string, value: string }>>(
        userDetails.user?.roletype === RoleType.INTERNAL
            ? []
            : userDetails?.userCommodityLists?.map(item => ({ label: item.commoditytype, value: item.commoditytype }))
    );

    const [homePageOptions] = useState<SelectOptionProps[]>([
        {
            label: 'Dashboard',
            value: Screens.DBRD,
        },
        ...(hasScreenPermission(Screens.ORDR_CRT, false) ? [{
            label: 'Create Transport Booking',
            value: Screens.ORDR_CRT,
        }] : []),
        ...(hasScreenPermission(Screens.TMS_UPLOAD, false) ? [{
            label: 'Upload Transport Bookings',
            value: Screens.TMS_UPLOAD,
        }] : []),
        ...(hasScreenPermission(Screens.ORD_LIST, false) ? [{
            label: 'Transport Bookings',
            value: Screens.ORD_LIST,
        }] : []),
        ...(hasScreenPermission(Screens.POD_STATUS, false) ? [{
            label: 'ePOD Status',
            value: Screens.POD_STATUS,
        }] : []),
    ]);

    const [onUpdateUserSettings] = useMutation<UpdateUserSettingsResponse, UpdateUserSettingsRequest>(UPDATE_USER_SETTINGS);
    const [getSsoLoginDetails, { data: updatedUserDetails }] = useLazyQuery<GetUserDetailsResponse>(GET_SSO_USERDETAILS, {
        fetchPolicy: "network-only",
        nextFetchPolicy: "network-only"
    });

    const { data: lookUpArray } = useQuery<LookUpApiResponse>(GET_LOOKUP);

    useEffect(() => {
        if (updatedUserDetails?.getUserdetails) {
            dispatch(userUpdateDetails((updatedUserDetails.getUserdetails)));
        }
    }, [dispatch, updatedUserDetails]);

    useEffect(() => {
        if (lookUpArray?.getAllGloballookups) {
            // setTimeZoneOptions((lookUpArray?.getAllGloballookups ?? [])
            //     .filter((item) => item.lookupid === 'Timezone')
            //     .map((item) => ({ label: item.lookupchar, value: item.lookupval })));

            if (userDetails.user?.roletype === RoleType.INTERNAL) {
                setBookingTypeOptions((lookUpArray?.getAllGloballookups ?? [])
                    .filter((item) => item.lookupid === 'CommodityType')
                    .map((item) => ({ label: item.lookupchar, value: item.lookupchar })));
            }
        }
    }, [lookUpArray, userDetails.user?.roletype]);


    const handleChange = (value: string, type: PreferenceTypes) => {
        switch (type) {
            case PreferenceTypes.HOME_PAGE:
                setHomePage(value)
                break;
            // case PreferenceTypes.TIMEZONE:
            //     setTImeZone(value)
            //     break;
            case PreferenceTypes.BOOKING_TYPE:
                setBookingType(value)
                break;
        }
        onUpdateUserSettings({
            variables: {
                request: [{
                    field_value: value,
                    preference_type_no: type,
                }]
            }
        }).then((result) => {
            getSsoLoginDetails();
        })
            .catch((error) => {
                console.log('error', error.graphQLErrors);

                updatePrefToInitial(type);
            })
    };

    const updatePrefToInitial = (type: PreferenceTypes) => {
        switch (type) {
            case PreferenceTypes.HOME_PAGE:
                setHomePage(homePageInitial)
                break;
            // case PreferenceTypes.TIMEZONE:
            //     setTImeZone(timeZoneInitial)
            //     break;
            case PreferenceTypes.BOOKING_TYPE:
                setBookingType(bookingTypeInitial)
                break;
        }
    };


    return (
        <Box>
            <ContactPrimaryConnect id="user-details"/>
            <Box className="user-profile-page" data-testid="user-profile-page">
                <Box borderRadius={8} bgcolor='white' p={3}>
                    <Grid container>
                        <Grid item className="profile-first-row" xs={12} sm={6} md={6} lg={6} xl={6} >
                            <Box display='flex' flexDirection='column' alignItems='center' p={2}>
                                <Avatar className="avatar-icon">{userFirstName}{userLastName}</Avatar>
                                <Box display='flex' justifyContent='center' pt={1}>
                                    <Typography variant="h3" color="textPrimary">{userDetails?.user?.firstname} {userDetails?.user?.lastname}</Typography>
                                </Box>
                                <Box display='flex' justifyContent='center' pt={1}>
                                    <Typography variant="body2" color="textPrimary">{companyName}</Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item className="profile-first-row" xs={12} sm={6} md={6} lg={6} xl={6} >
                            <Box display='flex' flexDirection='column' justifyContent='space-between' height='100%'>
                                <Box height={46} display='flex' justifyContent='space-between' alignItems='center' className='border-box'>
                                    <Typography variant="body2" color="textSecondary">First name</Typography>
                                    <Typography variant="body2" color="textPrimary">{userDetails?.user?.firstname}</Typography>
                                </Box>
                                <Box height={46} display='flex' justifyContent='space-between' alignItems='center' className='border-box'>
                                    <Typography variant="body2" color="textSecondary">Last name</Typography>
                                    <Typography variant="body2" color="textPrimary">{userDetails?.user?.lastname}</Typography>
                                </Box>
                                <Box height={46} display='flex' justifyContent='space-between' alignItems='center' className='border-box'>
                                    <Typography variant="body2" color="textSecondary">Mobile</Typography>
                                    <Typography variant="body2" color="textPrimary">{userDetails?.user?.phonenumber}</Typography>
                                </Box>
                                <Box height={46} display='flex' justifyContent='space-between' alignItems='center' className='border-box'>
                                    <Typography variant="body2" color="textSecondary">Contact email</Typography>
                                    <Typography variant="body2" color="textPrimary">{userDetails?.user?.userid}</Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Grid container spacing={2}>
                    {isRoleDetailsAvailable ? (
                        <Grid item className="profile-first-row" xs={12} sm={12} md={6} lg={6} xl={6} >
                            <Box borderRadius={8} bgcolor='white' mt={3} p={3} display='flex' flexDirection='column' height='230px'>
                                <Box height={56} display='flex' alignItems='center' >
                                    <Typography variant="h3" color="textPrimary">Company Details</Typography>
                                </Box>
                                <Box height={56} display='flex' justifyContent='space-between' alignItems='center' className='border-box'>
                                    <Typography variant="body2" color="textSecondary">Role</Typography>
                                    <Typography variant="body2" color="textPrimary">{userDetails?.user?.roletype}</Typography>
                                </Box>
                                <Box height={56} display='flex' justifyContent='space-between' alignItems='center' className='border-box'>
                                    <Typography variant="body2" color="textSecondary">{userDetails?.user?.roletype} number</Typography>
                                    <Typography variant="body2" color="textPrimary">{companyNo}</Typography>
                                </Box>
                                {userDetails?.user?.roletype?.toLowerCase() === RoleType.CARRIER.toLowerCase() ? (
                                    <Box height={56} display='flex' justifyContent='space-between' alignItems='center' className='border-box'>
                                        <Typography variant="body2" color="textSecondary">Carrier code</Typography>
                                        <Typography variant="body2" color="textPrimary">{companyCode}</Typography>
                                    </Box>
                                ): ''}
                            </Box>
                        </Grid>
                    ) : ''}
                    <Grid item className="profile-first-row" xs={12} sm={12} md={isRoleDetailsAvailable ? 6 : 12} >
                        <Box
                            borderRadius={8}
                            bgcolor='white'
                            mt={3}
                            p={3}
                            display='flex'
                            flexDirection='column'
                            className='edit'
                            height={isRoleDetailsAvailable ? '230px' : 'auto'}
                        >
                            <Box height={56} display='flex' alignItems='center' justifyContent='space-between' >
                                <Typography variant="h3" color="textPrimary">Preferences</Typography>
                            </Box>
                            <Box className={userDetails.user?.roletype === RoleType.CUSTOMER  ? 'user-setting-details' : 'user-details'} height={56}>
                                <Box display='flex' alignItems='center'>
                                    <Typography variant="body2" color="textSecondary" >Homepage</Typography>
                                </Box>
                                <Box width='250px'>
                                    <SelectBox
                                        id="hompe-page"
                                        fullWidth
                                        value={homePage}
                                        data-testid='homepage-selection'
                                        placeholder='Please select your homepage'
                                        onChange={(event: any) => handleChange(event?.target?.value, PreferenceTypes.HOME_PAGE)}
                                        options={homePageOptions}
                                        size='small'
                                    />
                                </Box>
                            </Box>
                            {bookingTypeOptions.length > 0 ? (
                                <Box className="user-setting-details user-setting-booking-type" height={56}>
                                    <Box display='flex' alignItems='center'>
                                        <Typography variant="body2" color="textSecondary" >Booking type</Typography>
                                    </Box>
                                    <Box width='250px'>
                                        <SelectBox
                                            id="booking_type_option"
                                            fullWidth
                                            value={bookingType}
                                            placeholder='Please select Booking Type'
                                            data-testid='bookingType-selection'
                                            onChange={(event: any) => handleChange(event?.target?.value, PreferenceTypes.BOOKING_TYPE)}
                                            options={bookingTypeOptions}
                                            size='small'
                                        />
                                    </Box>
                                </Box>
                               ) : ''}
                            {/* <Box className="user-setting-details" height={56}>
                                <Box display='flex' alignItems='center'>
                                    <Typography variant="body2" color="textSecondary" >Timezone </Typography>
                                </Box>
                                <Box width='250px'>
                                    <SelectBox
                                        id="timezone"
                                        fullWidth
                                        placeholder='Please select Timezone'
                                        data-testid='timezone-selection'
                                        value={timezone}
                                        onChange={(event: any) => handleChange(event?.target?.value, PreferenceTypes.TIMEZONE)}
                                        options={timeZoneOptions}
                                        size='small'
                                    />
                                </Box>
                            </Box> */}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}
export default UserProfilePage;