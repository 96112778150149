import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { UserPrefFieldMap } from './models/api/get-field-by-screen-id';
import { GetUserDetails } from './models/user';
import { UserSettings } from './models/user-settings';


declare global {
  interface Window {
    getFieldsByScreenId: (screenId: string) => UserPrefFieldMap[]
    prefFields?: UserPrefFieldMap[]
    getAllFieldIds?: () => UserPrefFieldMap[]
    setPrefFields?: (settings: UserPrefFieldMap[]) => void
    userDetails?: GetUserDetails,
    getUserDetails: () => GetUserDetails | undefined,
    updateUserSettings: (userSettings: UserSettings[]) => void
    setUserDetails: (user: GetUserDetails) => void
  }
}


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);


if (!window.getFieldsByScreenId) {
  window.getFieldsByScreenId = function(screenId: string) {
    if (window.prefFields) {
      return window.prefFields.filter(item => item.screen_id === screenId);
    }
    return [];      
  };
}

if (!window.getAllFieldIds) {
  window.getAllFieldIds = function() {
      return window?.prefFields ?? [];
  };
}

if (!window.setPrefFields) {
  window.setPrefFields = function(settings: UserPrefFieldMap[]) {
      window.prefFields = settings;
  };
}

if (!window.getUserDetails) {
  window.getUserDetails = function() {
      return window?.userDetails;
  };
}

if (!window.updateUserSettings) {
  window.updateUserSettings = function(userSettings: UserSettings[]) {
    if (window?.userDetails && window?.userDetails?.userSettingsList) { 
      let updatedSettings = [ ...(window?.userDetails?.userSettingsList ?? []) ];
      userSettings?.forEach((payLoad) => {
          let index = updatedSettings?.findIndex((item) => item.seq_field_id === payLoad.seq_field_id )
          if (index >= 0) {
              updatedSettings[index] = { ...updatedSettings[index], ...payLoad };
          } else  {
              updatedSettings.push(payLoad);
          }
      });
      window.userDetails = {
        ...window.userDetails,
        userSettingsList: updatedSettings
      }
    }
  };
}

if (!window.setUserDetails) {
  window.setUserDetails = function(user: GetUserDetails) {
      window.userDetails = user;
  };
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
