import {
    Box,
    Typography,
    Button,
    Dialog,
    DialogTitle,
    IconButton,
    DialogActions} from '@material-ui/core';
import './Impersonation.scss';


const StopImpersonationConfirm = ({ onClose, onConfirm }: { onClose: () => void, onConfirm: () => void }) => {

    return (
        <Dialog
            open={true}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            maxWidth='sm'
            fullWidth
            
        >
            <Box py={2} className="stop-impersonate-confirm" data-testid='stop-impersonate-confirm'>
                <DialogTitle id="alert-dialog-title" className="alert-dialog-title" >
                    <Box display='flex' alignItems='center' justifyContent='space-between' py={1}>
                        <Typography variant='h3' color='textPrimary'>Are you sure you want to stop impersonating?</Typography>
                        <IconButton
                            aria-label="close"
                            onClick={onClose}
                            color='secondary'
                            size='small'
                            data-testid='top-close-button'
                        >
                            <i className='ri-close-line ri-xl' />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogActions>
                    <Box py={2} px={1} width="100%" display='flex' justifyContent='center'>
                        <Box pr={2} >
                            <Button
                                onClick={onClose}
                                color="secondary"
                                size="large"
                                className="text-transform-none impersonation-confirm-cancel"
                                variant='outlined'
                                data-testid='cancel-confirm'
                            >
                                Cancel
                            </Button>
                        </Box>
                        <Box>
                            <Button
                                onClick={onConfirm}
                                color="primary"
                                variant='contained'
                                className="text-transform-none impersonation-confirm-ok"
                                data-testid='accept-confirm'
                                size="large"
                            >
                                Ok
                            </Button>
                        </Box>
                    </Box>
                </DialogActions>
            </Box>
        </Dialog>
    );
}
export default StopImpersonationConfirm;