
import { Avatar, Box, Drawer, IconButton, Tooltip, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import logo from '../../assets/Primary_Connect_Logo.png';
import './limited-header.scss';
import { selectUserDetails } from '../../store/user/user.selectors';
import HelpDrawer from '../Header/helpDrawer';

const LimitedHeader = () => {
    const [open, setOpen] = useState<boolean>(false);

    const userDetails = useSelector(selectUserDetails);
    const userFirstLetter = (userDetails?.user?.firstname ?? '').charAt(0).toUpperCase();
    const userLastLetter = (userDetails?.user?.lastname ?? '').charAt(0).toUpperCase();

    const toggleDrawer = () => {
        setOpen(!open)
    }

    return (
        <Box className="limited-header">
            <Box> <img className='logo' src={logo} alt='logo'/></Box>
            <Box>
                <Box className="header-right">
                    <Box className="profile-name">
                        <Typography variant="body1">{userDetails?.user?.firstname} {userDetails?.user?.lastname}</Typography>
                    </Box>
                    <Avatar className="avatar-icon">{userFirstLetter}{userLastLetter}</Avatar>

                    <Tooltip title={<Typography variant='caption'>Help</Typography>}>
                        <IconButton onClick={toggleDrawer} id="help" className='help-button' color="secondary" size='small'>
                            <i className="ri-question-fill ri-lg" />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
            <Drawer anchor='right' open={open} onClose={toggleDrawer} className="help-drawer-popup">
                <HelpDrawer onClose={toggleDrawer}  />
            </Drawer>
        </Box>
    )
}
export default LimitedHeader;