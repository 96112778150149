
import { useQuery } from '@apollo/client';
import { getBookingsToSubmitCountResponse } from '../../../models/dashbaord';

import { QuickActionTile, QuickActivityTileProps } from '../common/QuckActionTile';
import { omit } from 'lodash';
import { GET_BOOKINGS_TO_SUBMIT_COUNT } from '../../../graphql/Queries/bookingsToSubmit';

const BookingToSubmitTile = (props: Omit<QuickActivityTileProps, 'value'>) => {
    const { data: bookingToSubmitCount } = useQuery<getBookingsToSubmitCountResponse>(GET_BOOKINGS_TO_SUBMIT_COUNT, {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-and-network'
    });

    return (
        <QuickActionTile
            value={bookingToSubmitCount?.getBookingsToSubmitCount}
            edit={props.edit as any}
            {...omit(props, 'edit')}
        />
    );
}
export default BookingToSubmitTile;