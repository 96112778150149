import { gql } from '@apollo/client';

export const GET_NOTIFICATION_BY_USERID = gql`
    query GET_FIELDS_BY_SCREEN_ID(
        $pageNo: Int!,
        $pageSize: Int!,
        $properties: [String],
        $direction: Direction
    ) {
        getNotificationByPage(
            pageNo: $pageNo,
            pageSize: $pageSize,
            properties: $properties,
            direction: $direction
        ){
            totalNumber,
            list {
                numberOfOrders,
                batchId,
                carrierno,
                carriertmsref,
                createdatetime,
                customerno,
                loadid,
                id,
                message,
                notificationtype,
                orderid,
                roletype,
                subject,
                template,
                timestamp,
                userid,
                ponumber,
            }
        }
    }
`;