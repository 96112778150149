import { useLazyQuery } from '@apollo/client';
import {
    Box,
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent} from '@material-ui/core';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { CLEAR_USER_SESSION } from '../../graphql/Queries/UserDetailsLogin';
import { selectUserDetails } from '../../store/user/user.selectors';
import './not-mapped-modal.scss';

const CarrierCustomerNotMapped = () => {
    const userDetails = useSelector(selectUserDetails);
    const [clearUserSession, { loading, data: logoutSuccess }] = useLazyQuery(CLEAR_USER_SESSION);

    const logoutAction = () => {
        clearUserSession();
    }
    useEffect(() => {
        if (logoutSuccess) {
            window.location.href = process.env.REACT_APP_EXTERNAL_LOGOUT_SSO_URL ?? '';
        }
    }, [logoutSuccess, userDetails?.user?.roletype]);

    return (
        <Dialog
            open={true}
            aria-labelledby="alert-dialog-title"
            maxWidth='md'
            fullWidth
            disableEscapeKeyDown
            
        >
            <Box className="carrier-customer-not-mapped-modal" >
                <DialogTitle id="alert-dialog-title" className="alert-dialog-title" >
                    <Box display='flex' alignItems='center'>
                        <Box pr={1} display='flex' alignItems='center' color='error.main'><i className='ri-error-warning-fill' /></Box>
                        <Typography variant='h3' color='textPrimary'>Access Denied</Typography>
                    </Box>
                </DialogTitle>
                <DialogContent className="modal-border-top" >
                    <Box py={1}>
                        <Typography variant='body1' color='textPrimary'>You do not have permission to view this page. Please contact your administrator.</Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Box pb={2} px={1} className='action-content'>
                        <Button
                            onClick={logoutAction}
                            color="primary"
                            variant='contained'
                            className="text-transform-none success-button"
                            data-testid={'success-button'}
                            size="large"
                            disabled={loading}
                        >
                            Logout
                        </Button>
                    </Box>
                </DialogActions>
            </Box>
        </Dialog>
    );
}
export default CarrierCustomerNotMapped;