import './ContactPrimaryConnect.scss';
import { Box, Typography } from '@material-ui/core';
import Alert from '@mui/material/Alert';
import { useSelector } from 'react-redux';
import { RoleType } from '../../models/user-roles';
import { selectUserDetails} from '../../store/user/user.selectors';
import { useState } from 'react';
import Grid from '@material-ui/core/Grid';

function ContactPrimaryConnect({ id }: { id: string }) {
    const userDetails = useSelector(selectUserDetails);
    const [close, setClose] = useState(localStorage.getItem(`contactPrimaryConnectBanner-${id}`) === 'hide');

    const onClose = () => {
        localStorage.setItem(`contactPrimaryConnectBanner-${id}`, 'hide')
        setClose(true);
    };

    const handleClick = (e: any) => {
        if (e) {
            e.preventDefault();
            document.getElementById('help')?.click();
        }
    }

    return (
        <Box>
            {close ? '' : (
                <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Box className="Contact-main">
                            <Alert variant="outlined" severity="info"
                                icon={<i className="ri-information-fill information-icon" />}
                                action={<i className="ri-close-fill ri-lg alert-close-icon" data-testid="close-button" onClick={onClose} />}
                                className="alert"
                            >
                                {userDetails?.user?.roletype?.toLowerCase() === RoleType.INTERNAL?.toLowerCase() && (
                                     <Typography className="alert-message" variant="body2" color="textPrimary">If you need to update the details, please go to ServiceWoW.</Typography>
                                 )}

                                {userDetails?.user?.roletype?.toLowerCase() === RoleType.CUSTOMER?.toLowerCase() && (
                                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                    <Typography className="alert-message" variant="body2" color="textPrimary">If you need to update the details, please <a href="" className="contact-link" data-testid="help-link" onClick={(e) => { handleClick(e) }}>contact</a> Primary Connect.</Typography>
                                )}
                                {userDetails?.user?.roletype?.toLowerCase() === RoleType.CARRIER?.toLowerCase() && (
                                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                    <Typography className="alert-message" variant="body2" color="textPrimary">If you need to update the details, please <a href="" className="contact-link" data-testid="help-link" onClick={(e) => { handleClick(e) }}>contact</a> Primary Connect.</Typography>
                                )}
                            </Alert>
                        </Box>
                    </Grid>
                </Grid>
            )}
        </Box>

    );
}
export default ContactPrimaryConnect;