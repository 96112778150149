import { gql } from '@apollo/client';

export const GET_TRANSPORT_ORDER_BY_SEARCH_KEYWORD = gql`
query  GET_TRANSPORT_ORDER_BY_SEARCH_KEYWORD(
    $searchKeywords: String!,
    $searchField: [GlobalSearchFields],
    $pageSize: Int,
    $currentPageNo: Int
){
        getTransportOrderBySearchKeyword(
            searchField: $searchField, searchKeywords: $searchKeywords, pageSize: $pageSize, currentPageNo: $currentPageNo)
        {
            tbList{
                totalordersno
                orderlist{
                    transportOrderId,
                    orderStatus
                }
            }
            crList{
                orderlist {
                    transportOrderId,
                    orderStatus,
                    customerReference
                }
                totalordersno
            }
            ldidList{
                totalLoads
                loadList {
                    loadId,
                    loadStatus

                }
            }
            shidList{
                orderlist {
                    transportOrderId,
                    orderStatus,
                    shipmentNumber
                }
                totalordersno
                
            }
            poList{
                orderlist {
                    transportOrderId
                    orderStatus,
                    poNumber
                }
                totalordersno
            }
        }
    }`