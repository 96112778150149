import React, { useEffect } from 'react'; 
import { MicroFrontend } from './Interface/MicroFrontend';
import { useHistory } from 'react-router-dom';

interface LandingAppProps {
    client: any
}

const name = 'landingapp';
const { REACT_APP_LANDING_APP_HOST: landingApp } = process.env;
export const LandingApp = ({client}: LandingAppProps) => {
  const history = useHistory();
  if (localStorage.getItem('outageBannerStatus') !== null) {
    localStorage.removeItem('outageBannerStatus')
  }

  useEffect(() => {
    return () => {
      (window as { [key: string]: any })[`unmount${name}`] && (window as { [key: string]: any })[`unmount${name}`](`${name}-container`);
    }
  }, []);


  return (
  <MicroFrontend
    history={history}
    host={landingApp}
    name={name}
    client={client}
    parentDivId="body-container"
  />
  )
};