import { gql } from '@apollo/client';

export const GET_SSO_USERDETAILS = gql`
    query {
        getUserdetails {
            userdetails {
                active
                createdatetime
                createuserid
                firstname
                ipaddress
                lastaccessdatetime
                lastname
                middlename
                phonenumber
                profilephoto
                roletype
                secondaryemailid
                updatedatetime
                updateuserid
                userid
                isconsentrequired
                isconsentupdatedtime
                userrolexrefList{
                    accessroleid
                }
            }
            parentUserdetails {
                firstname
                lastname
                profilephoto
            }
            impersonated
            internalUserAccessReference {
                active
                servicetype
                roleId
            }
            userRoleLists{
                screenId
                screensectionmaster {
                    screensectionid
                    accesstype
                }
            }
            carrierReferenceLists {
                carriername
                salesforcecarrierno
                tmsref
                isdefault
            }
            customerReferenceLists {
                customername
                salesforcecustomerno
                customerno
                tmsref
                isdefault
                servicetype
                active
                suppliernumber
                billtocustomerno
                splitCustomer
                bigW
            }
            userSettingsList {
                component_desc,
                seq_field_id,
                field_value,
                display_flag,
                display_seq,
                preference_type_no,
                updatedatetime,
            }
            userCommodityLists {
                commoditytype
            }
        }
    }
`;

export const UPDATE_CONSENT_DETAILS = gql`
    query {
        updateUserConsent
    }            
`;

export const CLEAR_USER_SESSION = gql`
    query {
        clearUsersession
    }
`;