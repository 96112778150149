import { useState } from 'react';
import { QuickActionTile, QuickActivityTileProps } from '../common/QuckActionTile';
import { omit } from 'lodash';
import dashboardIconPod from '../../../assets/dashboardIconPod.png';
import { PodModal } from '../common/podModal';

const PodTile = (props: Omit<QuickActivityTileProps, 'value' | 'url' | 'title' | 'image' |'onClick'>) => {
    const [podModal, setPodModal] = useState(false);

    const handleModal = () => {
        setPodModal(!podModal);
    }
    
    return (
        <>
            <QuickActionTile 
                title="POD"
                url=""
                onClick={handleModal}
                image={dashboardIconPod} 
                edit={props.edit as any} 
                {...omit(props, 'edit')}
            />
            {podModal &&
                <PodModal onClose={() => setPodModal(false)} /> 
            } 
        </>
    );
            
}
export default  PodTile;