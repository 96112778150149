import { createReducer } from "@reduxjs/toolkit";
import { User } from "../../models/user";
import { userUpdateDetails, resetSessionTimer, updateUserPreference, updateSeqFieldMapping } from "./user.actions";
import { ScreenPermissionModal, SectionPermissionModal, CustomerReference, CarrierReference } from "../../models/user";
import { UserSettings } from "../../models/user-settings";
import { UserCommodity } from "../../models/user-commody";
import { UserPrefFieldMap } from "../../models/api/get-field-by-screen-id";
import { updateUserSettings } from "../../components/utils/windowHelper";

export interface UserState {
    user?: User;
    lastActive?: number,
    impersonated: boolean,
    screenPermissions?: ScreenPermissionModal;
    sectionPermissions?: SectionPermissionModal;
    isRoleInfoUpdate: boolean,
    defaultCustomer?: CustomerReference,
    defaultCarrier?: CarrierReference,
    parentUserDetails?: User,
    customerReferenceLists: CustomerReference[],
    carrierReferenceLists: CarrierReference[],
    userSettingsList: UserSettings[],
    userCommodityLists: UserCommodity[],
    hasMultipleAccounts: boolean,
    fieldSeqIdMapping: UserPrefFieldMap[]
}

export const initialState: UserState = {
    user: undefined,
    isRoleInfoUpdate: false,
    impersonated: false,
    customerReferenceLists: [],
    carrierReferenceLists: [],
    userSettingsList: [],
    userCommodityLists: [],
    hasMultipleAccounts: false,
    fieldSeqIdMapping: []
};

export const userReducer = createReducer(
    initialState,
    (builder) => {
        builder
            .addCase(userUpdateDetails, (state, action) => {
                return {
                    ...state,
                    user: action.payload.userdetails,
                    impersonated: action.payload.impersonated,
                    isRoleInfoUpdate: true,
                    screenPermissions: action.payload?.userRoleLists?.reduce((dictionary: ScreenPermissionModal, nextScreen) => ({
                        ...dictionary,
                        [nextScreen.screenId]: true,
                    }), {}),
                    sectionPermissions: action.payload?.userRoleLists?.reduce((dictionary: SectionPermissionModal, nextScreen) => ({
                        ...dictionary,
                        ...(nextScreen.screensectionmaster || []).reduce((sub: SectionPermissionModal, nextSection) => ({ 
                            ...sub,
                            [nextSection.screensectionid]: nextSection.accesstype,
                        }), {}),
                    }), {}),
                    defaultCustomer: action.payload.customerReferenceLists?.find(item => item.active),
                    defaultCarrier: [ ...(action.payload.carrierReferenceLists ?? [])]?.sort((x,y) => (x.isdefault === y.isdefault)? 0 : x.isdefault === 'Y' ? -1 : 1)?.[0],
                    parentUserDetails: action.payload.parentUserdetails,
                    customerReferenceLists: action.payload.customerReferenceLists ?? [],
                    carrierReferenceLists: action.payload.carrierReferenceLists ?? [],
                    userSettingsList: action.payload.userSettingsList,
                    userCommodityLists: action.payload.userCommodityLists ?? [],
                    hasMultipleAccounts: (action.payload.customerReferenceLists?.length ?? 0) > 1,
                }
            })
            .addCase(resetSessionTimer, (state, action) => {
                return {
                    ...state,
                    lastActive: action.payload,                    
                }
            })
            .addCase(updateUserPreference, (state, action) => {
                updateUserSettings(action.payload);
                let updatedSettings = [ ...(state?.userSettingsList ?? []) ];
                action.payload?.forEach((payLoad) => {
                    let index = updatedSettings?.findIndex((item) => item.seq_field_id === payLoad.seq_field_id )
                    if (index >= 0) {
                        updatedSettings[index] = { ...updatedSettings[index], ...payLoad };
                    } else  {
                        updatedSettings.push(payLoad);
                    }
                })
                return {
                    ...state,
                    userSettingsList: updatedSettings
                }
            })
            .addCase(updateSeqFieldMapping, (state, action) => {
                return {
                    ...state,
                    fieldSeqIdMapping: action.payload,                    
                }
            })
    }
);