import React from 'react';
import { useQuery } from '@apollo/client';
import { ActivityTile, ActivityTileProps } from '../common/ActivityTile';
import { omit } from 'lodash';
import recentDeliveriesFinal from '../../../assets/recentDeliveriesfinal.png';
import { GET_TRANSPORT_ORDER_COUNT } from '../../../graphql/Queries/getTransportOrderCount';
import { GetOrderListCountResponse, GetOrderListCountRequest } from '../../../models/api/get-transport-order-count';
import { OrderStatus } from '../../../models/order-status';
import dayjs from 'dayjs';

const RecentDeliveries = (props: Omit<ActivityTileProps, 'value' | 'url' | 'title' | 'image'>) => {
    const from = dayjs().subtract(1, 'day');
    const to = dayjs();
    const { data: bookingCount } = useQuery<GetOrderListCountResponse, GetOrderListCountRequest>(GET_TRANSPORT_ORDER_COUNT, {
        variables: {
            keywords: '',
            filterOrderStatus: [OrderStatus.DELIVERED],
            filterOrderToLocation: [],
            filterOrderCustomer: [],
            startDate: from.format('DD-MM-YYYY'),
            endDate:   to.format('DD-MM-YYYY'),
            pickupStartDate: null,
            pickupEndDate: null,
            isDeliveryDateSelected: false,
            isPalletsSelected: false,
            isSpacesSelected: false,
            isDeclineSelected: false,
            isMissedPickupSelected: false,
            isFavourite: false,
        },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-and-network'
    });

    return (
        <ActivityTile
            value={`${bookingCount?.getOrderListCount ?? ''}`}
            title="Recent booking deliveries"
            url={`/booking/list?status=${OrderStatus.DELIVERED}&from=${from.format('YYYYMMDD')}&to=${to.format('YYYYMMDD')}`}
            image={recentDeliveriesFinal}
            edit={props.edit as any}
            {...omit(props, 'edit')}
        />
    );
}
export default RecentDeliveries;