import { Box, Typography,  Tooltip } from '@material-ui/core';
import { useEffect, useRef, useState } from 'react';
import {
    GridRowsProp,
    GridColDef,
    GridRenderCellParams,
    GridSortModel,
    useGridApiContext,
    GridColumnHeaderParams
} from '@mui/x-data-grid-pro';
import { useQuery } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';
import './feedback.scss';
import { getBrowserName } from '../utils/browser';
import { GET_FEEDBACK } from '../../graphql/Queries/getFeedback';
import dayjs from 'dayjs';
import { Rating } from '@material-ui/lab';
import { CspDataGrid } from 'csp-common-ui';
import { GetFeedbackListMock, GetFeedbackRequest, GetFeedbackResponse } from '../../models/feedbackMock';
import { DownloadActions } from './DownloadActions';
import { PageTitle } from '../../models/page-title';
import { useHasPermission, Screens } from '../utils/permission';

const DarkTooltip = Tooltip;
const SortableHeaderRowComponent = (params: GridColumnHeaderParams) => {
    const apiRef = useGridApiContext();
    return (
        <Box sx={{ fontWeight: 'bold' }} display='flex' justifyContent='start' alignItems='center' data-testid={`${params.colDef.headerName}-header`}>
            {params.colDef.headerName}
            <DarkTooltip
                placement="bottom"
                title={<Typography variant="caption">{'Sort'}</Typography>}
            >
                <Box
                    className={`${apiRef.current.getSortModel()?.[0]?.field === params.field ? 'sortActive' : 'sortInActive'}`}
                    data-testid={`${(apiRef.current.getSortModel()?.[0]?.field === 'timestamp' && apiRef.current.getSortModel()?.[0]?.sort === 'desc') ? 'inactive-sort' : 'active-sort'}`}
                    width={32}
                    height={32}
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                    onClick={() => apiRef.current.setSortModel([
                        {
                            field: params.field,
                            sort: apiRef.current.getSortModel()?.[0]?.field === params.field && apiRef.current.getSortModel()?.[0]?.sort === 'asc' ? 'desc' : 'asc'
                        }
                    ] as GridSortModel)}
                >
                    {apiRef.current.getSortModel()?.[0]?.field === params.field && apiRef.current.getSortModel()?.[0]?.sort === 'asc' ? <i className="ri-arrow-down-line "/> : <i className="ri-arrow-up-line "/>}
                </Box>
            </DarkTooltip>
        </Box>
    );
}

const Feedback = () => {
    const location = useLocation();
    const [pageSize, setPageSize] = useState<number>(parseInt('25', 10));
    const [page, setPage] = useState<number>(parseInt('0', 10));
    const [dataGridRows, setDataGridRows] = useState<GridRowsProp[]>([]);
    const [selectionModel, setSelectionModel] = useState<string[]>([]);
    const urlParams = new URLSearchParams(location.search);
    const [downloadOpen, setDownloadOpen] = useState(false);
    const history = useHistory();
    const orderHash = useRef<{ [id: number]: GetFeedbackListMock}>({});
    const selectedRows = useRef<string[]>();
    const [currentPageStatus, setCurrentPageSatus] = useState({})
    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: urlParams.get('sortBy') ?? 'timestamp',
            sort: urlParams.get('sortOrder') ?? 'desc' as any,
        },
    ]);
    const { hasScreenPermission } = useHasPermission();
    document.title = PageTitle.FEEDBACK_PAGE;
    const { data: feedbackData, loading } = useQuery<GetFeedbackResponse, GetFeedbackRequest>(GET_FEEDBACK,
        {
            variables: {
                pageNo: page,
                pageSize: pageSize,
                properties: sortModel[0]?.field,
                direction: sortModel[0]?.sort !== 'asc' ? 'DESC' : 'ASC'
            },
            fetchPolicy: 'no-cache',
            onCompleted: (data) => {
                orderHash.current = {
                    ...orderHash.current,
                    ...(data?.getReviewsByPage?.list?.[0].reduce((acc, cur) => ({ ...acc, [cur.id-1]: cur}), {}))
                }
            }
        }
    );

    const getCurrentTimeZone = (str1: string, location: string) => {
        return new Date(
            new Date(str1).toLocaleString("en-US", { timeZone: location })
        );
    };

    const columns: GridColDef[] = [
        { field: "id", hide: true },
        {
            field: "rating",
            headerName: "Rating",
            minWidth: 270,
            sortable: false,
            renderHeader: SortableHeaderRowComponent,
            renderCell: (params: GridRenderCellParams<any>) => (
                <Box display='flex' alignItems='center' data-testid="rating-data">
                    <Rating
                        name="simple-controlled"
                        className="rating"
                        value={params.value}
                        precision={0.5}
                        readOnly
                    />
                </Box>
            ),
        },
        {
            field: "feedback",
            headerName: "Feedback",
            sortable: false,
            renderHeader: () => <Typography variant="subtitle2" >Feedback</Typography>,
            renderCell: (params: GridRenderCellParams) => (
                <DarkTooltip
                    placement="bottom"
                    title={
                        <Typography variant="caption">
                            {params.value}
                        </Typography>
                    }
                    disableHoverListener={
                        (params.value?.length ?? 0) < 45
                    }
                    >
                    <Typography variant="body2" style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
                        {params.value}
                    </Typography>
                </DarkTooltip>
            ),
            minWidth: 385,
        },
        {
            field: "userid",
            headerName: "Email",
            renderHeader: SortableHeaderRowComponent,
            width: 255,
            sortable: false,
            renderCell: (params: GridRenderCellParams) => (
                <DarkTooltip
                    placement="bottom"
                    title={
                        <Typography variant="caption">
                            {params.value}
                        </Typography>
                    }
                    disableHoverListener={
                        (params.value?.length ?? 0) < 30
                    }
                    >
                    <Typography variant="body2" style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
                        {params.value}
                    </Typography>
                </DarkTooltip>
            ),
        },
        {
            field: "type",
            headerName: "Type",
            renderHeader: () => <Typography variant="subtitle2">Type</Typography>,
            minWidth: 117,
            sortable: false,
        },
        {
            field: "timestamp",
            headerName: "Date",
            renderHeader: SortableHeaderRowComponent,
            renderCell: (params: GridRenderCellParams<any>) => (
                <Box display='flex' alignItems='center'>
                    {dayjs(getCurrentTimeZone(params.value?.timestamp, params.value?.timezone)).format('DD MMM YYYY')}
                </Box>
            ),
            minWidth: 133,
            sortable: false,
        },
        {
            field: "time",
            headerName: "Time",
            minWidth: 95,
            renderHeader: () => <Typography variant="subtitle2">Time</Typography>,
            renderCell: (params: GridRenderCellParams<any>) => (
                <Box display='flex' alignItems='center'>
                    {dayjs(getCurrentTimeZone(params.value?.timestamp, params.value?.timezone)).format('hh:mm A')}
                </Box>
            ),
            sortable: false,
        },
        {
            field: "timeZone",
            headerName: "TimeZone",
            minWidth: 95,
            renderHeader: () => <Typography variant="subtitle2">TimeZone</Typography>,
            sortable: false,
        },
        {
            field: "device",
            headerName: "Device",
            minWidth: 110,
            renderHeader: () => <Typography variant="subtitle2">Device</Typography>,
            sortable: false,
        },
        {
            field: "browser",
            headerName: "Browser",
            minWidth: 125,
            renderHeader: () => <Typography variant="subtitle2">Browser</Typography>,
            sortable: false,
        },
    ];

    useEffect(() => {
        if (feedbackData) {
            setDataGridRows(feedbackData?.getReviewsByPage?.list?.[0]?.map((item: any) => ({
                id: item?.id,
                rating: item?.rating,
                feedback: item?.usercomment === '' ? '-' : item?.usercomment,
                userid: item?.userid,
                type: item?.roletype,
                timestamp: item,
                time: item,
                timeZone: item?.timezone.split('/')[1],
                device: item?.deviceinfo,
                browser: getBrowserName(item?.systeminfo),
            })) as any);
        }
    }, [feedbackData]);

    const parseObjectToUrlParams = (options?: { [key: string]: string | string[] }): URLSearchParams => {
        let params: URLSearchParams = new URLSearchParams();
        if (options) {
            Object.keys(options).forEach((key) => {
                if (options[key] && Array.isArray(options[key])) {
                    (options[key] as string[]).forEach((item) => {
                        params.append(key, item);
                    })
                }
                else if (options[key]) {
                    params.append(key, options[key] as string);
                }
            });
        }
        return params;
    }

    useEffect(() => {
        const NewParams = parseObjectToUrlParams({
            sort:  sortModel[0]?.field !== 'timestamp' ? sortModel[0]?.field : '',
            sortOrder: sortModel[0]?.field !== 'timestamp' || `${sortModel[0]?.sort}` !== 'desc' ? sortModel[0]?.sort as string : '',
        }).toString();
        history.replace('/feedback' + NewParams ? '?' + NewParams : '');
    }, [history, page, sortModel]);

    const handleSelectionChange = (selected: string[]) => {
        setSelectionModel(selected as string[]);
    }

    useEffect(() => {
        selectedRows.current = selectionModel;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, sortModel]);

    useEffect(() => {
        setTimeout(() => setSelectionModel(selectedRows?.current ?? []), 10);
    }, [dataGridRows]);

    useEffect(()=>{
        if(feedbackData?.getReviewsByPage?.list){
            setCurrentPageSatus(feedbackData?.getReviewsByPage?.list?.[0].reduce((acc: any, cur: any) => ({ ...acc, [cur.id]: true}), {}))
        }
    }, [feedbackData, sortModel])

    return (
        <Box>
            { hasScreenPermission(Screens.USR_FEEDBACK, false) ?
        <Box className='user-feedback-page' data-testid='feedbackPage'>
            <Box display='flex' alignItems='center'>
                <Box >
                    <Typography variant='h1' color='textPrimary' data-testid="feedback-title">Users Feedback</Typography>
                </Box>
            </Box>
            <Box id="listing-table" data-testid="listing-table" pt={4}>
                <CspDataGrid
                    loading={loading}
                    rows={dataGridRows}
                    columns={columns}
                    sortModel={sortModel}
                    checkboxSelection
                    totalOrders={feedbackData?.getReviewsByPage?.totalNumber ?? 0}
                    onSortModelChange={(newSortModel: GridSortModel) => {
                        setSortModel(newSortModel);
                    }}
                    page={page}
                    onPageChange={(page: number) => setPage(page)}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize: number) => setPageSize(newPageSize)}
                    pagination 
                    paginationMode = 'server'
                    rowHeight={56}
                    disableStickyFooter
                    onSelectionModelChange={(selected) => {
                        handleSelectionChange(selected as string[]);
                    }}
                    actionButtons={[
                        {
                            icon: 'ri-download-cloud-2-line',
                            title: 'Download',
                            toolTip: 'Download',
                            onClick: () => setDownloadOpen(true),
                            disabled: false,
                            testid: 'download',
                        },
                    ]}
                    sortingMode='server'
                    selectionModel={selectionModel}
                    handleSelectAllPage={(checked: any) => setSelectionModel(checked ? dataGridRows.map((item: any) => item?.id) : [])}
                    selectAllTitle='Users'
                    showCellRightBorder
                    showColumnRightBorder
                />
            </Box>
            {downloadOpen
                &&  <Box data-testid='dowload-dialog'>
                <DownloadActions
                        currentPageStatus={currentPageStatus ?? {}}
                        open={downloadOpen}
                        feedbackData={selectionModel.map((item: any) => orderHash.current[item-1]).filter(item => !!item)}
                        onClose={() => { setDownloadOpen(false) }}
                    /></Box>}
        </Box>
        : '' }</Box>
    );
}

export default Feedback;