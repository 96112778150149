import { Screens } from "./permission";

export const GetDefaultUrl = (key?: string) => {
    switch(key) {
        case Screens.ORDR_CRT: return '/booking/create'; 
        case Screens.TMS_UPLOAD: return '/booking/bulk/create';
        case Screens.ORD_LIST: return '/booking/list';
        case Screens.POD_STATUS: return '/epod/status';
        default: return '/';
      }
}

export const truncateString = (str:string|undefined, length:number, end = "...", iniChar = 3) => {
  if(str){
      return str.length <= length ? str : str.substring(0, iniChar) + end + str.substring(str.length - (length - (iniChar + end.length)))
  } else {
      return '';
  }
}

export const chipTruncateString = (str:string|undefined, length: number, endChar: number, end = "...", iniChar = 3) => {
  if(str){
      return str.length <= length ? str : str.substring(0, iniChar) + end + str.substring(str.length - endChar)
  } else {
      return '';
  }
}

export const clearUserSessionStorage = () => {
  sessionStorage.clear()
}