import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { dispatchEvent } from '../../events/dispatchEvent';
import { eventsToDispatch } from '../../events/events';

export const ErrorPageRedirect = () => {
  const history = useHistory();

  useEffect(() => {
    dispatchEvent(eventsToDispatch.LOG_ERROR, { message: "Invalid Route" });
    history.push('/error-page');
  }, [history]);

  return (
    <></>
  );
}

export default ErrorPageRedirect;
