import { gql } from '@apollo/client';

export const GET_TRANSPORT_LOAD_STATUS_BY_IDS = gql`
query  GET_TRANSPORT_LOAD_STATUS_BY_IDS(
    $loadIds: [String]!,
    ){
        getLoadStatusByIds(loadIds: $loadIds)
        {
            status,
            id,
        }
    }`