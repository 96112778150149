import { gql } from '@apollo/client';

export const GET_ALL_LOADS = gql`
query(
  $pageSize: Int
  $currentPageNo: Int
  $isTenderActioned: Boolean
  $loadCollectionStartDate: String
  $loadCollectionEndDate: String
  $loadDeliveryStartDate: String
  $loadDeliveryEndDate: String
  $searchKeyword: String
  $filterCommodity: [String]
  $filterOrigins: [String]
  $filterCustomers: [String]
  $filterDestinations: [String]
  $isFavourite: Boolean
  $isPodNotSubmitted: Boolean,
  $missingPodDocument: Boolean,
  $variationsInPallets: Boolean,
  $variationsInSpaces: Boolean,
  $collectionDateTimeNotConfirmed: Boolean,
  ) {
getAllLoads(
    pageSize: $pageSize
    currentPageNo: $currentPageNo
    isTenderActioned: $isTenderActioned
    loadCollectionStartDate: $loadCollectionStartDate
    loadCollectionEndDate: $loadCollectionEndDate
    loadDeliveryStartDate: $loadDeliveryStartDate
    loadDeliveryEndDate: $loadDeliveryEndDate
    searchKeyWord: $searchKeyword
    filterCommodity: $filterCommodity
    filterOrigins: $filterOrigins
    filterCustomers: $filterCustomers
    filterDestinations: $filterDestinations
    isFavourite: $isFavourite
    isPodNotSubmitted: $isPodNotSubmitted
    missingPodDocument: $missingPodDocument
    variationsInPallets: $variationsInPallets
    variationsInSpaces: $variationsInSpaces
    collectionDateTimeNotConfirmed: $collectionDateTimeNotConfirmed
  ) {
    totalLoads
    loadList {
        loadId
        loadStatus
        loadDeliveryDetailsDto {
            loadDestination {
                plannedDeliveryDateUTC
                plannedDeliveryTimeZone
            }
        }
    }
  }
}
`;