import { useLazyQuery, useMutation } from "@apollo/client";
import { difference } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { eventsToListen } from "../../events/events";
import { GET_ASYNC_JOB_STATUS_BY_ID } from "../../graphql/Queries/getAsyncJobStatusById";
import { LOG_ERROR } from "../../graphql/Queries/logError";
import { GetAsyncJobStatusRequest, GetAsyncJobStatusResponse } from "../../models/api/getAsyncJobStatus";
import { LogErrorRequest, LogErrorResponse } from "../../models/api/logError";
import { AsyncJobStatus } from "../../models/async-job-status";
import { selectUserDetails } from "../../store/user/user.selectors";
import { saveFromUrl, wrapAzureUrl } from "../utils/download";

const ASYNC_JOB_SESSION_VARIABLE = 'async_jobs';

export interface EventFormat {
  message: string,
  url: string,
}


const EventHandler = () => {
  const history = useHistory();
  const userDetails = useSelector(selectUserDetails);
  const [logError] = useMutation<LogErrorResponse, LogErrorRequest>(LOG_ERROR);
  const [getJobStatus] = useLazyQuery<GetAsyncJobStatusResponse, GetAsyncJobStatusRequest>(GET_ASYNC_JOB_STATUS_BY_ID, {
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache'
  });
  
  const [prev, setPrev] = useState(window.location.href)

  const onError = useCallback((data: CustomEvent) => {
    logError({
      variables: {
        reason: data.detail?.message,
        accesspage: data.detail?.url ?? prev,
        usergroup: userDetails?.user?.roletype ?? '',
        username: userDetails?.user?.userid ?? '',
      }
    });
  }, [logError, prev, userDetails?.user?.roletype, userDetails?.user?.userid]); 


  

  const checkJobIsReady = useCallback((oneTime: boolean) => {
    console.log("Checking Download");
    const jobIds = (sessionStorage.getItem(ASYNC_JOB_SESSION_VARIABLE) ?? '').split(',')
      .map(item => item.trim())
      .filter(item => !!item);
    console.log("jobId", jobIds);
    if (jobIds?.length > 0) {
      getJobStatus({
        variables: {
          jobIds: jobIds
        }
      }).then(result => {
        const pendingJobs: string[] = [];
        result.data?.getAsyncJobStatusById?.map(job => {
          if (job.status === AsyncJobStatus.RUNNING) {
            pendingJobs.push(job.jobId);
          } else if (job.status === AsyncJobStatus.COMPLETED){
            saveFromUrl(wrapAzureUrl(job.filePath), job.name);
          }
          return false;
        });
        let tempJobIds = difference((sessionStorage.getItem(ASYNC_JOB_SESSION_VARIABLE) ?? '').split(','), jobIds);
        tempJobIds = [ ...tempJobIds, ...pendingJobs]; 
        sessionStorage.setItem(ASYNC_JOB_SESSION_VARIABLE, tempJobIds.join(','));
        if (!oneTime) {
          setTimeout(() => { checkJobIsReady(false) }, 10000);
        }
      })
      .catch(() => {
        if (!oneTime) {
          setTimeout(() => { checkJobIsReady(false) }, 60000);
        }
      });
    } else {
      if (!oneTime) {
        setTimeout(() => { checkJobIsReady(false) }, 20000);
      }
    }
  }, [getJobStatus]);

  useEffect(() => {
    checkJobIsReady(false);
  }, [checkJobIsReady]);

  const onCreateAsyncJob = useCallback((data: CustomEvent) => {
    console.log("New Job", data);
    if (sessionStorage.getItem(ASYNC_JOB_SESSION_VARIABLE)) {
      sessionStorage.setItem(ASYNC_JOB_SESSION_VARIABLE, `${sessionStorage.getItem(ASYNC_JOB_SESSION_VARIABLE)},${data.detail?.jobId}`);
    } else {
      sessionStorage.setItem(ASYNC_JOB_SESSION_VARIABLE, `${data.detail?.jobId}`);
    }
    checkJobIsReady(true);
  }, [checkJobIsReady]); 
  
  useEffect(() => {
    const unlisten = history.listen(() => {
      // This will be evaluated on every route change. 
      if (history.location.pathname !== '/error-url' && history.location.pathname !== '/error-page') {
        setPrev(window.location.href);
      }
    });
    // This function will be invoked on component unmount and will clean up
    // the event listener.
    return () => {
      unlisten();
    };
  }, [history]);

  useEffect(() => {
    window.addEventListener(eventsToListen.LOG_ERROR as any, onError);
    return () => window.removeEventListener(eventsToListen.LOG_ERROR as any, onError, false);
  }, [onError])

  useEffect(() => {
    window.addEventListener(eventsToListen.CREATED_ASYNC_JOB as any, onCreateAsyncJob);
    return () => window.removeEventListener(eventsToListen.CREATED_ASYNC_JOB as any, onCreateAsyncJob, false);
  }, [onCreateAsyncJob])

  return <></>
}

export default EventHandler;