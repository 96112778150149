import {
    Box,
    Typography,
} from '@material-ui/core';
import './UserProfilePage.scss';
import { useSelector } from 'react-redux';
import { selectUserDetails } from '../../store/user/user.selectors';
import { CspTabs as Tab, TabItem } from 'csp-common-ui';
import { useEffect, useState } from 'react';
import UserProfilePage from './UserProfilePage';
// import Emailnotification from './Emailnotification';
// import Inappnotification from './Inappnotification';


const TabMenu = () => {
    const userDetails = useSelector(selectUserDetails);
    const [tabs, setTabs] = useState<TabItem[]>([]);

    useEffect(() => {
        setTabs([
            {
                title: 'User Profile',
                icon: <i className='ri-user-settings-fill' />,
                children: <UserProfilePage />,
            },
            // {
            //     options: [
            //         {
            //             title: 'Email notifications',
            //             icon: <i className='ri-notification-2-line' />,
            //             children: <Emailnotification />,
            //         },
            //         {
            //             title: 'In app notifications',
            //             icon: <i className='ri-notification-2-line' />,
            //             children: <Inappnotification />,
            //         }
            //     ],
            //     minWidth: 250,
            //     type: 'dropdown',
            //     title: 'Notifications',
            //     icon: <i className='ri-notification-2-line' />,
            // },
        ]);
    },[]);
        
    
    return (
        <Box data-testid="user-settings" className="user-settings">
            <Box>
                <Typography variant="h1" color="textPrimary" data-testid='title-setting-page'>{userDetails.user?.firstname} {userDetails.user?.lastname}</Typography>
                <Box pt={4}>
                    <Tab tabs={tabs} defaultTab={0}/>
                </Box>
            </Box>
        </Box>
    );
}
export default TabMenu;