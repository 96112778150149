
import { Box,  Dialog, DialogTitle, IconButton, Typography} from '@material-ui/core';
import '../Dashboard.scss';
import  { useState, useEffect, useMemo } from 'react';
import { useLazyQuery } from '@apollo/client';
import { GetOrderListRequest, GetOrderListResponse } from "../../../models/api/get-orderlist";
import { GET_TRANSPORT_ORDER } from "../../../graphql/Queries/getTransportOrder";
import { CspTabs, TabItem } from "csp-common-ui";
import FavrouriteLoadModel from './FavouriteLoadModal'
import FavouritePOModal from './FavouritePOModal'
import { useSelector } from 'react-redux';
import { RoleType } from '../../../models/user-roles';
import { selectUserDetails } from '../../../store/user/user.selectors';
import { getAllLoadRequest, getAllLoadResponse } from '../../../models/api/getLoadList';
import { GET_ALL_LOADS } from '../../../graphql/Queries/getAllLoads';
interface FavouriteTileModal {
    onClose: () => void,
    currentTab: number
}

export const FavouriteModal = (props: FavouriteTileModal) => {
    const [currentTab, setCurrentTab] = useState<number>(props.currentTab);
    const userObj = useSelector(selectUserDetails);
    const userRoleType = userObj.user?.roletype;

    const [getAllLoads, {data: loadDataResponse }] = useLazyQuery<getAllLoadResponse, getAllLoadRequest>(GET_ALL_LOADS,{
        variables: {
            pageSize: 20,
            currentPageNo: 0,
            isTenderActioned: false,
            loadCollectionStartDate: '',
            loadCollectionEndDate: '',
            loadDeliveryStartDate: '',
            loadDeliveryEndDate: '',
            searchKeyword: '',
            filterCommodity: [],
            filterOrigins: [],
            filterCustomers: [],
            filterDestinations: [],
            isFavourite: true,
            isPodNotSubmitted: false,
            missingPodDocument: false,
            variationsInPallets: false,
            variationsInSpaces: false,
            collectionDateTimeNotConfirmed: false,
           },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
     });

    const [getAllPos, { data: orderData }] = useLazyQuery<GetOrderListResponse, GetOrderListRequest>(GET_TRANSPORT_ORDER,{
        variables: {
               sortBy: 'OID',
               sortOrder: 'DSC',
               pageSize: 20,
               currentPageNo: 0,
               keywords: '',
               filterOrderStatus: [],
               filterOrderToLocation: [],
               filterOrderCustomer: [],
               startDate: null,
               endDate: null,
               pickupStartDate: null,
               pickupEndDate: null,
               isDeliveryDateSelected: false,
               isPalletsSelected: false,
               isSpacesSelected: false,
               isDeclineSelected: false,
               isMissedPickupSelected: false,
               isFavourite: true,
           },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
    });

    useEffect(() => {
        if (RoleType.INTERNAL === userRoleType) {
            getAllPos();
            getAllLoads();
        } else if (RoleType.CARRIER === userRoleType) {
            getAllLoads();
        } else if (RoleType.CUSTOMER === userRoleType) {
            getAllPos();
        }
    }, [currentTab, getAllLoads, getAllPos, userRoleType]);

    const tabs = useMemo<TabItem[]>(() => [
        {
            children: <FavouritePOModal
                total={orderData?.getOrderList?.totalordersno ?? 0}
                data={orderData?.getOrderList?.orderlist}
                onUnFlag={() => {
                    getAllPos();
                }}
            />,
            title: 'Purchase orders',
            disabled: !(orderData?.getOrderList?.totalordersno && orderData.getOrderList.totalordersno > 0),
        },
        {
            children: <FavrouriteLoadModel
                total={loadDataResponse?.getAllLoads?.totalLoads ?? 0}
                data={loadDataResponse?.getAllLoads?.loadList}
                onUnFlag={() => {
                    getAllLoads();
                }}
            />,
            title: 'Loads',
            disabled: !(loadDataResponse?.getAllLoads?.totalLoads && loadDataResponse?.getAllLoads?.totalLoads > 0),
        },
    ], [getAllLoads, getAllPos, loadDataResponse?.getAllLoads?.loadList, loadDataResponse?.getAllLoads?.totalLoads, orderData?.getOrderList?.orderlist, orderData?.getOrderList?.totalordersno]);
    
    return (
        <>
            <Dialog open={true} data-testid="dialog-modal" className='favourite-dialog-box' onClose={props.onClose} fullWidth>
                <DialogTitle>
                    <Box display="flex" flexDirection="row" alignItems="center" className='favourite-title' data-testid="on-close" >
                        <i className="ri-star-fill star-color"></i>
                        <Typography className='favourite-order' variant='h3' color="textPrimary">Favourites</Typography>&nbsp;
                        <Typography variant='body1' color="textPrimary">
                            {userRoleType === RoleType.INTERNAL ? (
                                <>{currentTab === 1 
                                    ? (loadDataResponse?.getAllLoads?.totalLoads
                                        ? `(${loadDataResponse?.getAllLoads?.loadList?.length ?? 0} of ${loadDataResponse?.getAllLoads?.totalLoads})` : '')
                                    : (orderData?.getOrderList?.totalordersno 
                                        ? `(${orderData?.getOrderList?.orderlist?.length ?? 0} of ${orderData?.getOrderList?.totalordersno})` : '')
                                }</>
                            ) : ''}
                            {(userRoleType === RoleType.CARRIER && loadDataResponse?.getAllLoads?.totalLoads)
                                ? `(${loadDataResponse?.getAllLoads?.loadList?.length ?? 0} of ${loadDataResponse?.getAllLoads?.totalLoads})` : ''}
                            {(userRoleType === RoleType.CUSTOMER && orderData?.getOrderList?.totalordersno)
                                ? `(${orderData?.getOrderList?.orderlist?.length ?? 0} of ${orderData?.getOrderList?.totalordersno})` : ''}
                        </Typography>
                        <IconButton disableRipple style={{ position: "absolute", right: "29px" }} onClick={props.onClose}>
                            <i className="ri-close-fill" color='textSecondary'></i>
                        </IconButton> 
                    </Box>
                </DialogTitle>
                <Box display="flex" width="100%"  minHeight="300px">
                    {(userRoleType === RoleType.INTERNAL && (loadDataResponse?.getAllLoads || orderData?.getOrderList)) && (
                        <Box pb={2}>
                            <Box className='fav-margin-default'>
                                <CspTabs tabs={tabs} defaultTab={currentTab} onTabChange={(tab) => setCurrentTab(tab)}/>
                            </Box>
                        </Box>
                    )} 
                    {(userRoleType === RoleType.CARRIER && loadDataResponse?.getAllLoads) && (
                        <Box className="favourtie-modal-single">
                            <FavrouriteLoadModel
                                total={loadDataResponse?.getAllLoads?.totalLoads ?? 0}
                                data={loadDataResponse?.getAllLoads?.loadList}
                                onUnFlag={() => {
                                    getAllLoads();
                                }}
                            />
                        </Box>
                    )}
                    {(userRoleType === RoleType.CUSTOMER && orderData?.getOrderList) && (
                        <Box className="favourtie-modal-single">
                            <FavouritePOModal
                                total={orderData?.getOrderList?.totalordersno ?? 0}
                                data={orderData?.getOrderList?.orderlist}
                                onUnFlag={() => {
                                    getAllPos();
                                }}
                            />
                        </Box>
                    )} 
                </Box>
            </Dialog>
        </>
    );
}