import { Box, Typography } from "@material-ui/core";
import './QRCodeCSP.scss';

interface QRCodeProps{
    link: string,
    size?:number
}
export const QRCodeCSP = ({link, size=128} :QRCodeProps) =>{
    var QRCode = require('qrcode.react');
    return <Box className="qrcode">
                <Box sx={{ p: 1}}>
                    <QRCode size={size} value={link} data-testid="QRCode"/>
                </Box>
                <Box className="bottom-box">
                    <Box className="bottom-content">
                        <i className="ri-smartphone-line"></i>
                        <Typography variant="h3">Scan</Typography>
                    </Box>
                </Box>
    </Box>;
}
export default QRCodeCSP;