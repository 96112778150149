/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useContext } from 'react';
import Alert from '@material-ui/lab/Alert';
import { Box, Typography } from '@material-ui/core';
import { useMutation } from '@apollo/client';
import './SuccessAlert.scss';
import { useSelector } from 'react-redux';
import { selectLatestOrder } from '../../store/order/order.selectors';
import { closeCreateOrderSuccess } from '../../store/order/order.actions';
import { store } from '../../store';
import { Link } from 'react-router-dom';
import PrintConnote from '../PrintConnote/PrintConnote';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { FRUITANDVEG } from '../utils/bookingType';
import { createAsyncBatchJobRequest, createAsyncBatchJobResponse } from '../../models/api/create-async-batch-job';
import { CREATE_ASYNC_BATCH_JOB } from '../../graphql/Queries/createBatchJob';
import { AsyncJobTypes } from '../../models/async-job-types';
import { AlertContext } from '../../contexts/alert-context';
import { eventsToDispatch } from '../../events/events';
import { dispatchEvent } from '../../events/dispatchEvent';
import { SectionPermission, Sections } from '../utils/permission';
import { OrderStatus } from '../../models/order-status';
import { selectUserDetails } from '../../store/user/user.selectors';
import { RoleType } from '../../models/user-roles';

export default function SuccessAlert() {
  const userObj = useSelector(selectUserDetails);
  const userRoleType = userObj.user?.roletype;
    
  const [isClosed, setIsClosed] = useState(false);
  const latestOrder = useSelector(selectLatestOrder);
  const [open, setOpen] = useState(false);
  const [cloneLatestOrder, setCloneLatestOrder] = useState<number>();
  const { alert, confirm } = useContext(AlertContext);
  const [createAsyncJob] = useMutation<createAsyncBatchJobResponse, createAsyncBatchJobRequest>(CREATE_ASYNC_BATCH_JOB);

  useEffect(()=>{
      if (latestOrder){
        setCloneLatestOrder(latestOrder.transportOrderId)
      }
  }, [latestOrder])
  if (isClosed) {
    return null;
  }

  const startAsyncJobRequest = (transportOrderId: number, docTypes: string[], name: string) => {
    createAsyncJob({
      variables: {
        docTypes: docTypes,
        name: name,
        transportOrderIds: [transportOrderId.toString()],
        type: AsyncJobTypes.PALLET_LABEL
      }
    }).then((response) => {
      dispatchEvent(eventsToDispatch.CREATED_ASYNC_JOB, { jobId: response.data?.createAsyncJob?.jobId });
    }).catch(() => {
      alert({
        message: "Something went wrong. Please try again",
        severity: 'error'
      });
    });
  }

  const handleDownload = () => {
    if (cloneLatestOrder) {
      startAsyncJobRequest(cloneLatestOrder, ["Physical"], "Pallet-Labels.pdf");
      confirm({
          title: <Typography variant="h3">Downloading pallet labels</Typography>,
          message: 
              <Box maxWidth={500} py={2}>
                  <Typography variant="body1">
                      It will take a few minutes to retrieve all the pallet labels. They will be downloaded automatically when ready. Feel free to navigate away from this page.
                  </Typography>
              </Box>,
          hideCancelButton: true,
          onSuccess: () => {}
      });
    }
  }

  const ICONMAP: any = {
    icon: <i className="ri-checkbox-circle-fill success-icon"></i>,
    iconClose: <i data-testid='success-alert-close' className="ri-close-fill close-icon" onClick={() => {
      setIsClosed(true);
      store.dispatch(closeCreateOrderSuccess());
    }}></i>
  }
  dayjs.extend(customParseFormat);
  return (
    <div>
      {latestOrder && <div>
        <Alert
          icon ={ICONMAP["icon"]}
          action ={ICONMAP["iconClose"]}
          className = "alert"
        > 
        <div data-testid='success-alert-container' className="details">
              {latestOrder?.bookingType === FRUITANDVEG ?
                <><div className="order-text"> {`${latestOrder?.orderType === 'create' ? 'Booking created for:' : 'Booking updated for:'}`} {latestOrder?.poNumber} </div><br /></> : 
                <><div className="order-text">Transport booking <Link to={`/booking/detail/${latestOrder?.transportOrderId}`}>{latestOrder?.transportOrderId}</Link> {`${latestOrder?.orderType === 'create' ? 'created!' : 'updated!'}`}</div><br /></>}
                <div>Collection: {dayjs (latestOrder?.deliveryDetails?.origin?.pickUpDate, 'DD-MM-YYYY').format('DD MMM YYYY')}{latestOrder?.bookingType !== FRUITANDVEG && `,${latestOrder?.deliveryDetails?.origin?.pickUpTime}`}   </div>
                <div>Delivery: {dayjs (latestOrder?.deliveryDetails?.destination?.deliveryDate, 'DD-MM-YYYY').format('DD MMM YYYY')}{latestOrder?.bookingType !== FRUITANDVEG && `,${latestOrder?.deliveryDetails?.destination?.deliveryTime}`} </div><br/>
                <div className="link">
                  <div><a href={`/booking/detail/${latestOrder?.transportOrderId}`} style={{color: '#3D4548'}}>View transport booking</a></div>
                  <div><a href="#" onClick={() => {setOpen(true);}} data-testid="on-open" style={{color: '#3D4548'}}>Print connote</a></div>
                  <SectionPermission permissions={[Sections.DOWNLOAD_PLT_LBL_ALERT]}>
                    {(userRoleType?.toLowerCase() === RoleType.INTERNAL?.toLowerCase() || (latestOrder.orderStatus !== OrderStatus.DELIVERED && latestOrder.orderStatus !== OrderStatus.INVOICED) ) && 
                      <div><a href="#" onClick={() => {handleDownload()}} data-testid="on-download" style={{color: '#3D4548'}}>Download pallet labels</a></div>
                    }
                  </SectionPermission>                    
                </div>
        </div>
       {open && <PrintConnote handleClose={() => {setOpen(false);   store.dispatch(closeCreateOrderSuccess())}} orderId={cloneLatestOrder}/>}
        </Alert>
      </div>}
    </div>
  );
}