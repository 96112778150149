import React from 'react';
import Box from '@material-ui/core/Box';
import NavBar from './NavBar';
import NavBarExpanded from './NavBarExpanded';

const Navigation = () => {
    const small = {
        xs: 'block',
        sm: 'block',
        md: 'none',
        lg: 'none',
        xl: 'none'
    }
    const medium = {
        xs: 'none',
        sm: 'none',
        md: 'block',
        lg: 'block',
        xl: 'none'
    }
    const large = {
        xs: 'none',
        sm: 'none',
        md: 'none',
        lg: 'none',
        xl: 'block'
    }

    return (
        <>
            <Box sx={{ display: { ...medium } }}><NavBar /></Box>
            <Box sx={{ display: { ...large } }} ><NavBarExpanded /></Box>
            <Box sx={{ display: { ...small } }} ><NavBarExpanded small /></Box>
        </>
    )
}

export default Navigation;