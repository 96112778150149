import { createAction } from "@reduxjs/toolkit";
import { Order } from "../../models/order";

export const enum OrderActions {
    CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS',
    CLOSE_CREATE_ORDER_SUCCESS_ALERT = 'CLOSE_CREATE_ORDER_SUCCESS_ALERT',
    UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS'
}

export const createOrderSuccess = createAction(OrderActions.CREATE_ORDER_SUCCESS, (payload: Order) => ({payload}));
export const closeCreateOrderSuccess = createAction(OrderActions.CLOSE_CREATE_ORDER_SUCCESS_ALERT);
export const updateOrderSuccess = createAction(OrderActions.UPDATE_ORDER_SUCCESS, (payload: Order) => ({payload}));
