import { gql } from '@apollo/client';

export const GET_TRANSPORT_ORDER_STATUS_BY_IDS = gql`
query  GET_TRANSPORT_ORDER_STATUS_BY_IDS(
    $transportOrderIds: [String]!,
    ){
        getTransportOrderStatusByIds(transportOrderIds: $transportOrderIds)
        {
            status,
            id,
            deliveryDatetimeStamp
        }
    }`