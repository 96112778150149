
import { useState } from "react";
import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, Switch, Typography} from '@material-ui/core';
import { Alert } from '@mui/material';
import { SectionPermission, useHasPermission } from '../../utils/permission/';
import {  ActivityTile } from "../Dashboard";
import '../Dashboard.scss';

interface ActivityTileModal  {
    onClose: () => void,
    onSave: (tiles: ActivityTile[]) => void,
    tiles: ActivityTile[],
}

export const AddOrDeleteActivityTileModal = (props: ActivityTileModal) => {
    const [displayAlert, setDisplayAlert] = useState<boolean>(false);
    const [tiles, setTiles] = useState<ActivityTile[]>(props.tiles);
    const { hasSectionPermission } = useHasPermission();

    const handleSelection = (value:string) => {
        if (tiles.filter((tile) => tile.display && tile.permission.filter((permission) => hasSectionPermission(permission)).length > 0 ).length > 1) {
            // more than 1 active tile
            setTiles(tiles.map(item => item.value === value ? { ...item, display: !item.display } : item));
            setDisplayAlert(false);
        } else{
            const currentIndex = tiles.findIndex(item => item.value === value);
            if (currentIndex >= 0 ) {
                if (!tiles[currentIndex].display) {
                    setTiles(tiles.map(item => item.value === value ? { ...item, display: !item.display } : item));
                    setDisplayAlert(false);
                } else {
                    setDisplayAlert(true);
                }
            }
            
        }
    }

    return (
        <Dialog data-testid="dialog-open" open={true} className='add-dialog-box' onClose={props.onClose}>
            <DialogTitle className="dialog-main-title">
                <Box className='dialog-title' data-testid="on-close">
                <Typography variant='h3'>Activity Overview</Typography>
                <i className="ri-close-fill" onClick={props.onClose}></i>
                </Box>
            </DialogTitle>
            <DialogContent className='dialog-content'>
                {displayAlert && <Alert className='dialog-alert' variant='outlined' icon={<i className="ri-information-fill"/>} severity='info'><Typography variant='body2'>Atleast one item has to be selected.</Typography></Alert>}
                <Grid>
                {tiles.map((option) =>(
                    <SectionPermission permissions={option.permission}>
                        <Box className='dialog-switch'>
                            <Typography variant='body1'>{option.title}</Typography>
                            <Switch color='primary' value={option.value} checked={option.display} onChange={(e)=>handleSelection(e.target.value)}/>
                        </Box>
                    </SectionPermission>
                ))}
                <Box className='dialog-buttons' >
                    <Button onClick={props.onClose} style={{'marginRight':12, textTransform:'none'}} size='small' variant='outlined'>Cancel</Button>
                    <Button data-testid="dialog-save-button" onClick={() => props.onSave(tiles)} style={{ textTransform:'none'}} size='small' variant='contained' color='primary'>Save</Button>
                </Box>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}