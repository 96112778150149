import React from 'react'
import { Box, Typography, IconButton } from '@material-ui/core'
import './OutageBanner.scss'

interface OutageBannerProps {
  onClose: () => void
  outageBannerMessage: Array<[]>
}

const OutageBanner = ({ onClose, outageBannerMessage }: OutageBannerProps) => {
  const closeOutageBanner = () => {
    localStorage.setItem('outageBannerStatus', 'hide')
    onClose()
  }

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      className="outage-banner-container"
    >
      <Box display="flex" alignItems="center" className="info-content">
        <Typography variant="body1">
          <i className="ri-error-warning-fill"></i>
        </Typography>
        <Typography variant="subtitle2" data-testid='outage-banner-message'>
          {(outageBannerMessage ?? []).map((item: any) => item.lookupchar)}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        <IconButton
          className="close-btn"
          onClick={closeOutageBanner}
          data-testid='close-outage-banner'
          disableRipple
        >
          <i className="ri-close-fill"></i>
        </IconButton>
      </Box>
    </Box>
  )
}
export default OutageBanner
