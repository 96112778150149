import { gql } from '@apollo/client';

export const GET_USERS = gql`
    query getImpersonateUsers(
        $tmsref: String
        $companyno: String
        $roletype: String
        $servicetype: String
    ) {
    getImpersonateUsers(
        tmsref: $tmsref,
        companyno: $companyno,
        roletype: $roletype,
        servicetype: $servicetype
    ) {
        userid
        firstname
        lastname
    }
}`