import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export const downloadPdf = (id: string, filename: string) => {
  const connote = document.getElementById(id);
  connote && html2canvas(connote)
    .then((canvas) => {
      const imgData = canvas.toDataURL('image/png',0);
      const pdf = new jsPDF("landscape","mm","a4");
      pdf.addImage(imgData, 'JPEG', 0, 0, 297, 210);
      pdf.save(filename);
    })
  ;
}

export const openPdf = (id: string) => {
  const connote = document.getElementById(id);
  const pdf = new jsPDF('p', 'mm', [210, 210]);
  connote && pdf.html(connote, {
    callback: (pdf) => {
        pdf.output('dataurlnewwindow', {filename:'connote'});
    },
    width: 210,
    windowWidth: 800
  });
}