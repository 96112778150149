import React, { useState } from 'react';
import dayjs from 'dayjs';

import { useQuery } from '@apollo/client';
import { GET_COMPLIANCE_REPORT } from '../../../graphql/Queries/getCompliance';
import { getCompliancePercentageParams, getComplianceResponse } from '../../../models/dashbaord';
import { getDefaultDate, getTimeStampOfDate } from '../../utils/time';

import { ActivityTileTypeB, ActivityTileProps } from '../common/ActivityTile';
import { omit } from 'lodash';

const ComplianceReportTile = (props: Omit<ActivityTileProps, 'value' | 'url' | 'title' | 'image'>) => {
    const [from] = useState( dayjs(getDefaultDate()).subtract(30, 'days').toDate());
    const [to] = useState( dayjs(getDefaultDate()).subtract(1, 'days').toDate());

    const { data: compliance } = useQuery<getComplianceResponse, getCompliancePercentageParams>(GET_COMPLIANCE_REPORT, {
        variables: {
            delFromDate: getTimeStampOfDate(from),
            delToDate: getTimeStampOfDate(to, true),
        },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-and-network'
    });

    return (
        <ActivityTileTypeB
            value={`${parseFloat(compliance?.getCompliancePercentage ?? '0').toFixed(2)}%`}
            title="ePOD overall compliance"
            url={`/report/compliance?from=${dayjs(from).format('YYYY-MM-DD')}&to=${dayjs(to).format('YYYY-MM-DD')}`}
            dateRange="Last 30 days"
            edit={props.edit as any}
            {...omit(props, 'edit')}
        />
    );
}
export default ComplianceReportTile;