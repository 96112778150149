import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import arrow from '../../../assets/Vector.png';
import dragIcon from '../../../assets/drag-icon.png';
import { Box } from "@material-ui/core";


interface ActivityTileDefaultProps {
    url?: string,
    title: string,
    active: boolean,
    display: boolean,
    mapping: string,
    value: string,
    edit: boolean,
    dateRange?: string,
    image?: any,
    onClick?: () => void,
    hideArrow?: boolean
}

interface EditProps extends ActivityTileDefaultProps {
    edit: true,
    position: number,
    onHide: (e: string) => void,
    onStart: (e: any) => void,
    onDragOver: (e: any) => void
}


interface NonEditProps extends ActivityTileDefaultProps {
    edit: false,
}


export type ActivityTileProps = EditProps | NonEditProps;

export const ActivityTile = (props: EditProps | NonEditProps) => {
    return (
        props.display ? (
            <Grid item xs={12} sm={6} md={4} lg={4} xl={3} className="grid-item">
                {props.edit ? (
                    <div draggable onDragEnterCapture={(e: any) => props?.onDragOver(e)} onDragStart={(e: any) => props?.onStart(e)} id={props.mapping} className="dashboard-button dashboard-activity-overview-buttons" data-testid='draggable'>
                        <img src={dragIcon} draggable={false} style={{ marginLeft: 24 }} alt={props.mapping} />
                        <img draggable={false} style={{ marginLeft: 16, marginRight: 16 }} className="dashboard-card-space" src={props.image} alt={props.mapping} />
                        <span style={{ marginRight: 'auto' }} className="dashboard-card-space dashboard-card-desc">{props.title}
                            <p className="dashboard-card-number" data-testid="test-tile-value-edit">{props.value ?? '0'}</p>
                        </span>
                        <i onClick={() => props?.onHide(props.mapping)} className="ri-eye-off-line ri-lg" style={{ color: '#263238', opacity: '54%', float: 'right', paddingRight: 16 }} data-testid='hide-button'></i>
                    </div>
                ) : (
                    <div className="dashboard-button dashboard-activity-overview-buttons">
                        <Link to={props.url ?? ''} onClick={props.onClick} className="dashboard-card-button">
                            <img className="dashboard-card-left-icon" src={props.image} alt={props.mapping} />
                            <Box px={2}>
                                <span className="dashboard-card-desc">
                                    {props.title}
                                    <p className="dashboard-card-number" data-testid="test-tile-value">{props.value ?? '0'}</p>
                                </span>
                            </Box>
                            <div className="dashboard-arrow-image" style={{ display: props.hideArrow ? "none" : "block" }}>
                                <img src={arrow} alt={props.mapping} />
                            </div>
                        </Link>
                    </div>
                )}
            </Grid>
        ) : <></>
    );
}


export const ActivityTileTypeB = (props: EditProps | NonEditProps) => {
    return (
        props.display ? (
            <Grid item xs={12} sm={6} md={4} lg={4} xl={3} className="grid-item">
                {props.edit ? (
                    <div draggable onDragEnterCapture={(e: any) => props?.onDragOver(e)} onDragStart={(e: any) => props?.onStart(e)} id={props.mapping} className="dashboard-button dashboard-activity-overview-buttons" data-testid='draggable'>
                        <img src={dragIcon} draggable={false} style={{ marginLeft: 24 }} alt={props.mapping} />
                        <Box className="dashboard-card-button-type-b">
                            <Box display='flex' justifyContent='space-between' >
                                <span className="dashboard-card-desc">{props.title}</span>
                                <span className="dashboard-card-desc dashboard-card-date-range">{props.dateRange}</span>
                            </Box>
                            <Box display='flex' justifyContent='space-between' alignItems='center'>
                                <span className="dashboard-card-number" data-testid="test-tile-value-edit">{props.value ?? '0'}</span>
                                <img className="dashboard-arrow-image" src={arrow} alt={props.mapping} />
                            </Box>
                        </Box>
                        <i onClick={() => props?.onHide(props.mapping)} className="ri-eye-off-line ri-lg" style={{ color: '#263238', opacity: '54%', float: 'right', paddingRight: 16 }} data-testid='hide-button'></i>
                    </div>
                ) : (
                    <div className="dashboard-button dashboard-activity-overview-buttons">
                        <Link to={props.url ?? ''} onClick={props.onClick} className="dashboard-card-button-type-b">
                            <Box display='flex' justifyContent='space-between' >
                                <span className="dashboard-card-desc">{props.title}</span>
                                <span className="dashboard-card-desc dashboard-card-date-range">{props.dateRange}</span>
                            </Box>
                            <Box display='flex' justifyContent='space-between' alignItems='center'>
                                <span className="dashboard-card-number" data-testid="test-tile-value">{props.value ?? '0'}</span>
                                <img className="dashboard-arrow-image" src={arrow} alt={props.mapping} />
                            </Box>
                        </Link>
                    </div>
                )}
            </Grid>
        ) : <></>
    );
}