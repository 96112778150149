import { useState, createContext, ReactNode, useCallback } from "react";
import {CspSnackBar, CspSnackBarProps } from "csp-common-ui";
import {ConfirmationDialog, ConfirmationDialogProps } from "csp-common-ui";
import {Notification} from 'csp-common-ui';
import { v4 as uuidv4 } from 'uuid';
import { omit } from "lodash";
import React from "react";

const MemoCspSnackBar = React.memo(CspSnackBar, (prev, next) => prev.message === next.message );

const getNodeText = (node: any): string => {
    if (["string", "number"].includes(typeof node)) return node as string;
    if (node instanceof Array) return node.map(getNodeText).join(" ");
    if (typeof node === "object" && node) return getNodeText(node.props.children);
    return '';
};
  
const getWordCount = (message: string | ReactNode): number => getNodeText(message).trim().split(/\s+/).length;

export const AlertContext = createContext({
    alert: (item: AlertContent) => {},
    confirm: (item: ConfirmationDialogProps) => {},
    notification: (item: NotificationProps) => {},
});

interface AlertContent extends Omit<CspSnackBarProps, 'autoHide'> {
    timeout?: number,
};

interface AlertStack extends AlertContent {
    id: string
};

interface NotificationContent {
    message: string,
}

interface NotificationProps extends NotificationContent {
    timeout?: number
}

interface NotificaitonStack extends NotificationContent {
    id: string
};

export const AlertProvider = ({ children }: { children: ReactNode }) => {
    const [alertContent, setAlertContent] = useState<AlertStack[]>([]);
    const [confirmation, setConfirmation] = useState<ConfirmationDialogProps | null>(null);
    const [notificationContent, setNotificationContent] = useState<NotificaitonStack[]>([]);

    const alert = useCallback(({ timeout, message, inout = 1000, ...rest }: AlertContent) => {
        let time = timeout;
        if (!time) {
            const wordCount = getWordCount(message);
            time = (wordCount > 4 ? wordCount : 4) * 1000 + inout * 2;
        }
        const id = uuidv4();
        setAlertContent((prev) => ([
            { ...rest, message, id, timeout: time, inout}
        ]) );
        setTimeout(() => {
            setAlertContent((stack) => stack.filter((item) => item.id !== id));
        }, time)
    },[])

    const notification = useCallback(({ timeout = 3000, ...rest }: NotificationProps) => {
        const id = uuidv4();
        setNotificationContent((prev) => ([
            { ...rest, id}
        ]) );
        setTimeout(() => {
            setNotificationContent((stack) => stack.filter((item) => item.id !== id));
        }, timeout)
    }, [])

    const confirm = useCallback((item: ConfirmationDialogProps) => {
        setConfirmation(item);
    }, [])



    return (
        <AlertContext.Provider value={{ confirm, alert, notification }}>
            {children}
            {alertContent && alertContent.map((alert) => <MemoCspSnackBar key={alert.id} autoHide={true} {...alert} />)}
            {notificationContent && notificationContent.map((notification) => <Notification key={notification.id} {...notification} />)}
            {confirmation && (<ConfirmationDialog 
                onClose = {() => {
                    if (confirmation?.onClose) {
                        confirmation?.onClose();
                    }
                    setConfirmation(null);
                }}
                onSuccess = {() => {
                    confirmation.onSuccess();
                    setConfirmation(null);
                }}
                {...omit(confirmation, ['onClose', 'onSuccess']) }
            />)}
        </AlertContext.Provider>
    )
}