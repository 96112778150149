import { gql } from '@apollo/client';

export const UPDATE_USER_SETTINGS = gql`
mutation USER_USER_SETTINGS (
    $request: [SetUserSettingsRequestInput]
) {
    setUserSettings(
        request: $request
    ) {
        component_desc,
        seq_field_id,
        field_value,
        preference_type_no,
    }
}
`;