export enum OrderStatus {
    PENDING = "PENDING",
    PROCESSING = "PROCESSING",
    SUBMITTED = "SUBMITTED",
    PLANNED = "PLANNED",
    CONFIRMED = "CONFIRMED",
    INCOMING = "INCOMING",
    INTRANSIT = "INTRANSIT",
    DELIVERED = "DELIVERED",
    CANCELLED = "CANCELLED",
    INVOICED = "INVOICED",

}