import React, { useCallback, useState } from "react";
import './Notification.scss';
import { IconButton, Box, Typography, Popper, CircularProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useLazyQuery } from "@apollo/client";
import { GetNotificationByUserIdRequest, GetNotificationByUserIdResponse } from "../../models/api/get-notification-userid";
import dayjs from "dayjs";
import { currentAustDate, getAustTimeZone } from "../utils/time";
import { Link } from "react-router-dom";
import { GET_NOTIFICATION_BY_USERID } from "../../graphql/Queries/getNotificationByUserid";
import { NotificationItem } from "../../models/api/notification-item";
import { NotificationTemplates } from "../../models/api/notification-templates";
import InfiniteScroll from "react-infinite-scroll-component";
import { debounce } from "lodash";
import { truncateString } from "../utils/helper";
import { useSelector } from "react-redux";
import { selectUserDetails } from "../../store/user/user.selectors";
import { RoleType } from "../../models/user-roles";

const formatOrderListLink = (roleType: string, item: NotificationItem, onClose: () => void) => {
    if (roleType === RoleType.CARRIER.toLowerCase()) {
        return item.ponumber === "" ? item.orderid : truncateString(item.ponumber, 18)
    } else {
        return <Link className='notification-link' onClick={onClose} to={`/booking/detail/${item.orderid}`}>{item.ponumber === "" ? item.orderid : truncateString(item.ponumber, 18)}</Link>
    }
}

const handleMessage = (item: NotificationItem, onClose: () => void, roleType: string) => {
    if (item.template === NotificationTemplates.CreatedOrderToCarrier || item.template === NotificationTemplates.CreatedOrderToCustomer || item.template === NotificationTemplates.CreatedOrderToPlanner) {
        return <Typography variant="body2" color="textPrimary" >
           PO# {formatOrderListLink(roleType, item, onClose)} has been created.
        </Typography>
    }
    else if (item.template === NotificationTemplates.DeliveredToCustomer || item.template === NotificationTemplates.DeliveredToPlanner) {
        return <Typography variant="body2" color="textPrimary" >
            PO# {formatOrderListLink(roleType, item, onClose)} has been delivered.
        </Typography>
    }
    else if (item.template === NotificationTemplates.PalletsSpacesDifferenceToCustomer || item.template === NotificationTemplates.PalletsSpacesDifferenceToPlanner) {
        return <Typography variant="body2" color="textPrimary" >
            PO# {formatOrderListLink(roleType, item, onClose)} has a mismatch in quantity picked up.
        </Typography>
    }
    else if (item.template === NotificationTemplates.PalletsSpacesDifferenceToCarrier) {
        return <Typography variant="body2" color="textPrimary" >
            Load ID# {item.loadid} of PO# {formatOrderListLink(roleType, item, onClose)} has a mismatch in quantity picked up.
        </Typography>
    }
    else if (item.template === NotificationTemplates.ProductDamagedToCustomer || item.template === NotificationTemplates.ProductDamagedToPlanner) {
        return <Typography variant="body2" color="textPrimary" >
            PO# {formatOrderListLink(roleType, item, onClose)} has damages reported.
        </Typography>
    }
    else if (item.template === NotificationTemplates.ProductDamagedToCarrier) {
        return <Typography variant="body2" color="textPrimary" > 
            Load ID# {item.loadid} of PO# {formatOrderListLink(roleType, item, onClose)} has damages reported.
        </Typography>
    }
    else if (item.template === NotificationTemplates.CancelledToCustomer || item.template === NotificationTemplates.CancelledToPlanner) {
        return <Typography variant="body2" color="textPrimary" >
            PO# {formatOrderListLink(roleType, item, onClose)} has been canceled.
        </Typography>
    }
    else if (item.template === NotificationTemplates.CancelledToCarrier) {
        return <Typography variant="body2" color="textPrimary" >
            Load ID# {item.loadid} of PO# {formatOrderListLink(roleType, item, onClose)} has been canceled.
        </Typography>
    }
    else if (item.template === NotificationTemplates.POPToPlanner || item.template === NotificationTemplates.POPToCustomer) {
        return <Typography variant="body2" color="textPrimary" >
            PO# {formatOrderListLink(roleType, item, onClose)} is In Transit.
        </Typography>
    }
    else if (item.template === NotificationTemplates.CarrierReConfirmToCustomer) {
        return <Typography variant="body2" color="textPrimary" >
            PO# {formatOrderListLink(roleType, item, onClose)} has been updated.
        </Typography>
    }
    else if (item.template === NotificationTemplates.CarrierReConfirmToCarrier) {
        return <Typography variant="body2" color="textPrimary" >
            Load ID# {item.loadid} of PO# {formatOrderListLink(roleType, item, onClose)} has been updated.
        </Typography>
    }
    else if (item.template === NotificationTemplates.CarrierDeclinedToPlanner || item.template === NotificationTemplates.CarrierDeclinedToCustomer) {
        return <Typography variant="body2" color="textPrimary" >
            PO# {formatOrderListLink(roleType, item, onClose)} is declined.
        </Typography>
    }
    else if (item.template === NotificationTemplates.PendingMoreThanOneHourToPlanner) {
        return <Typography variant="body2" color="textPrimary" >
            PO# {formatOrderListLink(roleType, item, onClose)} is pending confirmation.
        </Typography>
    }
    else if (item.template === NotificationTemplates.PendingMoreThanOneHourToCarrier) {
        return <Typography variant="body2" color="textPrimary" >
            Load ID# {item.loadid} of PO# {formatOrderListLink(roleType, item, onClose)} is pending confirmation.
        </Typography>
    }
    else if (item.template === NotificationTemplates.CarrierConfirmed) {
        return <Typography variant="body2" color="textPrimary" >
            PO# {formatOrderListLink(roleType, item, onClose)} has been confirmed.
        </Typography>
    }
    else if (item.template === NotificationTemplates.AssignCarrier) {
        return <Typography variant="body2" color="textPrimary" >
            Load ID# {item.loadid} of PO# {formatOrderListLink(roleType, item, onClose)} has been received.
        </Typography>
    }
    else if (item.template === NotificationTemplates.BulkUploadToCustomer || item.template === NotificationTemplates.BulkUploadToPlanner) {
        return <Typography variant="body2" color="textPrimary" >
            A Bulk Purchase Order has been uploaded, <Link className='notification-link' onClick={onClose} to={`/booking/list?batchId=${item.batchId}`}>{item.numberOfOrders} orders created</Link>.
        </Typography>
    }
    else if (item.template === NotificationTemplates.DeliveredToCustomer || item.template === NotificationTemplates.DeliveredToPlanner) {
        return <Typography variant="body2" color="textPrimary" >
            PO# {formatOrderListLink(roleType, item, onClose)} is delivered.
        </Typography>
    }
    else if (item.template === NotificationTemplates.DeliveryDateOrTimeChangedToCustomer) {
        return <Typography variant="body2" color="textPrimary" >
            PO# {formatOrderListLink(roleType, item, onClose)} Delivery Date has been changed.
        </Typography>
    }
    else if (item.template === NotificationTemplates.DeliveryDateOrTimeChangedToCarrier) {
        return <Typography variant="body2" color="textPrimary" >
            Load ID# {item.loadid} of PO# {formatOrderListLink(roleType, item, onClose)} Delivery Date has been changed.
        </Typography>
    }
    else if (item.template === NotificationTemplates.TenderUpdateToCustomer) {
        return <Typography variant="body2" color="textPrimary" >
            PO# {formatOrderListLink(roleType, item, onClose)} has been updated.
        </Typography>
    }
    else if (item.template === NotificationTemplates.TenderUpdateToCarrier) {
        return <Typography variant="body2" color="textPrimary" >
            Load ID# {item.loadid} of PO# {formatOrderListLink(roleType, item, onClose)} has been updated.
        </Typography>
    }
    else if (item.template === NotificationTemplates.TMSTenderLoadCancel) {
        return <Typography variant="body2" color="textPrimary" >
            Load ID# {item.loadid} is cancelled for PO# {item.ponumber === "" ? item.orderid : truncateString(item.ponumber, 18)}.
        </Typography>
    }
    return null;
}

const handleNotificationDate = (date: any) => {
    const difference = dayjs(currentAustDate()).set('hour', 23).set('minute', 59).set('second', 59).diff(dayjs(getAustTimeZone(date)), 'day');
    if (difference < 1) {
        return `Today ${(dayjs(date).format('hh:mm a'))}`;
    }
    else if (difference === 1) {
        return `Yesterday ${(dayjs(date).format('hh:mm a'))}`;
    }
    else {
        return `${dayjs(date).format('MMM D hh:mm a')}`;
    }
}


const NotificationItemComponent = React.memo(({ item, handleClose }: { item: NotificationItem, handleClose: () => void }) => {
    
    const userDetails = useSelector(selectUserDetails);
    const message = handleMessage(item, handleClose, userDetails?.user?.roletype?.toLowerCase() ?? "");
    
    
    return message ? (
        <Box className="notification-item">
            {message}
            <Typography variant="caption" color="textSecondary">{handleNotificationDate(item.timestamp)}</Typography>
        </Box>
    ) : null;
}, (prev, next) => prev.item?.id === next.item.id);

function NotificationBell() {
    const [notificationitems, setNotificationitems] = useState< NotificationItem[]>([]);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [hideNotification, setHideNotification] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const [total, setTotal] = useState<number>(0);

    const [getNotificationByUserId, { loading }] = useLazyQuery<GetNotificationByUserIdResponse, GetNotificationByUserIdRequest>(GET_NOTIFICATION_BY_USERID, {
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            setNotificationitems((prev) => [ ...prev, ...(data?.getNotificationByPage.list ?? []) ]);
            if (data?.getNotificationByPage?.totalNumber) {
                setTotal(data?.getNotificationByPage?.totalNumber);
            }
        }
    });

    const handleNotificationOpen = (e: any) => {
        setAnchorEl(e.currentTarget);
        getNotificationByUserId({
            variables: {
                pageNo: page,
                pageSize: 10,
                properties: ["timestamp"],
                direction: "DESC"
            }
        });
    }

    const handleClose = () => {
        setAnchorEl(null);
        setPage(0);
        setTotal(0);
        setNotificationitems([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const loadMoreData = useCallback(debounce((pageNo: number) => {
        setPage(pageNo);
        getNotificationByUserId({
            variables: {
                pageNo: pageNo,
                pageSize: 10,
                properties: ["timestamp"],
                direction: "DESC"
            }
        });
    }, 300), []);


    return (
        <div>
            <Box pl={2} className="notification-bell" data-testid="notification-button">

                <IconButton className={`notification-bell-icon ${Boolean(anchorEl) ? 'opened' : ""}`} data-testid="notification-icon" color="secondary" disableRipple size='small' onClick={handleNotificationOpen} >
                    <i className="ri-notification-4-fill icon" />
                </IconButton>

                <Popper
                    id='notificaiton-list'
                    data-testid="notification-history"
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    modifiers={{
                        flip: {
                            enabled: false,
                        },
                        offset: {
                            enabled: true,
                            offset: '0, 8',
                        },
                    }}
                    className="notificaiton-container"
                    disablePortal
                    popperOptions={{
                        placement: 'bottom-end',
                    }}
                >
                    <Box
                        role="presentation"
                        className="notification-container-inner"
                        id="inner-notification-container"
                    >
                        <Box className='notification-header' data-testid="notification-title" px={2} display="flex" justifyContent='space-between' alignItems='center' height="64px">
                            <Typography variant="h3" component='span' className="notification-fs" color="textPrimary">Notifications</Typography>
                            <IconButton size="small" onClick={handleClose}>
                                <i className="ri-close-fill ri-lg"></i>
                            </IconButton>
                        </Box>
                        {hideNotification ? '' : (
                            <Box className="alert-box" >
                                <Alert className="alert-box-bg" icon={<i className="ri-information-fill" />} severity='info'>
                                    <Box height={50} display='flex' justifyContent='space-between' alignItems='center'>
                                        <Typography align='left' variant="body2" component="h2" color='textPrimary'>
                                            Everyone in your organization sees the same notifications.
                                        </Typography>
                                        <IconButton className="alert-icon-button" size="small" onClick={() => setHideNotification(true)} >
                                            <i className="ri-close-fill icon-close" />
                                        </IconButton>
                                    </Box>
                                </Alert>
                            </Box>
                        )}
                        
                        <Box className="notification-scrollbar" maxHeight={hideNotification ? 'calc(80vh - 144px)' : 'calc(80vh - 226px)'} id="infinite-scroll-list">
                            {total > 0 ? (<InfiniteScroll
                                dataLength={notificationitems.length} //This is important field to render the next data
                                next={() => loadMoreData(page + 1)}
                                hasMore={notificationitems.length < total && !loading}
                                loader={<Box display='flex' component='h4' height="40px" width="100%" justifyContent='center'><CircularProgress /></Box>}
                                scrollableTarget="infinite-scroll-list"
                                scrollThreshold='10px'
                            >
                                {notificationitems.map((item) => (
                                    <NotificationItemComponent key={item.id} item={item} handleClose={handleClose} />
                                ))}
                            </InfiniteScroll>) : (
                                <Box display="flex" justifyContent='center' alignItems='center' height={180}flexDirection='column' >
                                    { loading ? ( <CircularProgress /> ): (
                                        <>
                                            <Box  display="flex" justifyContent='center' pb={3} color='action.active'><i className="ri-notification-4-line ri-lg" /></Box>
                                            <Typography variant="body2"  >No new notifications.</Typography>
                                        </>
                                    )}
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Popper>
            </Box>

        </div>
    );
}

export default NotificationBell;

