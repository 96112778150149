import React from 'react';
import { useQuery } from '@apollo/client';
import { ActivityTile, ActivityTileProps } from '../common/ActivityTile';
import { omit } from 'lodash';
import overdueDeliveriesactiveFinal from '../../../assets/overdueDeliveriesactivefinal.png';
import { GetLoadListCountRequest, GetLoadListCountResponse } from '../../../models/api/get-load-list-count';
import { GET_LOAD_LIST_COUNT } from '../../../graphql/Queries/getLoadListCount';
import { LoadStatus } from '../../../models/load-status';

const OverdueLoadDeliveries = (props: Omit<ActivityTileProps, 'value' | 'url' | 'title' | 'image'>) => {
    const { data: LoadCount } = useQuery<GetLoadListCountResponse, GetLoadListCountRequest>(GET_LOAD_LIST_COUNT, {
        variables: {
            filterCommodity: [],
            filterOrigins: [],
            filterCustomers: [],
            loadCollectionStartDate: '',
            loadCollectionEndDate: '',
            loadDeliveryStartDate: '',
            filterDestinations: [],
            filterLoadStatus: [LoadStatus.INTRANSIT],
            isFavourite: false,
            loadDeliveryEndDate: '',
            isTenderActioned: false,
            searchKeyWord: '',
            isPodNotSubmitted: true,
            missingPodDocument: false,
            variationsInPallets: false,
            variationsInSpaces: false,
            collectionDateTimeNotConfirmed: false,
        },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-and-network'
    });

    return (
        <ActivityTile
            value={`${LoadCount?.getLoadListCount ?? ''}`}
            title="Overdue load deliveries"
            url={`/load/list?status=${LoadStatus.INTRANSIT}&exceptions=isPodNotSubmitted`}
            image={overdueDeliveriesactiveFinal}
            edit={props.edit as any}
            {...omit(props, 'edit')}
        />
    );
}
export default OverdueLoadDeliveries;