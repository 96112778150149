export const PageTitle = {
	DASHBOARD_PAGE: 'Dashboard',
    PREPLANNING: 'Preplanning',
    ERRORPAGE: 'Errorpage',
    MAINTENANCEPAGE: 'Maintenancepage',
    ERRORURL: 'ErrorUrl',
    UNAUTHORISED: 'Unauthorisedaccess',
    TERM_PAGE: 'Terms Page',
    FEEDBACK_PAGE: 'User Feedback',
    SELECT_ACCOUNT: "Select Account"
}