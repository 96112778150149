import { createAction } from "@reduxjs/toolkit";
import { GetUserDetails } from "../../models/user";
import { UserSettings } from "../../models/user-settings";
import { UserPrefFieldMap } from "../../models/api/get-field-by-screen-id";

export const enum UserActions {
    UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS',
    RESET_SESSION_TIMER = "RESET_SESSION_TIMER",
    UPDATE_USER_PREFERENCE = "UPDATE_USER_PREFERENCE",
    UPDATE_FIELD_MAPPING = "UPDATE_FIELD_MAPPING",
}

export const userUpdateDetails = createAction(UserActions.UPDATE_USER_SUCCESS, (payload: GetUserDetails) => ({payload}));

export const resetSessionTimer = createAction(UserActions.RESET_SESSION_TIMER, (payload: number) => ({payload}));

export const updateUserPreference = createAction(UserActions.UPDATE_USER_PREFERENCE, (payload: UserSettings[]) => ({payload}));

export const updateSeqFieldMapping = createAction(UserActions.UPDATE_FIELD_MAPPING, (payload: UserPrefFieldMap[]) => ({payload}));