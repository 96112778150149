import { gql } from '@apollo/client';

export const GET_FEEDBACK = gql`
query GET_FEEDBACK(
    $pageNo:Int!,
    $pageSize:Int!,
    $properties:[String],
    $direction: Direction ,
    ) {
    getReviewsByPage(
        pageNo:$pageNo,
        pageSize:$pageSize,
        properties:$properties,
        direction:$direction
        ) {
        list
        totalNumber
    }
  }
`;
