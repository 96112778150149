import './TermPage.scss';
import { Box, Grid, Typography } from '@material-ui/core';
import primaryConnectLogo from './../../assets/PrimaryConnect_Mini.png';
import { PageTitle } from '../../models/page-title';
import { GET_TERMS_DATA } from '../../graphql/Queries/getTermsDetails';
import { getTermsCondsContentResponse } from '../../models/api/getTermsData';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import DOMPurify from 'dompurify';

const TermPage = () => {
  document.title = PageTitle.TERM_PAGE;
  const [terms, setTerms] = useState<string>('');
  const { data: termsData } = useQuery<getTermsCondsContentResponse>(GET_TERMS_DATA);

  useEffect(() => {
    setTerms(DOMPurify.sanitize(termsData?.getTermsCondsContent ?? '', {
      FORCE_BODY: true,
    }))
  }, [termsData]);

  return (
    <Box className="TermPageContainer">
      <Grid>
        <Box display='flex' justifyContent='center' ><img className='primary-connect-logo' alt='Primary connect logo' src={primaryConnectLogo} /></Box>
        <Typography variant="h3" className="heading" data-testid="my-primary-connect"><b>MY PRIMARY CONNECT <br /> TERMS AND CONDITIONS</b></Typography><br/>
        <div dangerouslySetInnerHTML={{ __html: terms }} />
      </Grid>
    </Box>

  );
}
export default TermPage;