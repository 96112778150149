import { gql } from '@apollo/client';

export const FAVOURITE_FLAG_DETAILS = gql`
    mutation flagorUnFlagTransportOrder(
        $isFavourite: Boolean!,
        $transportOrderIds: [String]
        ) {
        flagorUnFlagTransportOrder(
            isFavourite: $isFavourite,
            transportOrderIds: $transportOrderIds
        )
    }
`;