import {
    Box,
    Typography,
    IconButton,
    withStyles,
    Tooltip,
} from '@material-ui/core';
import './PrintConnote.scss';
import { useQuery } from '@apollo/client'
import Modal from '@material-ui/core/Modal';
import Connote from '../../components/Connote/Connote';
import ReactToPrint from 'react-to-print';
import { GET_ORDER_DETAILS } from '../../graphql/Queries/orderDetails';
import { downloadPdf } from '../utils/pdfActions';


interface PrintConnoteProps {
    orderId: number | string | undefined,
    handleClose?: () => void,
}

const DarkTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.secondary.main,
        boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(0, 0, 0, 0.08)'
    },
}))(Tooltip);

const PrintConnote = ({ orderId, handleClose }: PrintConnoteProps) => {
    let zoom = 1;

    const { data: orderDetails } = useQuery(GET_ORDER_DETAILS, {
        variables: {
            transportOrderId: orderId
        }
    });

    const zoomIn = () => {
        if (zoom < 1.5)
            zoom += 0.25;
        var connote = document.getElementById('connote-element');
        if (connote) {
            const width = connote.getBoundingClientRect().width;
            connote.style.transform = "scale(" + zoom + ")";
            if (width < 600) {

                connote.style.transformOrigin = "top left";
            }
            else {
                connote.style.transformOrigin = "top";
            }
        }
    }
    const zoomOut = () => {
        if (zoom > 0.5)
            zoom -= 0.25;
        var connote = document.getElementById('connote-element');

        if (connote) {
            const width = connote.getBoundingClientRect().width;
            connote.style.transform = "scale(" + zoom + ")";
            if (width < 600) {

                connote.style.transformOrigin = "top left";
            }
            else {
                connote.style.transformOrigin = "top";
            }

        }

    }

    const body = (
        <Box className="popUpBlock">
            <Box display='flex' component="span" justifyContent="space-between">
                <Box display='flex' component="span" justifyContent="space-between" className="header-left-icons">
                    <Box className="svg-connect-logo" ><i className="ri-qr-code-line ri-xl"></i> </Box>
                    <Typography variant="h3" component="h3"> TB# {orderId}</Typography>
                </Box>
                <Box display='flex' component="span" justifyContent="space-between" className="header-right-icons">
                    <DarkTooltip
                        placement='bottom'
                        data-testid='download-icon'
                        title={<Typography variant='caption'>Download</Typography>} >
                        <IconButton disableRipple className="order-icon" onClick={() => downloadPdf('connote-element', 'TB' + orderId)}>
                            <i className="ri-download-cloud-2-line" />
                        </IconButton>
                    </DarkTooltip>
                    <ReactToPrint
                        data-testid="react-to-print"
                        onBeforeGetContent={() => {
                            var connote = document.getElementById('connote-element');
                            if (connote) {
                                connote.style.transform = "scale(1.33,1.28)";
                            }
                        }}
                        onBeforePrint={() => {
                            var connote = document.getElementById('connote-element');
                            if (connote) {
                                connote.style.transform = "scale(1)";
                            }
                        }}
                        trigger={() => <IconButton data-testid="print-button" className="order-icon" ><i className="ri-printer-line" /></IconButton>}
                        content={() => document.getElementById('connote-element')}
                    />
                    <IconButton onClick={handleClose} data-testid="on-close" className="order-icon"><i className="ri-close-fill"></i></IconButton>
                </Box>
            </Box>
            <Box display='flex' >
                <Connote id='connote-element' transportOrder={orderDetails?.getTransportOrderById} />
            </Box>
            <Box className="zoom-tools">
                <Box className="icon"><i onClick={() => zoomIn()} className="ri-add-line ri-xl" data-testid="zoom-in-icon" /></Box>
                <Box className="icon"><i className="ri-zoom-in-line ri-xl" /></Box>
                <Box className="icon"><i onClick={() => zoomOut()} className="ri-subtract-line ri-xl" data-testid="zoom-out-icon" /></Box>
            </Box>
        </Box>

    );

    return (
        <Box>
            <Modal open={true} className="modal-popup" data-testid="modal-popup">
                {body}
            </Modal>
        </Box>
    );
};

export default PrintConnote;
