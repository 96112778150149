export enum DocumentFileTypes {
    LOAD_TENDER = "LOADTENDERDOC",
    PALLET_TRANSFER= 'PLTTRANSFER',
    POD = 'POD',
    POP = 'POP',
    OTHER = 'OTHERS'
}

export const DocumentTypeDescription :{ [key: string]: string } = {
    'LOADTENDERDOC': 'Load Tender',
    'PLTTRANSFER': 'Pallet Transfer',
    'POD':'POD',
    'POP' : 'Proof of collection',
    'OTHERS': 'Other'
};