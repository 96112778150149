import { gql } from '@apollo/client';

export const GET_TRANSPORT_ORDER = gql`
query(
  $sortBy: String
  $sortOrder: String
  $pageSize: Int
  $currentPageNo: Int
  $keywords: String
  $filterOrderStatus: [String]
  $filterOrderToLocation: [String]
  $filterOrderCustomer: [String]
  $startDate: String
  $endDate: String
  $pickupStartDate: String
  $pickupEndDate: String
  $isDeliveryDateSelected: Boolean
  $isPalletsSelected: Boolean
  $isSpacesSelected: Boolean
  $isDeclineSelected: Boolean
  $isMissedPickupSelected: Boolean
  $isFavourite: Boolean
  $searchOnField: String
  ) {
  getOrderList(
    sortBy: $sortBy
    sortOrder: $sortOrder
    pageSize: $pageSize
    currentPageNo: $currentPageNo
    keywords: $keywords
    filterOrderStatus: $filterOrderStatus
    filterOrderToLocation: $filterOrderToLocation
    filterOrderCustomer: $filterOrderCustomer
    startDate: $startDate
    endDate: $endDate
    pickupStartDate: $pickupStartDate
    pickupEndDate: $pickupEndDate
    isDeliveryDateSelected: $isDeliveryDateSelected
    isPalletsSelected: $isPalletsSelected
    isSpacesSelected: $isSpacesSelected
    isDeclineSelected: $isDeclineSelected
    isMissedPickupSelected: $isMissedPickupSelected
    isFavourite: $isFavourite
    searchOnField: $searchOnField
  ) {
    totalordersno
    orderlist {
      poNumber
      orderStatus
      transportOrderId
      imagePath {
        docType
        parentFilePath
        source
      }
      grnFlag
      splitted
      splitParentTransportOrderId
      deliveryDetails {
        destination {
          deliveryDate,
          deliveryTime,
          deliveryDateTimeUTC
        }
      }
      transportOrderLegs {
        transportOrderLoadId
        proofOfDelivery{
          actualPallets
          actualSpaces
          actualDeliveryDate
          actualDeliveryTime
          actualDeliveryDateTime
          actualDeliveryTimezone
          podImagePath {
            docType
          }
          damageDetails {
            damageImagePathsList {
              docType
            }
          }
        }
        firstLeg
        lastLeg
      }
    }
  }
}
`;