import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import './Tooltip.scss';
import { Variant } from '@material-ui/core/styles/createTypography';


interface TooltipProps {
  content: string,
  children: React.ReactNode,
  dataTestId: string,
  theme: string,
  position: PositionType,
  textVariant: Variant | 'inherit';
}

interface PositionType {
  top?: number,
  bottom?: number,
  left?: number,
  right?: number,
  display?: 'block' | 'none'
}

const Tooltip = ({ content, children, dataTestId, theme, position, textVariant }: TooltipProps) => {
  const [active, setActive] = useState(false);

  const showTip = () => {
    setActive(true);
  };

  const hideTip = () => {
    setActive(false);
  };

  return (
    <div className='tooltip-wrapper' onMouseEnter={showTip} onMouseLeave={hideTip} >
      {children}
      {active && (
        <div style={{...position}} data-testid={dataTestId} onMouseEnter={hideTip} className={`tooltip-tip ${theme}`}>
          <Typography variant={textVariant}>{content}</Typography>
        </div>
      )}
    </div>
  );
};

export default Tooltip;
