import React, { useEffect } from 'react'; 
import { MicroFrontend } from './Interface/MicroFrontend';
import { useHistory } from 'react-router-dom';

interface CreateOrderAppProps {
    client: any
}
const name = 'orderapp';

const { REACT_APP_ORDERAPP_HOST: orderAppHost } = process.env;
export const FetchOrderApp = ({client}: CreateOrderAppProps) => {
  const history = useHistory();

  useEffect(() => {
    return () => {
      (window as { [key: string]: any })[`unmount${name}`] && (window as { [key: string]: any })[`unmount${name}`](`${name}-container`);
    }
  }, []);

  return (
  <MicroFrontend
    history={history}
    host={orderAppHost}
    name={name}
    client={client}
    parentDivId="micro-apps"
  />
  )
};