import { Box,  Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Typography, Button, Chip, TextField, TableFooter, TableRow, TablePagination, Link as Links } from '@material-ui/core';
import { Link } from "react-router-dom";
import { Autocomplete } from '@material-ui/lab';
import Alert from '@mui/material/Alert';
import { take, trim, uniq } from 'lodash';
import dayjs from 'dayjs';
import '../Dashboard.scss';
import { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GetOrderListRequest, GetOrderListResponse } from "../../../models/api/get-orderlist";
import { GET_TRANSPORT_ORDER } from "../../../graphql/Queries/getTransportOrder";
import { TransportOrder } from '../../../models/transport-order';
import { createAsyncBatchJobRequest, createAsyncBatchJobResponse } from '../../../models/api/create-async-batch-job';
import { CREATE_ASYNC_BATCH_JOB } from '../../../graphql/Queries/createBatchJob';
import { AsyncJobTypes } from '../../../models/async-job-types';
import { AlertContext } from '../../../contexts/alert-context';
import { eventsToDispatch } from '../../../events/events';
import { dispatchEvent } from '../../../events/dispatchEvent';
import { DocumentFileTypes } from '../../../models/documentType';
import { chipTruncateString } from '../../utils/helper';
import { useSelector } from 'react-redux';
import { selectUserDetails } from '../../../store/user/user.selectors';
import { RoleType } from '../../../models/user-roles';
import { downloadCSV } from "../../utils/download";

interface PodTileModalProps {
    onClose: () => void,
}

interface NoPodsAttachedPoTable {
    poNumber?: string,
    transportOrderId: string | null,
    podCount: number, 
    validPO: boolean
}

const checkPodExists  = (order: TransportOrder, userRole?: string) => {
    const exists = order?.transportOrderLegs
        ?.filter(leg => userRole?.toLowerCase() !== RoleType.CUSTOMER.toLowerCase() || leg.lastLeg)
        ?.find(item => {
            return item?.proofOfDelivery?.podImagePath && item?.proofOfDelivery?.podImagePath?.length > 0
    });
    if (!exists) {
      if (order.grnFlag) {
        if (order.splitted) {
          if (order.transportOrderId === order.splitParentTransportOrderId) {
            return order;
          }
        } else {
          return order;
        }
      }
      return (!!order?.imagePath?.find(item => item.docType === DocumentFileTypes.POD));
    }
    return true;
}

export const PodModal = ({ onClose }: PodTileModalProps) => {
    const { alert } = useContext(AlertContext);
    const [keyword, setKeyword] = useState<string>('');
    const [list, setList] = useState<string[]>([]);
    const [validPOCount, setValidPOCount] = useState<number>(0);
    const [showAlertInfo, setShowAlertInfo] = useState<boolean>(false);
    const ref= useRef<any | null>(null);
    const maxCount = 30;
    const maxReached = !!(list.length >= maxCount);
    const searchPlaceholderText = 'Separate multiple POs with commas';
    const [orderWithPods, setOrderWithPods] = useState<TransportOrder[]>([]);
    const userRoleType = useSelector(selectUserDetails)?.user?.roletype?.toLowerCase() ?? '';
    const [initialPodModalState, setInitialPodModalState] = useState(true);
    const [noPodsAttachedPoTableData, setNoPodsAttachedPoTableData] = useState<NoPodsAttachedPoTable[]>([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage] = useState(15);
    
    const handleChangePage = (event:any, newPage: number) => {
        setPage(newPage);
    };

    const [getAllPos, { data: orderData }] = useLazyQuery<GetOrderListResponse, GetOrderListRequest>(GET_TRANSPORT_ORDER, {
        variables: {
               sortBy: 'OID',
               sortOrder: 'DSC',
               pageSize: 30,
               currentPageNo: 0,
               keywords: list.join(','),
               filterOrderStatus: [],
               filterOrderToLocation: [],
               filterOrderCustomer: [],
               startDate: null,
               endDate: null,
               pickupStartDate: null,
               pickupEndDate: null,
               isDeliveryDateSelected: false,
               isPalletsSelected: false,
               isSpacesSelected: false,
               isDeclineSelected: false,
               isMissedPickupSelected: false,
               isFavourite: false,
               searchOnField: "po"
           },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
    });
    
    const [createAsyncJob] = useMutation<createAsyncBatchJobResponse, createAsyncBatchJobRequest>(CREATE_ASYNC_BATCH_JOB);

    const searchPOs = () => {
        if (list.length > 0) {
            getAllPos().then(() => {
                setInitialPodModalState(false);
            });
        }
    }

    const startAsyncJobRequest = useCallback((printableOrders: TransportOrder[], docTypes: string[], name: string) => {
        createAsyncJob({
          variables: {
            docTypes: docTypes,
            name: name,
            transportOrderIds: printableOrders.map(item => item.transportOrderId),
            type: AsyncJobTypes.DOWNLOAD_POD_ZIP
          }
        }).then((response) => {
          dispatchEvent(eventsToDispatch.CREATED_ASYNC_JOB, { jobId: response.data?.createAsyncJob?.jobId });
        }).catch(() => {
          alert({
            message: "Something went wrong. Please try again",
            severity: 'error'
          });
        });
    }, [alert, createAsyncJob]);

    useEffect(() => {
        if (orderData) {
            let foundArr: TransportOrder[] = [];
            let notFoundArr: NoPodsAttachedPoTable[] = [];
            list.map(poNumber => {
                const data = orderData.getOrderList.orderlist.find(x => x.poNumber === poNumber);
                if (data && checkPodExists(data, userRoleType)) {
                    return foundArr.push(data);
                } else if (data) {
                    return notFoundArr.push({'poNumber': data.poNumber, 'transportOrderId': data.transportOrderId, 'podCount': 0, 'validPO': true})
                } else {
                    return notFoundArr.push({'poNumber': poNumber, 'transportOrderId': null, 'podCount': 0, 'validPO': false})
                }
            })
            setOrderWithPods(foundArr);
            setNoPodsAttachedPoTableData(notFoundArr);
        }
    }, [orderData, userRoleType, list])

    useEffect(() => {
        if (orderWithPods.length > 0) {
            // Download POD Documents
            startAsyncJobRequest(orderWithPods, ["Physical", "Digital"], "POD-Documents.zip");
        }
    }, [orderWithPods, startAsyncJobRequest])

    const setupList = (list: string[], maxCount?: number): string[] => {
        if (maxCount && maxCount > 0) {
            return take(uniq(list).map(item => trim(item)).filter(item => !!item), maxCount);
        }
        return uniq(list.map(item => trim(item))).filter(item => !!item);
    }

    const onChangeSearchList = (inputs: string[], _e?: any, _reason?: string ) => {
        setList(inputs);
        setShowAlertInfo(false);
        if(inputs.length < 1) {
            setValidPOCount(0);
        }
    }

    const parseOrderForCSVDownload = (noPodsAttachedPoTableData: NoPodsAttachedPoTable[]) => {
        return noPodsAttachedPoTableData.map((po) => ({
            "PO#": po.poNumber,
            "Error type": po.validPO ? po.podCount + ' POD' : 'PO not found'
        }));
    }

    const handleDownload = () => {
        // Download Missing PODs
        downloadCSV(parseOrderForCSVDownload(noPodsAttachedPoTableData), `MissingPODs_${dayjs().format("DD-MM-YYYY")}.csv`);
    }

    const handleGoBack = () => {
        setInitialPodModalState(true);
        if (noPodsAttachedPoTableData.length < 1) {
            setList([]);
        }
    }

    return (
        <>
            {initialPodModalState ?
                <Dialog open={true} data-testid="dialog-modal" className='add-dialog-box' onClose={onClose} fullWidth>
                    <DialogTitle className="pod-modal-title">
                        <Box className='dialog-title'>
                            <Typography variant='h3'>POD</Typography>
                            <IconButton disableRipple onClick={onClose}>
                                <i className="ri-close-fill" ></i>
                            </IconButton>
                        </Box>
                    </DialogTitle>
                    <DialogContent className="pod-modal-content">
                        <Autocomplete
                            multiple
                            id='tags-filled'
                            size="small"
                            filterSelectedOptions={true}
                            options={[]}
                            value={list}
                            className={'autocomplete-chip'}
                            freeSolo
                            ListboxProps={{ style: { maxHeight: 150 } }}
                            classes={{
                                endAdornment: "end-adornment-contr",
                                inputRoot: "search-autocomplete-input-root",
                                input: `search-autocomplete-input ${maxReached ? 'hide-input' : ''}`
                            }}
                            renderTags={ ( listIds, getTagProps ) => {
                                return listIds.map( ( id: any, index ) => {
                                    return <Chip
                                        data-testid='multi-search-chip'
                                        key={ index }
                                        label={chipTruncateString(id, 27, 23) }
                                        { ...getTagProps( { index } ) }
                                        className={`auto-complete-chip`}
                                        
                                    />
                                })
                            }}
                            renderInput={(params: any) => <TextField
                                {...params}
                                value={keyword}
                                variant='outlined'
                                autoFocus={true}
                                placeholder={searchPlaceholderText}
                                inputRef={ref}
                                onChange={e => {
                                    if (maxReached) {
                                        e.preventDefault();
                                    }
                                    else if (e.target.value?.indexOf(',') >= 0) {
                                        setList(prev => setupList([...prev, ...(e.target.value.split(','))], maxCount));
                                        setKeyword('');
                                    } else {
                                        setKeyword(e.target.value)
                                    }
                                }}
                                onKeyDown={(e) => {
                                    if (maxReached) {
                                        e.preventDefault();
                                    }
                                    else if (e.key === ',') {
                                        setList(prev => setupList([...prev, keyword], maxCount));
                                        setKeyword('');
                                        e.preventDefault();
                                    }
                                }}
                                inputProps={{
                                    ...params.inputProps,
                                    style: { minWidth: (searchPlaceholderText.length ?? 15) * 11.2},
                                }}
                                InputProps={{
                                    ...params.InputProps,
                                    'data-testid': 'search-text-box',
                                    startAdornment: (  
                                    <Box display='flex' justifyContent="space-between">
                                        <Box pt={1} pr={1}>
                                            <i className='ri-search-line ri-lg' style={{ fontSize: 24 }} aria-hidden="true" />
                                        </Box>
                                        <Box flexGrow={1} className="auto-complete-tag-list">{params.InputProps.startAdornment}</Box>
                                    </Box>
                                    )
                                }}
                            />}
                            onChange={(e, input, reason) => onChangeSearchList(input as any, e, reason)}
                        />
                        {maxReached ? 
                            <Box color="warning.textDark" display="flex" alignItems="center" justifyContent="flex-start">
                                <Box display="flex" color="warning.main" p="4px"><i className="ri-alert-fill" /></Box>
                                <Typography variant="caption">Max limit of {maxCount} POs reached.</Typography>
                            </Box> 
                            : ''
                        }                        
                        <Box className="info-alert-box" mt={3}>
                            <Alert variant="outlined" severity="info"
                                icon={<i className="ri-information-fill information-icon" />}
                                className="alert"
                            >
                                {showAlertInfo &&
                                    <Typography className="alert-message" variant="subtitle2" color="textPrimary">
                                        We found {validPOCount} out of {list.length} POs. {validPOCount > 0 ? `Click on 'Download PODs' to download the documents.` : ''}
                                    </Typography>
                                }
                                <Typography className="archived-pod-message" variant="body2" color="textPrimary">
                                    If you're looking for PODs prior to October 2023, search in <Link to={'/epod/status'} className="archived-pod-link">Archived PODs.</Link>
                                </Typography>
                            </Alert>
                        </Box>
                    </DialogContent>
                    <DialogActions className='pod-modal-actions'>
                        <Box>
                            <Typography variant='body2' color='textPrimary'>Don't have a PO number?</Typography>
                            <Link to={'/booking/list'} className="pod-modal-actions-link">
                                <Typography variant='body2'>View PODs on all bookings page</Typography>
                            </Link>
                        </Box>
                        <Box>
                            <Button
                                onClick={onClose}
                                color="secondary"
                                variant='outlined'
                                data-testid={'cancel-button'}
                                size="large"
                                style={{ margin: "0 16px" }}
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={() => { searchPOs(); }}
                                color="primary"
                                variant='contained'
                                data-testid={'download-pod-button'}
                                size="large"
                                disabled={list.length < 1}
                            >
                                Download PODs
                            </Button>
                        </Box>
                    </DialogActions>
                </Dialog>
            :
                <Dialog open={true} data-testid="dialog-modal" className='add-dialog-box' onClose={onClose} fullWidth>
                    <DialogTitle className="pod-modal-title">
                        <Box className='dialog-title'>
                            <Typography variant='h3'>{orderWithPods.length > 0 ? 'Downloading PODs...' : 'No PODs found'}</Typography>
                            <IconButton disableRipple onClick={onClose}>
                                <i className="ri-close-fill" ></i>
                            </IconButton>
                        </Box>
                    </DialogTitle>
                    <DialogContent className="pod-modal-content">
                        {orderWithPods.length > 0 &&
                            <Box className="success-alert-box">
                                <Alert variant="outlined" severity="info"
                                    icon={<i className="ri-checkbox-circle-fill success-icon" />}
                                    className="alert"
                                >
                                    <Typography variant="subtitle2" color="textPrimary" className="success-message">
                                        We found {orderWithPods.length} {orderWithPods.length > 1 ? 'POs' : 'PO'} with PODs
                                    </Typography>
                                    <Typography variant="body2" color="textPrimary" className="success-message">
                                        They will be downloaded automatically. It may take a few minutes, so feel free to navigate away.
                                    </Typography>
                                </Alert>
                            </Box>
                        }
                        {noPodsAttachedPoTableData.length > 0 &&
                            <>
                                <Typography variant="body2" className={orderWithPods.length > 0 ? 'no-pod-message' : ''}>
                                    The POs below do not have a POD available in MyPrimaryConnect. Please download this list and send to <Links href="mailto:customerservice@primaryconnect.com.au" target="_blank" rel="noopener noreferrer" color="inherit" underline="always">customerservice@primaryconnect.com.au</Links> for assistance.
                                </Typography>
                                <Box className='po-box' mt={2} border="solid 0.5px #DAE1E4">
                                    {(rowsPerPage > 0
                                        ? noPodsAttachedPoTableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : noPodsAttachedPoTableData
                                    ).map((po, index) => {
                                        return (
                                            <Box key={index} className={po.validPO ? 'po-table' : 'po-table po-not-found'}>
                                                <Typography variant="body2">PO {po.validPO ? <Link to={`/booking/detail/${po.transportOrderId}`} className="po-details-link" target="_blank" rel="noopener noreferrer">{po.poNumber}</Link> : po.poNumber}</Typography>
                                                <Typography variant="body2">{po.validPO ? po.podCount + ' POD' : <><i className="ri-error-warning-fill" style={{verticalAlign: "middle"}}/> PO not found</>}</Typography>
                                            </Box>
                                        )
                                    })}
                                    <TableFooter className='po-table-footer'>
                                        <TableRow className='po-table-footer-row'>
                                            <Button className='download-po-btn' onClick={handleDownload}><i className="ri-download-line"></i> Download all POs without a POD (CSV)</Button>
                                            <TablePagination
                                                className='po-table-pagination'
                                                count={noPodsAttachedPoTableData.length}
                                                rowsPerPage={rowsPerPage}
                                                rowsPerPageOptions={[]}
                                                page={page}
                                                onPageChange={handleChangePage}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Box>
                            </>
                        }
                    </DialogContent>
                    <DialogActions className='pod-modal-actions'>
                        <Box>
                            <Typography variant='body2' color='textPrimary'>Looking for PODs prior to October 2023?</Typography>
                            <Link to={'/epod/status'} className="pod-modal-actions-link">
                                <Typography variant='body2'>Search in Archived PODs</Typography>
                            </Link>
                        </Box>
                        <Box>
                            <Button
                                onClick={handleGoBack}
                                color="secondary"
                                variant='outlined'
                                data-testid={'cancel-button'}
                                size="large"
                                style={{ margin: "0 16px" }}
                            >
                                {noPodsAttachedPoTableData.length > 0 ? 'Go back' : 'New search'}
                            </Button>
                            <Button
                                onClick={onClose}
                                color="primary"
                                variant='contained'
                                data-testid={'download-pod-button'}
                                size="large"
                                disabled={list.length < 1}
                            >
                                Close
                            </Button>
                        </Box>
                    </DialogActions>
                </Dialog>
            }
        </>
    );
}