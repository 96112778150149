import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import arrow from '../../../assets/Vector.png';
import dragIcon from '../../../assets/drag-icon.png';
import { Box } from "@material-ui/core";

interface QuickActivityTileDefaultProps {
    url: string,
    image: any,
    title: string,
    active: boolean,
    display: boolean,
    mapping: string,
    value?: string,
    openInNewTab: boolean,
    halfSize?: boolean,
    onClick?: () => void,
}

interface EditProps extends QuickActivityTileDefaultProps {
    edit: true,
    position: number,
    onHide: (e: string) => void,
    onStart: (e: any) => void,
    onDragOver: (e: any) => void
}


interface NonEditProps extends QuickActivityTileDefaultProps {
    edit: false,
}

export type QuickActivityTileProps = EditProps | NonEditProps;

export const QuickActionTile = (props: QuickActivityTileProps) => {

    return (
        props.display ? (
            <Grid item xs={12} sm={6} md={props?.halfSize ? 6: 4} lg={props?.halfSize ? 6 : 4} xl={props?.halfSize ?  6: 3} className="grid-item">
                {props.edit ? (
                    <div draggable onDragEnterCapture={(e: any) => props?.onDragOver(e)} onDragStart={(e: any) => props?.onStart(e)} id={props.mapping} className="dashboard-button dashboard-quick-action-buttons" data-testid='draggable'>
                        <img src={dragIcon} draggable={false} style={{ marginLeft: 24 }} alt={props.mapping} />
                        <img draggable={false} style={{ marginLeft: 16 }} src={props.image} alt={props.mapping} />
                        <Box px={2} mr="auto"><span className="dashboard-card-desc" data-testid='title-tile'> {props.title}</span></Box>
                        <i onClick={() => props?.onHide(props.mapping)} className="ri-eye-off-line ri-lg" style={{ color: '#263238', opacity: '54%', float: 'right', paddingRight: 16 }} data-testid='hide-button'></i>
                    </div>
                ) : (
                    <div className="dashboard-button dashboard-quick-action-buttons">
                        {props.openInNewTab ? (
                            <a target="_blank" href={props.url} className="dashboard-card-button" rel="noreferrer">
                                <img className="dashboard-card-left-icon" src={props.image} alt={props.mapping}/>
                                <Box px={2}><span className="dashboard-card-desc" data-testid='title-tile'>
                                    {props.title}
                                    {props.value !== undefined
                                        ? <p className="dashboard-card-number" data-testid="test-tile-value">
                                            {props.value}</p>
                                        : ''
                                    }
                                </span></Box>
                                <img className="dashboard-arrow-image" src={arrow} alt={props.mapping}/>
                            </a>
                        ) : (
                            <Link to={props.url} onClick={props.onClick} className="dashboard-card-button">
                                <img className="dashboard-card-left-icon" src={props.image} alt={props.mapping} />
                                <Box px={2}><span className="dashboard-card-desc" data-testid='title-tile'>
                                    {props.title}
                                    {props.value !== undefined
                                        ? <p className="dashboard-card-number" data-testid="test-tile-value">
                                            {props.value}</p>
                                        : ''
                                    }
                                </span></Box>
                                <img className="dashboard-arrow-image" src={arrow} alt={props.mapping} />
                            </Link>
                        )}
                    </div>
                )}
            </Grid>
        ) : <></>
    );
}