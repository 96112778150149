import { useLazyQuery } from "@apollo/client";
import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { CLEAR_USER_SESSION, GET_SSO_USERDETAILS } from "../../graphql/Queries/UserDetailsLogin";
import { GetUserDetailsResponse } from "../../models/api/get-sso-userdetails";
import { resetSessionTimer } from "../../store/user/user.actions";
import { getIsImpersonated, getUserLastActiveTIme, selectUserDetails } from "../../store/user/user.selectors";


export default function SessionAlert() {
    const dispatch = useDispatch();
    const lastActivity = useSelector(getUserLastActiveTIme);
    const isImpersonated = useSelector(getIsImpersonated);
    const [open, setOpen] = useState<boolean>(false);
    const currentUserDetails = useSelector(selectUserDetails);
    const [getSsoLoginDetails, { data: userDetails }] = useLazyQuery<GetUserDetailsResponse>(GET_SSO_USERDETAILS, {
        fetchPolicy: "network-only",
        nextFetchPolicy: "network-only"
    });
    const history = useHistory();

    const handleEvent = useCallback(() => {
        dispatch(resetSessionTimer(new Date().getTime()))
    }, [dispatch]);

    useEffect(() => {
        const timer = setInterval(() => {
            if (lastActivity && new Date().getTime() - lastActivity >=  55 * 60 *1000) {
                // Open dialog at 55 minutes of inactivity
                setOpen(true);
            }
            if (lastActivity && new Date().getTime() - lastActivity >=  60 * 60 *1000) {
                // go to login page after 1 hr of inactivity
                history.push('/login');
            }
        }, 60000);
        return () => { clearInterval(timer); };
    }, [history, lastActivity]);

    useEffect(() => {
        document.removeEventListener("UpdateSessionTimer", handleEvent);
        document.addEventListener("UpdateSessionTimer", handleEvent);
        return () => {
            document.removeEventListener("UpdateSessionTimer", handleEvent);
        };
    }, [handleEvent]);

    useEffect(() => {
        if (userDetails) {
            if (userDetails?.getUserdetails === null) {
                if (window.location.pathname !== '/') {
                    localStorage.setItem("redirect-path", window.location.pathname + window.location.search);
                }
                history.push("/login");
            }
            else {
                setOpen(false);
            }
        }
    }, [history, userDetails]);

    const extendSession = () => {
        getSsoLoginDetails();
    }

    const [clearUserSession, { data: logoutSuccess }] = useLazyQuery(CLEAR_USER_SESSION, {
        fetchPolicy: "network-only",
        nextFetchPolicy: "network-only"
    });

    const logoutAction = () => {
        clearUserSession();
    }

    useEffect(() => {
        if (logoutSuccess) {
            if (currentUserDetails?.user?.roletype === 'Internal') {
                window.location.href = process.env.REACT_APP_LOGOUT_SSO_URL ?? '';
            }
            else {
                window.location.href = process.env.REACT_APP_EXTERNAL_LOGOUT_SSO_URL ?? '';
            }
        }
    }, [logoutSuccess, currentUserDetails?.user?.roletype])

    return <>
        <Dialog
            maxWidth='sm'
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            data-testid='confirmation-dialog'
            disableEscapeKeyDown
            disableBackdropClick
        >
            { isImpersonated ? (
                <>
                    <DialogContent>
                        <Box display='flex' >
                            <Box color='warning.main' px={1} pt={1}><i className='ri-alert-fill ri-lg' /></Box>
                            <Typography variant="body1" color="textPrimary">The impersonation session will be expire in the next 5 minutes, no user activity been recorded for the last 55 minutes.</Typography>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Box pt={0} pb={2} px={2} display='flex'>
                            <Button onClick={extendSession} color="primary" variant='contained' className="text-transform-none warning-button" data-testid={'success-button'} >
                                Continue
                            </Button>
                        </Box>
                    </DialogActions>
                </>
            ) : (
<>
                    <DialogContent>
                        <Box><Typography variant="body1" color="textPrimary">Your session will expire in 5 minutes. Extend the session to continue working.</Typography></Box>
                    </DialogContent>
                    <DialogActions>
                        <Box pt={0} pb={2} px={2} display='flex'>
                            <Box pr={2}>
                                <Button onClick={logoutAction} color="secondary" className="text-transform-none"  variant='outlined' data-testid={'cancel-button'}>
                                    Logout now
                                </Button>
                            </Box>
                            <Box pr={2}>
                                <Button onClick={extendSession} color="primary" variant='contained' className="text-transform-none" data-testid={'success-button'} >
                                    Extend
                                </Button>
                            </Box>
                        </Box>
                    </DialogActions>
                </>
            )}
        </Dialog>
    </>;
}