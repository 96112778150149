
import { Avatar, Box, Drawer, IconButton, Menu, Tooltip, Typography, MenuItem } from '@material-ui/core';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import logo from '../../assets/Primary_Connect_Logo.png';
import './limited-header.scss';
import { selectUserDetails } from '../../store/user/user.selectors';
import HelpDrawer from '../Header/helpDrawer';
import { CLEAR_USER_SESSION } from '../../graphql/Queries/UserDetailsLogin';
import { useLazyQuery } from '@apollo/client';

const LimitedHeader = () => {
    const [open, setOpen] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const userDetails = useSelector(selectUserDetails);
    const userFirstLetter = (userDetails?.user?.firstname ?? '').charAt(0).toUpperCase();
    const userLastLetter = (userDetails?.user?.lastname ?? '').charAt(0).toUpperCase();
    const [clearUserSession] = useLazyQuery(CLEAR_USER_SESSION, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only'
    });

    const toggleDrawer = () => {
        setOpen(!open)
    }

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    const logoutAction = () => {
        clearUserSession().then(() => {
            if (userDetails?.user?.roletype === 'Internal') {
                window.location.href = process.env.REACT_APP_LOGOUT_SSO_URL ?? '';
            }
            else {
                console.log('logut ext', userDetails?.user?.roletype);
                window.location.href = process.env.REACT_APP_EXTERNAL_LOGOUT_SSO_URL ?? '';
            }
        });
    }

    // useEffect(() => {
    //     console.log('logut', userDetails?.user?.roletype, logoutSuccess);
    //     if (logoutSuccess) {
    //         if (userDetails?.user?.roletype === 'Internal') {
    //             window.location.href = process.env.REACT_APP_LOGOUT_SSO_URL ?? '';
    //         }
    //         else {
    //             console.log('logut ext', userDetails?.user?.roletype);
    //             window.location.href = process.env.REACT_APP_EXTERNAL_LOGOUT_SSO_URL ?? '';
    //         }
    //     }
    // }, [logoutSuccess, userDetails?.user?.roletype]);

    return (
        <Box className="limited-header">
            <Box> <img className='logo' src={logo} alt='logo' /></Box>
            <Box>
                <Box className="header-right">

                    <span className="profile-name">
                        <Typography variant="body1">{userDetails?.user?.firstname} {userDetails?.user?.lastname}</Typography>
                    </span>
                    <IconButton
                        className="avatar-button user-profile"
                        disableRipple
                        onClick={handleClick}
                        data-testid='profile-button-desktop'
                    >
                        <Avatar className="avatar-icon">{userFirstLetter}{userLastLetter}</Avatar>
                    </IconButton>

                    <Tooltip title={<Typography variant='caption'>Help</Typography>}>
                        <IconButton onClick={toggleDrawer} id="help" className='help-button' color="secondary" size='small'>
                            <i className="ri-question-fill ri-lg" />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
            <Drawer anchor='right' open={open} onClose={toggleDrawer} className="help-drawer-popup">
                <HelpDrawer onClose={toggleDrawer} />
            </Drawer>
            <Menu
                classes={{ paper: 'profile-menu' }}
                data-testid="menus"
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        marginLeft: "50px",
                        marginTop: "5px"
                    }
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                getContentAnchorEl={
                    null
                }
            >
                <MenuItem
                    onClick={logoutAction}
                    data-testid="logout-button-desktop">
                    <Box pt={1} pr={1} color="action.active"><i className="ri-logout-box-r-line ri-xl"></i></Box>
                    <Typography variant="body1" color="textPrimary">Sign out</Typography>
                </MenuItem>
            </Menu>
        </Box>
    )
}
export default LimitedHeader;