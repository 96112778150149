import { ReactNode, useEffect, useMemo, useState } from 'react';
import './Dashboard.scss';
import { Box, Button, Grid, IconButton } from '@material-ui/core';
import SuccessAlert from '../SuccessAlert/SuccessAlert';
import { useSelector } from 'react-redux';
import { selectUserDetails, getUserSettings, getFieldMapping } from '../../store/user/user.selectors';
import { Sections, SectionPermission } from '../utils/permission/';
import { PageTitle } from '../../models/page-title';
import dashboardhome from '../../assets/dashboardIconshome.png';
import customerquick from '../../assets/dashboardIcons.quick.png';
import epodicon from '../../assets/dashboardIconsEpod.png';
import epoduploadorder from '../../assets/epoduploadorder.png';
import confirmOrderActive from '../../assets/confirmOrderActive.png';
import dashboardIconsviewallorder from '../../assets/dashboardIconsviewallorder.png';
import tmsactive from '../../assets/tmsactive.png';
import dashboardIconPod from '../../assets/dashboardIconPod.png';
import { useMutation } from '@apollo/client';
import { UpdateUserSettingsRequest, UpdateUserSettingsResponse } from '../../models/api/update-user-setings';
import { PreferenceTypes } from '../../models/preference-type-mapping';
import { UPDATE_USER_SETTINGS } from '../../graphql/Queries/updateUserSettings';
import { QuickActionTile } from './common/QuckActionTile';
import TodayPickUp from './dashboardTiles/TodaysPickup';
import ComplianceReportTile from './dashboardTiles/compliance';
import TomorrowPickUp from './dashboardTiles/TomorrowsPickup';
import RecentDeliveries from './dashboardTiles/RecentDeliveries';
import OverdueDeliveries from './dashboardTiles/OverdueDeliveries';
import MissedPickup from './dashboardTiles/MissedPickups';
import FavouriteTile from './dashboardTiles/FavouriteTile';
import PodTile from './dashboardTiles/podTile';
import { AddOrDeleteQuickActionModal } from './common/addOrDeleteQuickActionModal';
import { AddOrDeleteActivityTileModal } from './common/addOrDeleteActivityTileModal';
import PlannedLoad from './dashboardTiles/plannedLoadTile';
import TommorowLoadCollections from './dashboardTiles/TommorowLoadCollections';
import RecentLoadDeliveries from './dashboardTiles/RecentLoadDeliveries';
import OverdueLoadDeliveries from './dashboardTiles/OverdueLoadDeliveries';
import BookingToSubmitTile from './dashboardTiles/bookingToSubmitTitle';
import FeedBackSection from './common/feedback-component';
import { updateUserPreference } from '../../store/user/user.actions';
import { store } from '../../store';
import Typography from '@material-ui/core/Typography';

//Please use this when adding a new dashboard tile
export interface DashBoardTile {
    permission: string[] //Permissions for the tile
    display: boolean //true if tile is being displayed to user
    position: number //the position which the tile will be displayed. Try to avoid duplicates if possible
    url:string //url where the tile actually leads to
    value:string //the value which the tiles are compared to. Should match with values in Usersettings table
    title:string //Title that is actually displayed on screen and in dialog box
    image: string //Provide image element 
    active: boolean //false and the tile we be greyed out, but still displayed
    openInNewTab: boolean,
    component?: (props: any) => ReactNode,
}

export interface ActivityTile {
    permission: string[] //Permissions for the tile
    display: boolean //true if tile is being displayed to user
    position: number //the position which the tile will be displayed. Try to avoid duplicates if possible
    value:string //the value which the tiles are compared to. Should match with values in Usersettings table
    active: boolean //false and the tile we be greyed out, but still displayed.
    component: (props: any) => ReactNode,
    title?:string //the value displayed in the Add/Remove dialog ONLY
}

//get our dashboard tile set up
const dashboardTiles: DashBoardTile[] =  [
    {
        permission:[Sections.QA_CRT_TB],
        display: true,
        position:0,
        url:"/booking/create",
        value:"QA_CRT_TB",
        title:'Create transport booking',
        image:customerquick,
        active:true,
        openInNewTab:false,
    },
    {
        permission:[Sections.QA_CRT_SB],
        display: true,
        position:1,
        url:"/gatehouse/create",
        value:"QA_CRT_SB",
        title:'Submit truck booking',
        image:customerquick,
        active:true,
        openInNewTab:false,
    },
    
    // {
    //     permission:[Sections.QA_TMS_UP],
    //     display: true,
    //     position:1,
    //     url:"/booking/tms",
    //     value:"QA_TMS_UP",
    //     title:'Generate TMS upload',
    //     image:tmsactive,
    //     active:true,
    // },
    {
        permission:[Sections.QA_UP_FILE],
        display: true,
        position:2,
        url:"/booking/bulk/create",
        value:"QA_UP_FILE",
        title:'Upload transport bookings',
        image:dashboardhome,
        active:true,
        openInNewTab:false,
    },
    {
        permission:[Sections.QA_VIEW_BOOK],
        display: true,
        position:3,
        url:"/booking/list",
        value:"QA_VIEW_BOOK",
        title:'View all bookings',
        image:dashboardIconsviewallorder,
        active:true,
        openInNewTab:false,
    },
    // {
    //     permission:[Sections.QA_ASGN_BOOK],
    //     display: true,
    //     position:4,
    //     url:"/booking/unplanned",
    //     value:"QA_ASGN_BOOK",
    //     title:'Allocate bookings',
    //     image:allocateordersactive,
    //     active:true,
    // },
    {
        permission:[Sections.QA_EPOD_STAT],
        display: true,
        position:5,
        url:"/epod/status",
        value:"QA_EPOD_STAT",
        title:'Archived PODs',
        image:epodicon,
        active:true,
        openInNewTab:false,
    },
    {
        permission:[Sections.A_EPOD_UPLOAD_HUB],
        display: true,
        position:6,
        url:"/epod/missing",
        value:"A_EPOD_UPLOAD_HUB",
        title:'ePOD upload hub',
        image:epoduploadorder,
        active:true,
        openInNewTab:false,
    },
    {
        permission:[Sections.A_CONF_PEND_BOOK],
        display: true,
        position:7,
        url:"/booking/confirm",
        value:"A_CONF_PEND_BOOK",
        title:'Confirm pending bookings',
        image:confirmOrderActive,
        active:true,
        openInNewTab:false,
        component: (props) => <BookingToSubmitTile {...props}/>,
    },
    {
        permission:[Sections.QA_VIEW_LOADS],
        display: true,
        position:8,
        url:"/load/list",
        value:"QA_VIEW_LOADS",
        title:'View all loads',
        image:dashboardIconsviewallorder,
        active:true,
        openInNewTab:false,
    },
    {
        permission:[Sections.QA_TRUCK_BULK_UPLOAD],
        display: true,
        position:9,
        url:"/gatehouse/bulk/upload",
        value:"QA_TRUCK_BULK_UPLOAD",
        title:'Bulk truck bookings',
        image: dashboardhome,
        active:true,
        openInNewTab:false,
    },
    // {
    //     permission:[Sections.QA_GEN_REPORTS],
    //     display: true,
    //     position:9,
    //     value:"A_GEN_REPORTS",
    //     title:'Generate a 1037 report',
    //     image:tmsactive,
    //     active:true,
    // },
    {
        permission:[Sections.QA_LOAD_BULK_UP_FILE],
        display: true,
        position:10,
        url:"/load/bulk/upload",
        value:"QA_LOAD_BULK_UP_FILE",
        title:'Action loads in bulk',
        image:dashboardhome,
        active:true,
        openInNewTab:false,
    },
    {
        permission:[Sections.DBRD_GATEHOUSE],
        display: true,
        position:11,
        url:"/admin/gatehouse",
        value:"DBRD_GATEHOUSE",
        title:'Gatehouse view',
        image:confirmOrderActive,
        active:true,
        openInNewTab:false,
    },
    {
        permission:[Sections.DBRD_WATCHLIST],
        display: true,
        position:12,
        url:"/admin/watchlist",
        value:"DBRD_WATCHLIST",
        title:'All Drivers',
        image:dashboardIconsviewallorder,
        active:true,
        openInNewTab:false,
    },
    {
        permission:[Sections.GO_TO_TMS],
        display:true,
        position:13,
        url: "https://tms.woolworths.com.au/tm/framework/Frame.jsp",
        value:"GO_TO_TMS",
        title:'Go to TMS',
        image:tmsactive,
        active:true,
        openInNewTab:true,
    },
    {
        permission:[Sections.A_POD_DOWNLOAD_HUB],
        display: true,
        position:14,
        url:'',
        value:"A_POD_DOWNLOAD_HUB",
        title:'POD',
        image:dashboardIconPod,
        active:true,
        openInNewTab:false,
        component: (props) => <PodTile {...props}/>,
    },
];

const activityTitles: ActivityTile[] = [
    {
        permission: [Sections.A_TODAY_PICKUP],
        display: true,
        position: 1,
        value: "A_TODAY_PICKUP",
        active: true,
        component: (props) => <TodayPickUp {...props}/>,
        title:"Today's pickups"
    },
    {
        permission: [Sections.A_MISS_PICKUP],
        display: true,
        position: 2,
        value: "A_MISS_PICKUP",
        active: true,
        component: (props) => <MissedPickup {...props}/>,
        title:"Missed Collections"
    },
    {
        permission: [Sections.A_TOM_PICKUP],
        display: true,
        position: 3,
        value: "A_TOM_PICKUP",
        active: true,
        component: (props) => <TomorrowPickUp {...props}/>,
        title:"Tomorrow's collections"
    },
    {
        permission: [Sections.A_MISSED_DLV],
        display: true,
        position: 4,
        value: "A_MISSED_DLV",
        active: true,
        component: (props) => <OverdueDeliveries {...props}/>,
        title:"Overdue booking deliveries"
    },
    {
        permission: [Sections.A_REC_DLV],
        display: true,
        position: 5,
        value: "A_REC_DLV",
        active: true,
        component: (props) => <RecentDeliveries {...props}/>,
        title:"Recent booking deliveries"
    },
    // {
    //     permission: [Sections.A_CONF_BOOK],
    //     display: true,
    //     position: 6,
    //     value: "A_CONF_BOOK",
    //     active: true,
    //     component: (props) => <ConfirmBookingTile {...props}/>,
    //     title:"Bookings to Confirm"
    // },
    {
        permission: [Sections.A_EPOD_COMP],
        display: true,
        position: 7,
        value: "A_EPOD_COMP",
        active: true,
        component: (props) => <ComplianceReportTile {...props}/>,
        title:"ePOD overall compliance"
    },
    {
        permission: [Sections.A_PLANNED_LOADS],
        display: true,
        position: 8,
        value: "A_PLANNED_LOADS",
        active: true,
        component: (props) => <PlannedLoad {...props}/>,
        title:"Planned loads to action"
    },
    {
        permission: [Sections.A_TMWR_LOAD_COLLECTIONS],
        display: true,
        position: 9,
        value: "A_TMWR_LOAD_COLLECTIONS",
        active: true,
        component: (props) => <TommorowLoadCollections {...props}/>,
        title:"Tomorrow’s load collections"
    },
    {
        permission: [Sections.A_REC_LOAD_DLV],
        display: true,
        position: 10,
        value: "A_REC_LOAD_DLV",
        active: true,
        component: (props) => <RecentLoadDeliveries {...props}/>,
        title:"Recent load deliveries"
    },
    {
        permission: [Sections.A_MISSED_LOAD_DLV],
        display: true,
        position: 11,
        value: "A_MISSED_LOAD_DLV",
        active: true,
        component: (props) => <OverdueLoadDeliveries {...props}/>,
        title:"Overdue load deliveries"
    },
    {
        permission:[Sections.A_FAV],
        display: true,
        position: 12,
        value: "A_FAV",
        active: true,
        component: (props) => <FavouriteTile {...props}/>,
        title:"Favourites"
    },
];

const AddActionTile = (props: {open:()=>void, type: string}) => {
    return (
        <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
            <div  data-testid="add-tile" className={`dashboard-button dashboard-add-button ${props.type === 'activity' ? 'activity-add-button' : ''}`} onClick={props.open}>
                    <i className="ri-add-circle-fill ri-xl"></i>
                    <span className="dashboard-card-space dashboard-card-desc">Add / Remove</span>
            </div>
        </Grid>
    );
}

function Dashboard() {
    document.title = PageTitle.DASHBOARD_PAGE;
    const userDetails = useSelector(selectUserDetails);
    const userSettings = useSelector(getUserSettings);
    
    const [quickActionsEdit, setQuickActionsEdit] = useState<boolean>(false); 
    const [activityOverviewEdit, setActivityOverviewEdit] = useState<boolean>(false); 
    const [tileOrder, setTileOrder] = useState<DashBoardTile[]>([]);
    const [addQADialog,setQADialog] = useState<boolean>(false);
    const [addAODialog,setAODialog] = useState<boolean>(false);
    const [currentDrag,setCurrentDrag] = useState('');
    const [activityTileOrder, setActiveTileOrder] = useState<ActivityTile[]>([]);
    const [loadingTileMapping, setLoadingTileMapping] = useState<boolean>(true);
    
    const allFieldMapping = useSelector(getFieldMapping);

    const tileMapping = useMemo(() => allFieldMapping?.filter(item => item.component_desc === "DASHBOARD"), [allFieldMapping])


    const [setUserSettings] = useMutation<UpdateUserSettingsResponse,UpdateUserSettingsRequest>(UPDATE_USER_SETTINGS)

    //assign current tile order to user prefrences loaded in.
    useEffect(() => {
        if(userSettings && tileMapping){
            const copyData = [...dashboardTiles]
            userSettings.filter((item) => item.preference_type_no === PreferenceTypes.DASHBOARD).forEach((pref:any) => {
                let matchDesc:string|undefined = tileMapping?.find((element:any) => element.seq_field_id === pref.seq_field_id)?.field_desc;
                let matchIndex = copyData?.findIndex((item) => item.value === matchDesc)
                if(matchIndex !==-1){
                    copyData[matchIndex].display = convertToBool(pref.display_flag);
                    copyData[matchIndex].position = pref.display_seq;
                }
            });
            const copyActivityTiles = [...activityTitles]
            userSettings.filter((item) => item.preference_type_no === PreferenceTypes.DASHBOARD).forEach((pref:any) => {
                let matchDesc:string|undefined = tileMapping?.find((element:any) => element.seq_field_id === pref.seq_field_id)?.field_desc;
                let matchIndex = copyActivityTiles?.findIndex((item) => item.value === matchDesc)
                if(matchIndex !==-1){
                    copyActivityTiles[matchIndex].display = convertToBool(pref.display_flag);
                    copyActivityTiles[matchIndex].position = pref.display_seq;
                }
            });
            copyData.sort((a,b)=> a.position -b.position )
            copyActivityTiles.sort((a,b)=> a.position -b.position );
            setTileOrder(copyData);
            setActiveTileOrder(copyActivityTiles);
            setLoadingTileMapping(false);
        }
    }, [userSettings, tileMapping])

    const convertToBool = (flag:string) =>{
        if(flag.toLowerCase() ==='y')
            return true
        return false;
    }
    
    const handleQADoneEdit = () =>{

        let updateData:UpdateUserSettingsRequest ={request:[]}; 
        tileOrder.forEach((tile) => {
            //find sequence id of tile
            let seqId: number|undefined = tileMapping?.find((element:any) => element.field_desc === tile.value)?.seq_field_id;
            if(seqId){
                updateData?.request.push({
                    seq_field_id:seqId,
                    display_flag:tile.display ? 'Y' : 'N',
                    display_seq:tile.position,
                    preference_type_no:PreferenceTypes.DASHBOARD
            })
            }
        })
        setQuickActionsEdit(false);
        setUserSettings({variables:updateData})
        store.dispatch(updateUserPreference(updateData?.request?.map(item => ({ ...item, component_desc: 'DASHBOARD'}))));
        //update db with current changes...

    }

    const handleQAStartDrag = (e:any) =>{
        let tilesCopy = [...tileOrder];
        setCurrentDrag(tilesCopy[tilesCopy.findIndex(item => item.value ===e.target.id)].value);
        //find the current tile that it is hovering over and move it down or up 1.
    }

    //Dragging logic/swapping
    const handleQADragOver = (e:any) =>{
        let tilesCopy = [...tileOrder];
        if(e.target.id)
        {
            if(e.target.id !==currentDrag &&currentDrag)
            {
                let startPos:number = tilesCopy.find(item => item.value === currentDrag)?.position ||0;
                let endPos:number = tilesCopy[tilesCopy.findIndex(item => item.value === e.target.id)]?.position ||0;
                    tilesCopy[tilesCopy.findIndex(item => item.value === e.target.id)].position = startPos;
                    tilesCopy[tilesCopy.findIndex(item => item.value === currentDrag)].position = endPos;
                    tilesCopy.sort((a,b)=> a.position -b.position);
                    //Never allow for overlapping orders.
                    tilesCopy.forEach((item,i) =>{
                        tilesCopy[i].position=i;
                    })
                    setTileOrder(tilesCopy); 
            }
        }
    }
    
    const handleQASelection = (value:string) =>{
        setTileOrder(tileOrder.map(item => item.value === value ? { ...item, display: !item.display } : item));
    }

    const handleAOSelection = (value:string) =>{
        setActiveTileOrder(activityTileOrder.map(item => item.value === value ? { ...item, display: !item.display } : item));
    }

    const handleAODoneEdit = () =>{

        let updateData:UpdateUserSettingsRequest ={request:[]}; 
        activityTileOrder.forEach((tile) => {
            //find sequence id of tile
            let seqId: number|undefined = tileMapping?.find((element:any) => element.field_desc === tile.value)?.seq_field_id;
            if(seqId){
                updateData?.request.push({
                    seq_field_id:seqId,
                    display_flag:tile.display? 'Y' : 'N',
                    display_seq:tile.position,
                    preference_type_no:PreferenceTypes.DASHBOARD
                })
            }
        })
        setActivityOverviewEdit(false);
        setUserSettings({variables:updateData})
        store.dispatch(updateUserPreference(updateData?.request?.map(item => ({ ...item, component_desc: 'DASHBOARD'}))));
        //update db with current changes...
    }

    const handleAOStartDrag = (e:any) =>{
        let tilesCopy = [...activityTileOrder];
        setCurrentDrag(tilesCopy[tilesCopy.findIndex(item => item.value ===e.target.id)].value);
        //find the current tile that it is hovering over and move it down or up 1.
    }

    //Dragging logic/swapping
    const handleAODragOver = (e:any) =>{
        let tilesCopy = [...activityTileOrder];
        if(e.target.id)
        {
            if(e.target.id !==currentDrag &&currentDrag)
            {
                let startPos:number = tilesCopy.find(item => item.value === currentDrag)?.position ||0;
                let endPos:number = tilesCopy[tilesCopy.findIndex(item => item.value === e.target.id)]?.position ||0;
                    tilesCopy[tilesCopy.findIndex(item => item.value === e.target.id)].position = startPos;
                    tilesCopy[tilesCopy.findIndex(item => item.value === currentDrag)].position = endPos;
                    tilesCopy.sort((a,b)=> a.position -b.position);
                    //Never allow for overlapping orders.
                    tilesCopy.forEach((item,i) =>{
                        tilesCopy[i].position=i;
                    })
                    setActiveTileOrder(tilesCopy); 
            }
        }
    }

    return (
    <Box px={4} data-testid="dashboard-landing-main" className="dashboard-landing-main">
        { !loadingTileMapping && <>
            <Typography variant="h1" color="textPrimary">Dashboard</Typography>
            <Box pt={1}>
                <Typography variant="body1" color="textPrimary">Hello {userDetails?.user?.firstname}, welcome to MyPrimaryConnect!</Typography>
            </Box>
            <div className="success-alert">
                <SuccessAlert />
            </div>
            <SectionPermission permissions={[
                Sections.QA_CRT_TB,
                Sections.QA_TMS_UP,
                Sections.QA_UP_FILE,
                Sections.QA_LOAD_BULK_UP_FILE,
                Sections.QA_VIEW_BOOK,
                Sections.QA_ASGN_BOOK,
                Sections.QA_EPOD_STAT,
                Sections.A_EPOD_UPLOAD_HUB,
                Sections.QA_VIEW_LOADS,
                Sections.DBRD_GATEHOUSE,
                Sections.DBRD_WATCHLIST,
                Sections.GO_TO_TMS,
                Sections.A_POD_DOWNLOAD_HUB,
                // Sections.QA_GEN_REPORTS,

            ]}>
            <div className="dashboard-quick-action-text">
                Quick actions
                <Box style={{'float':'right',marginRight:24}}>
                {
                    quickActionsEdit
                    ?<Button data-testid="save-edit-button" variant='contained' style={{'textTransform':'none'}} size='medium' color='primary' onClick={handleQADoneEdit} >Done</Button>
                    :<IconButton onClick={()=> setQuickActionsEdit(true)} data-testid="edit-button">
                        <i className="ri-pencil-fill pencil-icon" ></i>
                    </IconButton>
                    
                }
                </Box>
            </div>
            </SectionPermission>
            <Grid container className="" data-testid="dashboard-internal">
                {tileOrder.map((tileSpecs) => (
                    <SectionPermission permissions={tileSpecs.permission} key={tileSpecs.value}>
                        {tileSpecs.component ? (
                            tileSpecs.component({
                                onDragOver: handleQADragOver,
                                onStart: handleQAStartDrag,
                                onHide: handleQASelection,
                                display: tileSpecs.display,
                                mapping: tileSpecs.value,
                                url: tileSpecs.url,
                                title: tileSpecs.title,
                                image: tileSpecs.image,
                                active: tileSpecs.active,
                                position: tileSpecs.position,
                                edit: quickActionsEdit,
                                openInNewTab: tileSpecs.openInNewTab,
                            })
                        ) : (
                            <QuickActionTile
                                onDragOver={handleQADragOver}
                                onStart={handleQAStartDrag}
                                onHide={handleQASelection}
                                display={tileSpecs.display}
                                mapping={tileSpecs.value}
                                url={tileSpecs.url}
                                title={tileSpecs.title}
                                image={tileSpecs.image}
                                active={tileSpecs.active}
                                position={tileSpecs.position}
                                edit={quickActionsEdit}
                                openInNewTab={tileSpecs.openInNewTab}
                            />
                        )}
                    </SectionPermission>
                ))}
                {quickActionsEdit && <AddActionTile open={()=> setQADialog(true)} type='quick'/>}
            </Grid>
            <SectionPermission permissions={[
                Sections.A_CONF_BOOK,
                Sections.A_EPOD_COMP,
                Sections.A_MISSED_DLV,
                Sections.A_MISS_PICKUP,
                Sections.A_REC_DLV,
                Sections.A_TODAY_PICKUP,
                Sections.A_TOM_PICKUP,
                Sections.A_FAV,
                Sections.A_PLANNED_LOADS,
                Sections.A_TMWR_LOAD_COLLECTIONS,
                Sections.A_REC_LOAD_DLV,
                Sections.A_MISSED_LOAD_DLV,
            ]}>
                <div className="dashboard-quick-action-text">
                    Activity overview
                    <Box style={{'float':'right',marginRight:24}}>
                {
                    activityOverviewEdit
                    ?<Button data-testid="save-edit-button" variant='contained' style={{'textTransform':'none'}} size='medium' color='primary' onClick={handleAODoneEdit} >Done</Button>
                    :<IconButton onClick={()=> setActivityOverviewEdit(true)} data-testid="edit-button">
                        <i className="ri-pencil-fill pencil-icon"></i>
                    </IconButton>
                    
                }
                </Box>
                </div>
            </SectionPermission>
            <Grid container className="">
                {activityTileOrder.map((tileSpecs) => {
                    return (
                        <SectionPermission permissions={tileSpecs.permission} key={tileSpecs.value}>
                            {tileSpecs.component({
                                onDragOver: handleAODragOver,
                                onStart: handleAOStartDrag,
                                onHide: handleAOSelection,
                                display: tileSpecs.display,
                                mapping: tileSpecs.value,
                                active: tileSpecs.active,
                                position: tileSpecs.position,
                                edit: activityOverviewEdit,
                            })}
                        </SectionPermission>
                    );
                })}
                {activityOverviewEdit && <AddActionTile open={()=> setAODialog(true)}  type='activity'/>}
            </Grid>
            {addQADialog &&
                <AddOrDeleteQuickActionModal
                    tiles={tileOrder}
                    onClose={() => setQADialog(false)}
                    onSave={(newOrder) => {
                        setQADialog(false);
                        setTileOrder(newOrder);
                    }}
                />}
            {addAODialog &&
                <AddOrDeleteActivityTileModal
                    tiles={activityTileOrder}
                    onClose={() => setAODialog(false)}
                    onSave={(newOrder) => {
                        setAODialog(false);
                        setActiveTileOrder(newOrder);
                    }}
                />}
            <FeedBackSection />
        </>}
    </Box>
    );
}

export default Dashboard;