import { gql } from '@apollo/client';

export const GET_ALL_CUSTOMERS = gql`
query getImpersonatedAccounts (
    $keywords: String,
    $currentPageNo: Int,
    $pageSize: Int,
    $sortBy: String,
    $sortOrder: String,
) {
    getImpersonatedAccounts (
        keywords: $keywords,
        currentPageNo: $currentPageNo,
        pageSize: $pageSize,
        sortBy: $sortBy,
        sortOrder: $sortOrder,
    ) {
        roleType
        customerCarrierPageInfo {
            totalcustcarrno
            impersonateCustomerCarrierList {
                companyno 
                companycode
                companyname 
                accounttype
                commiditytype,
                usercount,
                salesforcecustomerno,
                servicetype
            }
        }
    }
}
`;
