import React, { useEffect } from 'react'; 
import { MicroFrontend } from './Interface/MicroFrontend';
import { useHistory } from 'react-router-dom';

interface CreateAppProps {
    client: any
}

const name = 'dcAdminapp';

const { REACT_APP_DC_ADMIN_APP_HOST } = process.env;
export const CreateDcAdminApp = ({client}: CreateAppProps) => {
  const history = useHistory();

  useEffect(() => {
    return () => {
      (window as { [key: string]: any })[`unmount${name}`] && (window as { [key: string]: any })[`unmount${name}`](`${name}-container`);
    }
  }, []);

  return (
  <MicroFrontend
    history={history}
    host={REACT_APP_DC_ADMIN_APP_HOST}
    name={name}
    client={client}
    parentDivId="micro-apps"
  />
  )
};