import React, { useCallback, useEffect, useState } from 'react';
import {
    Box,
    Typography,
    Button,
    InputAdornment,
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    RadioGroup,
    FormControlLabel,
    Radio,
    DialogActions,
    LinearProgress
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextBox } from 'csp-common-ui';
import { GET_ALL_CUSTOMERS } from '../../graphql/Queries/getAllCustomers';
import './Impersonation.scss';
import { useLazyQuery, useMutation } from '@apollo/client';
import { getAccountsResponse, getAccountsRequest } from '../../models/api/get-accounts';
import { debounce } from 'lodash';
import { getUsersRequest, getUsersResponse } from '../../models/api/get-users';
import { GET_USERS } from '../../graphql/Queries/getUsers';
import { RoleType } from '../../models/user-roles';
import { IMPERSONATE } from '../../graphql/Queries/impersonate';
import { ImpersonateRequest, ImpersonateResponse } from '../../models/api/impersonate-user';
import { SectionPermission, Sections } from '../utils/permission';
interface CompanyOptions  {
    value: string,
    companyNo: string,
    tmsRef: string,
    roleType: string,
    serviceType: string,
    key: number
};

interface UserOptions  {
    value: string,
    userId: string,
    key: string
};

const ImpersonationModal = ({ onClose }: { onClose: () => void }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [options, setOptions] = useState<CompanyOptions[]>([]);
    const [companyNo, setCompanyNo] = useState<string>('');
    const [tmsRef, setTmsRef] = useState<string>('');
    const [accountType, setAccountType] = useState<string>('');
    const [serviceType, setServiceType] = useState<string>('');
    const [userId, setUserId] = useState<string>('');
    const [selectedUserOption, setSelectedUserOption] = useState<UserOptions | null>();
    
    const [userOptions, setUserOptions] = useState<UserOptions[]>([]);

    const [userTypeSelection, setUserTypeSelection] = useState<'carrier_customer' | 'internal'>('carrier_customer');


    const [getAllCustomersDetails, { data: customerDetails, loading: loadingCompanies }] = useLazyQuery<getAccountsResponse, getAccountsRequest>(GET_ALL_CUSTOMERS);
    const [getUsers, { data: users, loading: loadingUsers }] = useLazyQuery<getUsersResponse, getUsersRequest>(GET_USERS);
    const [getInternalUsers, { data: internalUsers, loading: loadingInternalUsers }] = useLazyQuery<getUsersResponse, getUsersRequest>(GET_USERS);
    const [doImpersonate] = useMutation<ImpersonateResponse, ImpersonateRequest>(IMPERSONATE);

    const getCompanies = useCallback((keyword: string) => {
        getAllCustomersDetails({
            variables: {
                keywords: keyword,
                pageSize: 100,
                currentPageNo: 0,
                sortBy: 'DESP',
                sortOrder: "ASC"
            }
        });
    }, [getAllCustomersDetails]);

    useEffect(() => {
        setUserOptions([]);
        if (userTypeSelection === 'internal' || (companyNo)) {
            const variable = {
                variables: {
                    roletype: userTypeSelection === 'internal' ? RoleType.INTERNAL : accountType,
                    tmsref: accountType?.toLowerCase() === RoleType.CARRIER.toLowerCase() ? tmsRef : "",
                    companyno: companyNo,
                    servicetype: accountType?.toLowerCase() === RoleType.CARRIER.toLowerCase() ? "" : serviceType,
                }
            };
            if (userTypeSelection === 'internal') {
                getInternalUsers(variable);
            }
            else {
                getUsers(variable);
            }
        }
        setUserId('');
        setSelectedUserOption(null);
    }, [companyNo, tmsRef, accountType, userTypeSelection, getUsers, serviceType, getInternalUsers]);

    useEffect(() => {
        setUserOptions(((userTypeSelection === 'internal' 
            ? internalUsers?.getImpersonateUsers 
            : (companyNo ? users?.getImpersonateUsers : [])
        ) ?? []).map((user) => ({
            value: `${user.firstname} ${user.lastname}`,
            userId: user.userid,
            key:  user.userid,
        })));
    }, [users, userTypeSelection, internalUsers?.getImpersonateUsers, companyNo]);

    useEffect(() => {
        if (customerDetails?.getImpersonatedAccounts.customerCarrierPageInfo?.impersonateCustomerCarrierList) {
            setOptions(customerDetails?.getImpersonatedAccounts.customerCarrierPageInfo?.impersonateCustomerCarrierList.map((account, index) => ({
                value: `${account.companyname} - ${account.accounttype?.toLowerCase() === RoleType.CARRIER.toLowerCase() ? account.companycode : account.companyno}`,
                companyNo: account.accounttype?.toLowerCase() === RoleType.CARRIER.toLowerCase() ? account.companyno : account.salesforcecustomerno,
                tmsRef: account.companycode,
                roleType: account.accounttype,
                serviceType: account.servicetype,
                key: index
            })));
        }
    }, [customerDetails]);

    useEffect(() => {
        getCompanies('');
    }, [getCompanies]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedChange = useCallback(debounce(nextValue => {
        getCompanies(nextValue);
    }, 300), []);

    const onImpersonate = () => {
        if (userId) {
            setLoading(true);
            setError(false);
            doImpersonate({
                variables: {
                    roleType: userTypeSelection === 'internal' ? RoleType.INTERNAL : accountType,
                    impersonateEmail: userId,
                    salesforcecustomerno: companyNo
                }
            }).then((response) => {
                if ( response.data?.impersonation) {
                    window.location.href = '/dashboard';
                }
                else {
                    setLoading(false);
                    setError(true);
                }
            }).catch((error) => {
                setLoading(false);
                setError(true);
                console.log('Error', error.graphQLErrors);
            });
        }
    }

    return (
        <Dialog
            open={true}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            maxWidth='sm'
            fullWidth
            
        >
            <Box className="impersonate-user-container" data-testid="impersonate-user-modal">
                {loading && !error  ? (
                    <DialogContent>
                        <Box p={3}>
                            <Box>
                                <Box>
                                    <Typography variant="body1" component="span">Impersonating session loading...</Typography>
                                </Box>
                                <Box mt={3} sx={{ width: '100%' }}>
                                    <LinearProgress />
                                </Box>
                            </Box>
                        </Box>
                    </DialogContent>
                ) : ''}
                {error ? (
                    <>
                        <DialogContent>
                            <Box display='flex'>
                                <Box color='error.main' px={1} pt={1}><i className='ri-alert-fill ri-lg' /></Box>
                                <Typography variant="body1" color="textPrimary">The impersonation session was unable to connect successfully. Please try again or close the session and try later.</Typography>
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Box py={2} px={1} display='flex' justifyContent='flex-end'>
                                <Box pr={2}>
                                    <Button onClick={onClose} color="secondary" className="text-transform-none"  variant='outlined' data-testid='retry-cancel-button'>
                                        Cancel
                                    </Button>
                                </Box>
                                <Box pr={2} >
                                    <Button
                                        onClick={() => {
                                            onImpersonate();
                                            setError(false);
                                        }}
                                        color="inherit"
                                        variant='contained'
                                        className="error-button text-transform-none"
                                        data-testid='retry-accept-button'
                                        disabled={!userId && loading}
                                        disableRipple
                                        disableTouchRipple
                                    >
                                        Try again
                                    </Button>
                                </Box>
                            </Box>
                        </DialogActions>
                    </>
                ) : ''}
                {!error && !loading ? (
                    <>
                        <DialogTitle id="alert-dialog-title" >
                            <Box display='flex' alignItems='center' justifyContent='space-between' py={1}>
                                <Typography variant='h3' color='textPrimary'>Select a user to impersonate</Typography>
                                <IconButton
                                    aria-label="close"
                                    onClick={onClose}
                                    color='secondary'
                                    size='small'
                                    data-testid='top-close-button'
                                >
                                    <i className='ri-close-line ri-xl' />
                                </IconButton>
                            </Box>
                        </DialogTitle>
                        <DialogContent className="title-box" >
                            <Box pb={2}>
                                <SectionPermission permissions={[Sections.IMPERSONATE_ADMIN_USER]} >
                                    <Box pt={2} >
                                        <RadioGroup
                                            row
                                            defaultValue={userTypeSelection}
                                            name="user-type"
                                            onChange={(e, value) => {
                                                setUserTypeSelection(value as any);
                                                setCompanyNo('');
                                                setTmsRef('');
                                                setAccountType('');
                                                setServiceType('');
                                            }}
                                        >
                                            <FormControlLabel value="carrier_customer" control={<Radio color='primary' />} label="Customer or Carrier" />
                                            <FormControlLabel value="internal" control={<Radio color='primary'  />} label="Internal User" />
                                        </RadioGroup>
                                    </Box>
                                </SectionPermission>
                                {userTypeSelection === 'carrier_customer' && (
                                    <Box pt={2} display='flex' justifyContent='space-between'>
                                        <Autocomplete
                                            id={`companyId`}
                                            size="small"
                                            options={options}
                                            getOptionLabel={(option: any) => option.value}
                                            onChange={(e, value) => {
                                                setCompanyNo(value?.companyNo as string);
                                                setTmsRef(value?.tmsRef as string);
                                                setAccountType(value?.roleType as string);
                                                setServiceType(value?.serviceType as string);
                                            }}
                                            autoComplete
                                            noOptionsText={loadingCompanies ? undefined : 'No results found'}
                                            onOpen={() => {
                                                debouncedChange('');
                                            }}
                                            renderInput={(params: any) => <TextBox
                                                {...params}
                                                onChange={(e) => {
                                                    debouncedChange(e.target.value);
                                                }}
                                                variant="outlined"
                                                fullWidth
                                                autoComplete='off'
                                                label="Company name"
                                                InputProps={{
                                                    ...(params?.InputProps ?? {}),
                                                    startAdornment:
                                                        <InputAdornment position="start">
                                                            <i className="ri-search-2-line ri-lg"></i>
                                                        </InputAdornment>
                                                }}
                                                inputProps={{
                                                    ...(params?.inputProps ?? {}),
                                                    "data-testid": "select-account",
                                                }}
                                            />}
                                            fullWidth
                                        />
                                    </Box>
                                )}
                                <Box pt={2} display='flex' justifyContent='space-between'>
                                    <Autocomplete
                                        id={`userId`}
                                        size="small"
                                        options={userOptions}
                                        value={selectedUserOption}
                                        getOptionLabel={(option: any) => option.value}
                                        onChange={(e, value) => {
                                            setUserId(value?.userId as string);
                                            setSelectedUserOption(value);
                                        }}
                                        autoComplete
                                        noOptionsText={'No results found'}
                                        loading={loadingUsers || loadingInternalUsers}
                                        renderInput={(params: any) => <TextBox
                                            {...params}
                                            variant="outlined"
                                            fullWidth
                                            autoComplete='off'
                                            label="User name"
                                            data-testid='carrier-input'
                                            InputProps={{
                                                ...(params?.InputProps ?? {}),
                                                startAdornment:
                                                    <InputAdornment position="start">
                                                        <i className="ri-search-2-line ri-lg"></i>
                                                    </InputAdornment>
                                            }}
                                            inputProps={{
                                                ...(params?.inputProps ?? {}),
                                                "data-testid": "select-user",
                                            }}
                                        />}
                                        fullWidth
                                    />
                                </Box>
                                <Box pt={2} pb={1} display='flex' justifyContent='flex-end'>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        classes={{ root: 'text-transform-none' }}
                                        data-test
                                        onClick={() => onImpersonate()}
                                        data-testid="impersonate-button"
                                        disabled={!userId || loading}
                                    >
                                        Impersonate
                                    </Button>
                                </Box>
                            </Box>
                        </DialogContent>
                    </>
                ) : ''}
            </Box>
        </Dialog>
    );
}
export default ImpersonationModal;