import { Box, Button, Checkbox, Dialog, DialogContent, DialogTitle, FormControlLabel, IconButton, Typography } from "@material-ui/core";
import { SelectBox } from 'csp-common-ui';
import CloseIcon from '@material-ui/icons/Close';
import {useEffect, useMemo, useState } from "react";
import React from "react";
import { pdf as createPDF } from '@react-pdf/renderer';
import { downloadCSV } from "../utils/download";
import { PDFDocument } from "./PdfDocument";
import dayjs from "dayjs";
import saveAs from "file-saver";
import { getBrowserName } from "../utils/browser";
import { GetFeedbackListMock } from "../../models/feedbackMock";
import './feedback.scss';
interface FeedbackActionsProps{
    open:boolean,
    feedbackData: GetFeedbackListMock[],
    currentPageStatus : {[key : number]: boolean},
    onClose: () => void,
}

export const DownloadActions = ({currentPageStatus, open, feedbackData, onClose }: FeedbackActionsProps) =>{
    const [pdf, setPdf] = useState(false);
    const [csv, setCsv] = useState(feedbackData?.length > 100);
    const [current, setCurrent] = useState('all');
    const [feedbackList,setFeedbackList] = useState(feedbackData);

    const displayMenu = useMemo(() => {
        let match = false;
        let index = 0;
        while(match || index < feedbackData?.length) {
            const selectedId = feedbackData[index]?.id
            if (!currentPageStatus[selectedId]) {
                match = true;
                break;
            }
            index = index + 1;
        }
        return match;
    }, [currentPageStatus, feedbackData]);

    const handleMenuChange = (event: React.ChangeEvent<HTMLInputElement>) =>{
        setCurrent(event.target.value);
        if(event.target.value==="current"){
            setFeedbackList(feedbackData.filter((item: { id: number }) => currentPageStatus[item?.id]));
        } else {
            setFeedbackList(feedbackData);
        }
    }

    useEffect(() => {
        if(feedbackList?.length > 100) {
          setCsv(true)
        }
    }, [feedbackList]);

    const getCurrentTimeZone = (str1: string, location: string) => {
        return new Date(
            new Date(str1).toLocaleString("en-US", { timeZone: location })
        );
    };

    const downloadPDF = async (orders: any[]) => {
        // PDF Document Will be render in a separate part without access to redux so pass all the required props
        saveAs(await createPDF(<PDFDocument
            feedbackData={orders}
          />).toBlob(), 'Feedback_Report.pdf');
    }

    const parseOrderForCSVDownload = (orders: any[]): any[] => {
        return orders.map((list) => ({
            "Rating": list.rating,
            "Feedback": list.usercomment,
            "User Id": list.userid,
            "Type": list.roletype,
            "Date": dayjs(getCurrentTimeZone(list?.datetime, list?.timeZone)).format('DD MMM YYYY') ?? '',
            "Time": dayjs(getCurrentTimeZone(list?.datetime, list?.timezone)).format('hh:mm A') ?? '',
            "TimeZone": list?.timezone.split('/')[1],
            "Device": list.deviceinfo,
            "Browser": getBrowserName(list?.systeminfo)
        }));
    }

    const handleDownload = () => {
        if (csv) {
            // Download CSV
            downloadCSV(parseOrderForCSVDownload(feedbackList), 'Feedback_Report.csv');
        }
        if (pdf && feedbackList?.length <= 100) {
            // Download PDF
            downloadPDF(feedbackList);
        }
    }

    return(
        <Dialog open={open} maxWidth='sm' fullWidth >
            <Box className='download-feedback-modal' data-testid='download-feedback-modal'>
                <DialogTitle >
                    <Box display='flex' alignItems='center' justifyContent='space-between' >
                        <Typography variant="h3">Download</Typography>
                        <IconButton size="small" onClick={onClose} data-testid='trigger-modal-close'>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>
                {(feedbackList?.length > 0) ? (
                    <DialogContent className="content">
                        <Box className="option">
                            <FormControlLabel
                                control={<Checkbox color="primary" checked={pdf} onChange={() => setPdf(!pdf)} disabled={feedbackList.length > 100}/>}
                                label={
                                    <Typography variant="body1" data-testid='trigger-download-pdf' className={feedbackList?.length > 100? 'disabledText' : ''}> 
                                        Download PDF {`(${feedbackList?.length} ${feedbackList?.length === 1 ? "Booking" : "Bookings"})`}
                                    </Typography>
                                }
                            />                            
                        </Box>
                        <Box className="option">
                            <FormControlLabel
                                control={<Checkbox color="primary" checked={csv || feedbackList?.length > 100}  onChange={() => setCsv(!csv)} />}
                                label={<Typography variant="body1" data-testid="trigger-download-csv"> Download CSV {`(${feedbackList?.length} ${feedbackList?.length === 1 ? "Booking" : "Bookings"})`}</Typography>}
                            />
                        </Box>
                        <Box display='flex' alignItems='center' justifyContent='space-between' flexWrap='wrap'>
                            <Box className="selectBoxs" pt={3}>
                                {displayMenu && (
                                    <SelectBox
                                            style={{width:178}}
                                            id="damageType"
                                            fullWidth
                                            value={current}
                                            data-testid='item-type'
                                            onChange={(event: any) => handleMenuChange(event)}
                                            options={[{label:'Current pages only', value:'current'},{label:'All pages',value:'all'}]}
                                    />
                                )}
                            </Box>
                            <Box display='flex' pt={3}>
                                <Box pr={1}><Button size="large" variant="outlined" color="secondary" onClick={onClose} data-testid='trigger-cancel'>Cancel</Button></Box>
                                <Button size="large" variant="contained" color="primary" onClick={() => {handleDownload(); onClose()}} data-testid="download-button" >
                                    Download
                                </Button>
                            </Box>
                        </Box>
                        </DialogContent>
                    ) : (
                    <DialogContent className="content">
                        <Typography variant="body1">No transport bookings are selected. Please select a transport booking</Typography>
                        <Box  display='flex' justifyContent='flex-end' pt={2}>
                            <Button size="large" variant="outlined" color="secondary" onClick={onClose}>Cancel</Button>
                        </Box>
                    </DialogContent>
                     )} 

            </Box>
        </Dialog>
    )

}