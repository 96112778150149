import { gql } from '@apollo/client';

export const LOG_ERROR = gql`
    mutation generateErrorLog(
        $reason: String,
        $usergroup: String,
        $accesspage: String,
        $username: String
    ) {
    generateErrorLog(
        reason: $reason,
        usergroup: $usergroup,
        accesspage: $accesspage,
        username: $username
    ) 
}`