import { gql } from '@apollo/client';

export const CREATE_ASYNC_BATCH_JOB = gql`
    mutation CREATE_ASYNC_BATCH_JOB(
        $docTypes: [String],
        $name: String,
        $type: AsyncJobType,
        $transportOrderIds: [String]
    ) {
		createAsyncJob(
            request: {
				type: $type,
                name: $name,
                transportOrderIds: $transportOrderIds,
                docTypes: $docTypes
        }) {
            jobId
            name
            status
            filePath
        }
    }
`;
