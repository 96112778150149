import React from 'react'
import { PageTitle } from '../../models/page-title';

export const PrePlanning = () => {
    document.title = PageTitle.PREPLANNING;
    return (
        <div data-testid='pre-planning-component'>
            Pre planning component
        </div>
    )
}
