import React from 'react';
import { useQuery } from '@apollo/client';
import { ActivityTile, ActivityTileProps } from '../common/ActivityTile';
import { omit } from 'lodash';
import missedPickups from '../../../assets/missedpickups.png';
import { GET_TRANSPORT_ORDER_COUNT } from '../../../graphql/Queries/getTransportOrderCount';
import { GetOrderListCountResponse, GetOrderListCountRequest } from '../../../models/api/get-transport-order-count';
import { OrderStatus } from '../../../models/order-status';

const MissedPickup = (props: Omit<ActivityTileProps, 'value' | 'url' | 'title' | 'image'>) => {
    const { data: bookingCount } = useQuery<GetOrderListCountResponse, GetOrderListCountRequest>(GET_TRANSPORT_ORDER_COUNT, {
        variables: {
            keywords: '',
            filterOrderStatus: [OrderStatus.CONFIRMED],
            filterOrderToLocation: [],
            filterOrderCustomer: [],
            startDate: null,
            endDate:   null,
            pickupStartDate: null,
            pickupEndDate: null,
            isDeliveryDateSelected: false,
            isPalletsSelected: false,
            isSpacesSelected: false,
            isDeclineSelected: false,
            isMissedPickupSelected: true,
            isFavourite: false,
        },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-and-network'
    });

    return (
        <ActivityTile
            value={`${bookingCount?.getOrderListCount ?? ''}`}
            title="Missed collections"
            url={`/booking/list?status=${OrderStatus.CONFIRMED}&exceptions=isMissedPickupSelected`}
            image={missedPickups}
            edit={props.edit as any}
            {...omit(props, 'edit')}
        />
    );
}
export default MissedPickup;