import TagManager from 'react-gtm-module';

declare global {
    interface Window {
        dataLayer: any;
    }
}
const tagManagerArgs = {
    gtmId: 'GTM-W42432M'
}

export const GAinit = () => {
    TagManager.initialize(tagManagerArgs)
}

export const trackLoginAnalytics = (useId: string) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'event': 'login',
        'userId': useId
    });
}