import { useState } from 'react';
import Grid from "@material-ui/core/Grid";
import { Box, Typography, Tooltip, IconButton, Button, Divider,  TextField, withStyles } from "@material-ui/core";
import { Rating } from '@material-ui/lab';
import { RatingRequest } from '../../../models/rating';
import { SAVE_REVIEW } from '../../../graphql/Queries/reviewRating';
import { getVersion } from '../../utils/browser';
import { useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';
import { Screens, useHasPermission } from '../../utils/permission/';

export const FeedBackSection = () => {
    const [onSetRating] = useMutation<RatingRequest>(SAVE_REVIEW);
    const [ratingValue, setRatingValue] = useState<number>(0);
    const [open, setopen] = useState<boolean>(false);
    const [showReview, setShowReview] = useState<boolean>(true);
    const [comments, setComments] = useState<string>('');
    const { hasScreenPermission } = useHasPermission();

    // darktooltip
    const DarkTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: theme.palette.secondary.main,
            boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(0, 0, 0, 0.08)',

        },
    }))(Tooltip);

    const updateComments = (value: string) => {
        if (value.length <= 200) {
            setComments(value);
        }
    };

    const version = getVersion();
    const handleRating = (Rating: number, comment: string) => {
        onSetRating({
            variables: {
                Rating: Rating.toString(),
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                comment: comment,
                deviceInfo: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? "mobile" : "laptop/desktop",
                datetime: new Date().toISOString(),
                systemInfo: version
            }
        }).then(() => {
            setRatingValue(0)
            setopen(false)
            setComments('')
        });
    }

    return (
        <Box className='feed-back-section-dashboard'>
            <div className="dashboard-quick-action-text">
                Help us improve
            </div>
            {showReview ?
                <Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={3} style={{ marginBottom: '32px' }}>
                        <div className="feedback-button " >
                            <Typography variant="body1">How satisfied are you with our product?
                            <DarkTooltip
                                        placement='bottom-start'
                                        data-testid='info-icon'
                                        classes={{
                                            tooltip: "info-tooltip"
                                            }}
                                        title={<Typography variant='caption'>Your feedback will help inform the future improvements to MyPrimaryConnect. For any urgent operational or technical queries, please contact Customer Service.</Typography>} >
                                        <IconButton className='info-buttonicon'>
                                            <i className="ri-information-fill ri-xs" ></i>
                                        </IconButton>
                                    </DarkTooltip>
                                </Typography>           

                            <Rating
                                name="hover-feedback"
                                className='rating'
                                value={ratingValue}
                                precision={0.5}
                                size="large"
                                onChange={(event, newValue) => {
                                    setRatingValue(newValue!)
                                    setopen(true);
                                }} />
                                {(!open && hasScreenPermission(Screens.USR_FEEDBACK, false)) &&
                                <div className="view-feedback-button">
                                    <div className="divider">
                                        <Divider/>
                                     </div>
                                    <Box mt={2}>
                                        <Button variant="contained" size="large" className="view-feedback">
                                            <Link to ='/feedback' className='view-feedback-link'>
                                                <Typography variant="subtitle1">View feedback</Typography>
                                            </Link>
                                        </Button>
                                    </Box>
                                </div>
                                }
                            {open &&
                                <div style={{ width: "100%" }}>
                                    <Typography variant="body1" className="textarea-lable">Reason for your rating (optional): </Typography>
                                    <TextField
                                        id='comments'
                                        size='small'
                                        variant='outlined'
                                        fullWidth
                                        inputProps={{
                                            'data-testid': 'comments',
                                            className: 'custom-input-box'
                                        }}
                                        multiline
                                        color='primary'
                                        minRows={5}
                                        maxRows={15}
                                        className='text-area'
                                        value={comments}
                                        onChange={(e) => updateComments(e.target.value)}
                                        helperText= {<Typography variant="body1">Maximum 200 characters</Typography>} 
                                    />
                                    <Button className="feedBackButton"
                                        color="primary"
                                        variant="contained"
                                        onClick={() => {
                                            handleRating(ratingValue, comments);
                                            setShowReview(false);
                                            setTimeout(() => {
                                                setShowReview(true);
                                            }, 3000);
                                        }}>Send feedback</Button>
                                </div>
                            }
                        </div>
                    </Grid>
                </Grid>
                :
                <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                    <div className="toggle-button " >
                   <Box display ='flex'  className="box-button "><i className="ri-checkbox-circle-fill toggle-box " />
                            <Typography className="feedback" variant="body1">Thank you for your feedback!</Typography></Box>
                    </div>
                </Grid>
            }
        </Box>
    );
}

export default FeedBackSection;