import { gql } from '@apollo/client';

export const GET_ASYNC_JOB_STATUS_BY_ID = gql`
    query GET_ASYNC_JOB_STATUS_BY_ID (
        $jobIds: [String]
    ) {
        getAsyncJobStatusById (
            jobIds: $jobIds
        ) {
            jobId
            name
            status
            filePath
        }
    }
`;
