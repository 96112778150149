import './Header.scss';
import {
    Box,
    Typography,
    AccordionSummary,
    Accordion,
    AccordionDetails,
    Button} from  '@material-ui/core';
import { Link} from "react-router-dom";
import { Tag } from 'csp-common-ui';
import { useState } from 'react';
import React from 'react';
import reactStringReplace from 'react-string-replace';

const SIZE_PER_SECTION = 10;

export interface ResultData {
    transportOrderId: string,
    matchId?: string,
    orderStatus?: string,
    total?: Number
}


interface GlobalSearchGroupAccordionProps {
    title: string,
    type: "CR" | "TB" | "LDID" | "SHID" | "PO",
    data: ResultData[],
    handleHistory: (key: string) => void,
    selected: string,
    onSelectAll: () => void,
    keyword: string,
    total: Number
}

const GlobalSearchGroupAccordion = ({ title, type, data, total, handleHistory, selected, onSelectAll, keyword}: GlobalSearchGroupAccordionProps) => {
    const [expanded, setExpanded] = useState<boolean>(true);
    return (
        <>
            {(data && data.length  && (selected === type || selected === "all")) ? (
                <Accordion
                    className={`search-accordion ${selected === type ? 'selected' : ''}`}
                    expanded={selected === type ? true : expanded}
                    onChange={(event, expanded) => setExpanded(expanded)}
                    data-testid={`search-accordion-${type}`}
                >
                    <AccordionSummary
                        expandIcon={<i className="ri-arrow-drop-down-line accordion-icon" />}
                        className="search-accordion-header"
                        data-testid="search-accordion-header"
                    >
                        {selected === type
                            ? (
                                <Box display="flex" alignItems="center">
                                    <Box display="flex" alignItems="center">
                                        <Typography variant='subtitle1' color="textPrimary">{title}&nbsp;&nbsp;</Typography>
                                        <Typography variant='body1' color="textPrimary">({total})</Typography>
                                    </Box>
                                </Box>
                            ) : (
                                <Box display="flex" alignItems="center" justifyContent="space-between" flexGrow={1}>
                                    <Box display="flex" alignItems="center">
                                        <Typography variant='subtitle1' color="textPrimary">{title}&nbsp;&nbsp;</Typography>
                                        <Typography variant='body1' color="textPrimary">
                                            ({ total > SIZE_PER_SECTION ? `${SIZE_PER_SECTION} of ${total}` : total})
                                        </Typography>
                                    </Box>
                                    <Box width={120}>
                                        {(total > SIZE_PER_SECTION && expanded)? (
                                            <Button
                                                disableRipple
                                                className="see-all-button"
                                                color="secondary"
                                                size="small"
                                                variant='contained'
                                                onClick={() => onSelectAll()}
                                                data-testid="see-all-button"
                                            >
                                                See all
                                            </Button>
                                        ): ''}
                                    </Box>
                                </Box>
                            )
                        }
                    </AccordionSummary>
                    <AccordionDetails className="search-accordion-content">
                        <Box data-testid="search-result-list">
                            {(selected === type ? data : data.slice(0, SIZE_PER_SECTION)).map((item, index) => (
                                <Link
                                    key={index}
                                    className="search-result-item" 
                                    to={type === "LDID"
                                        ? `/load/details/${item?.matchId}`
                                        : `/booking/detail/${item?.transportOrderId}`}
                                    color="textPrimary"
                                    onClick={() => handleHistory(type === "TB" ? item.transportOrderId : `TB-${item.transportOrderId}-${type}-${item.matchId}`)}
                                >
                                    <Box className="search-result-list--item-box" key={`${item.transportOrderId}-${item.matchId}`} display="flex" alignItems="center">
                                        {selected === type ? (
                                            <Box width={24} display="flex" alignItems="center" justifyContent="center">
                                                <Typography className="result-index" >{index + 1}</Typography>
                                            </Box>
                                        ): ''}
                                        <Box width={40} display="flex" alignItems="center">
                                            <Typography variant='subtitle1' color="textPrimary">{type}#</Typography>
                                        </Box>
                                        <Box flexGrow="1" pl={3} pr={2} className="search-order-link">
                                            <Typography variant='body2' color="textPrimary">
                                                {reactStringReplace(item.matchId, keyword, (match) => <b>{match}</b>)}
                                            </Typography>
                                        </Box>
                                        <Box width={150} minWidth={150} display="flex" alignItems="center"><Tag closeAction={false} type={item?.orderStatus?.toLowerCase() as any} size="small" /></Box>
                                    </Box>
                                </Link>
                            ))}
                        </Box>
                    </AccordionDetails>
                </Accordion>
            ): ''}
        </>
    )
}
export default GlobalSearchGroupAccordion;