import { createReducer } from "@reduxjs/toolkit";
import { Order } from "../../models/order";
import { closeCreateOrderSuccess, createOrderSuccess, updateOrderSuccess } from "./order.actions";

export interface LatestOrder extends Order {
    orderType?: String;
}

export interface OrderState {
    latestOrder?: LatestOrder;
}

export const initialState: OrderState = {
    latestOrder: undefined
};

export const orderReducer = createReducer(
    initialState,
    (builder) => {
        builder
        .addCase(createOrderSuccess, (state, action) => {
            return {
                ...state,
                latestOrder: {...action.payload, orderType: 'create'}
            }
        })
        .addCase(closeCreateOrderSuccess, (state, action) => {
            return {
                ...state,
                latestOrder: undefined
            }
        })
        .addCase(updateOrderSuccess, (state, action) => {
            return {
                ...state,
                latestOrder: {...action.payload, orderType: 'update'}
            }
        })
    }
);