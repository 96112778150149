export const Sections = {
	NAV_PANE_DBRD: 'NAV_PANE_DBRD',
    NAV_PANE_CRTTO: 'NAV_PANE_CRTTO',
    NAV_PANE_VIEWTO: 'NAV_PANE_VIEWTO',
    NAV_PANE_CUST_ALL_LOADS:'NAV_PANE_CUST_ALL_LOADS',
    NAV_PANE_CONFIRM: 'NAV_PANE_CONFIRM',
    NAV_PANE_PLAN: 'NAV_PANE_PLAN',
    NAV_PANE_TMSFILE: 'NAV_PANE_TMSFILE',
    NAV_PANE_REPORT: 'NAV_PANE_REPORT',
    NAV_PANE_CAN_REPORT: 'NAV_PANE_CAN_REPORT',
    NAV_PANE_DEC_REPORT:'NAV_PANE_DEC_REPORT',
    NAV_PANE_SETT: 'NAV_PANE_SETT',
    QA_CRT_TB: 'QA_CRT_TB',
    QA_CRT_SB: 'QA_CRT_SB',
    QA_TRUCK_BULK_UPLOAD: 'QA_TRUCK_BULK_UPLOAD',
    QA_UP_FILE: 'QA_UP_FILE',
    QA_ASGN_BOOK: 'QA_ASGN_BOOK',
    QA_ORD_PLT_CRD: 'QA_ORD_PLT_CRD',
    QA_SUBMIT_PREPLAN: 'QA_SUBMIT_PREPLAN',
    QA_VIEW_BOOK: 'QA_VIEW_BOOK',
    QA_TMS_UP: 'QA_TMS_UP',
    QA_EPOD_STAT: 'QA_EPOD_STAT',
    A_TODAY_PICKUP: 'A_TODAY_PICKUP',
    A_TOM_PICKUP: 'A_TOM_PICKUP',
    A_MISS_PICKUP: 'A_MISS_PICKUP',
    A_REC_DLV: 'A_REC_DLV',
    A_MISSED_DLV: 'A_MISSED_DLV',
    A_CONF_BOOK: 'A_CONF_BOOK',
    A_EPOD_COMP: 'A_EPOD_COMP',
    A_EPOD_UPLOAD_HUB: 'A_EPOD_UPLOAD_HUB',
    A_FAV: 'A_FAV',
    QA_VIEW_LOADS: 'QA_VIEW_LOADS',
    QA_GEN_REPORTS: 'QA_GEN_REPORTS',
    QA_LOAD_BULK_UP_FILE: 'QA_LOAD_BULK_UP_FILE',
    A_PLANNED_LOADS: 'A_PLANNED_LOADS',
    A_TMWR_LOAD_COLLECTIONS: 'A_TMWR_LOAD_COLLECTIONS',
    A_REC_LOAD_DLV: 'A_REC_LOAD_DLV',
    A_MISSED_LOAD_DLV: 'A_MISSED_LOAD_DLV',
    MY_PROFILE_ADMIN_USERS: 'MY_PROFILE_ADMIN_USERS',
    NAV_PANE_ADMIN_ACCOUNT_MGMT: 'NAV_PANE_ADMIN_ACCOUNT_MGMT',
    NAV_PANE_ADMIN_TMS_UPLOAD: 'NAV_PANE_ADMIN_TMS_UPLOAD',
    IMPERSONATE_USERS: 'IMPERSONATE_USERS',
    IMPERSONATE_ADMIN_USER: 'IMPERSONATE_ADMIN_USER',
    GLOBAL_SEARCH: 'GLOBAL_SEARCH',
    A_CONF_PEND_BOOK: 'A_CONF_PEND_BOOK',
    NAV_PANE_ADMIN_USER_LIST: 'NAV_PANE_ADMIN_USER_LIST',
    NAV_PANE_LEAD_TIMES: 'NAV_PANE_LEAD_TIMES',
    NAV_PANE_VIEW_ALL_LOADS: 'NAV_PANE_VIEW_ALL_LOADS',
    NAV_PANE_CANCEL_LOAD_REPORT: 'NAV_PANE_CANCEL_LOAD_REPORT',
    NAV_PANE_COMPLIANCE_LOAD_REPORT: 'NAV_PANE_COMPLIANCE_LOAD_REPORT',
    NAV_PANE_TAX_INVOICE_REPORT: 'NAV_PANE_TAX_INVOICE_REPORT',
    NAV_PANE_TRUCK_CRT_BOOKING:'NAV_PANE_TRUCK_CRT_BOOKING',
    NAV_PANE_TRUCK_ALL_BOOKINGS:'NAV_PANE_TRUCK_ALL_BOOKINGS',
    NAV_PANE_TRUCK_BULK_CRT:'NAV_PANE_TRUCK_BULK_CRT',
    LOAD_BULK_UP_FILE: 'LOAD_BULK_UP_FILE',
    NAV_PANE_GATEHOUSE: 'NAV_PANE_GATEHOUSE',
    NAV_PANE_WATCHLIST: 'NAV_PANE_WATCHLIST',
    DBRD_GATEHOUSE: 'DBRD_GATEHOUSE',
    DBRD_WATCHLIST: 'DBRD_WATCHLIST',
    GO_TO_TMS: 'GO_TO_TMS',
    SWITCH_ACCOUNTS_HEADER_OPTION: 'SWITCH_ACCOUNTS_HEADER_OPTION',
    A_POD_DOWNLOAD_HUB: 'A_POD_DOWNLOAD_HUB',
    DOWNLOAD_PLT_LBL_ALERT: 'DOWNLOAD_PLT_LBL_ALERT'
}