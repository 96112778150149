import { gql } from '@apollo/client';

export const GET_TRANSPORT_ORDER_COUNT = gql`
query(
  $keywords: String
  $filterOrderStatus: [String]
  $filterOrderToLocation: [String]
  $filterOrderCustomer: [String]
  $startDate: String
  $endDate: String
  $pickupStartDate: String
  $pickupEndDate: String
  $isDeliveryDateSelected: Boolean
  $isPalletsSelected: Boolean
  $isSpacesSelected: Boolean
  $isDeclineSelected: Boolean
  $isMissedPickupSelected: Boolean
  $isFavourite: Boolean
  ) {
    getOrderListCount(
      keywords: $keywords
      filterOrderStatus: $filterOrderStatus
      filterOrderToLocation: $filterOrderToLocation
      filterOrderCustomer: $filterOrderCustomer
      startDate: $startDate
      endDate: $endDate
      pickupStartDate: $pickupStartDate
      pickupEndDate: $pickupEndDate
      isDeliveryDateSelected: $isDeliveryDateSelected
      isPalletsSelected: $isPalletsSelected
      isSpacesSelected: $isSpacesSelected
      isDeclineSelected: $isDeclineSelected
      isMissedPickupSelected: $isMissedPickupSelected
      isFavourite: $isFavourite
    ) 
}
`;