import React, { useEffect } from 'react'; 
import { MicroFrontend } from './Interface/MicroFrontend';
import { useHistory } from 'react-router-dom';

interface CreateGatehouseAppProps {
    client: any
}
const name = 'gatehouseapp';

const { REACT_APP_GATEHOUSE_APP_HOST: AppHost } = process.env;
export const FetchGatehouseApp = ({client}: CreateGatehouseAppProps) => {
  const history = useHistory();

  useEffect(() => {
    return () => {
      (window as { [key: string]: any })[`unmount${name}`] && (window as { [key: string]: any })[`unmount${name}`](`${name}-container`);
    }
  }, []);

  return (
  <MicroFrontend
    history={history}
    host={AppHost}
    name={name}
    client={client}
    parentDivId="micro-apps"
  />
  )
};