import React, { useCallback, useEffect } from 'react';
import { History } from 'history';
import { Manifest } from './Manifest';
import { ApolloClient } from '@apollo/client';

interface MicroFrontendProps {
    name: string
    host: string | undefined
    history: History
    client: ApolloClient<any>
    parentDivId: string,
}

export const MicroFrontend = ({ name, host, parentDivId, history, client }: MicroFrontendProps) => {
    // debugger;
    const renderMicroFrontend = useCallback(() => {
        if (!document.getElementById(`${name}-container`)) {
            let main = document.createElement("main");
            main.setAttribute("id", `${name}-container`);
            document.getElementById(parentDivId)?.append(main);
        }
        (window as { [key: string]: any })[`render${name}`] && (window as { [key: string]: any })[`render${name}`](`${name}-container`, history, client);
    }, [client, history, name, parentDivId]);

    useEffect(() => {
        const scriptId = `micro-frontend-script-${name}`;
        if (document.getElementById(scriptId)) {
          renderMicroFrontend();
          return;
        }
        fetch(`${host}/asset-manifest.json`)
        .then(res => res.json())
        .then((manifest: Manifest) => {
            manifest.entrypoints.forEach(x => {
                if(x.endsWith(".js")){
                    const script = document.createElement('script');
                    script.id = scriptId;
                    script.crossOrigin = '';
                    script.src = `${host}/${x}`;
                    script.onload = renderMicroFrontend;
                    document.head.appendChild(script);        
                }
                if(x.endsWith(".css")){
                    const link = document.createElement('link');
                    link.rel = 'stylesheet';
                    link.href = `${host}/${x}`;
                    document.head.appendChild(link);
                }
            });
        });

        // return () => {
        //     (window as { [key: string]: any })[`unmount${name}`] && (window as { [key: string]: any })[`unmount${name}`](`${name}-container`);
        // } 
    
    }, [host, name, renderMicroFrontend]);


    
    
    return <div />;
}