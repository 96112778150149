import { gql } from '@apollo/client';

export const GET_ORDER_DETAILS = gql`
    query GET_ORDER_DETAILS($transportOrderId: String) {
        getTransportOrderById(transportOrderId: $transportOrderId) {
            transportOrderId ,
            customerReference,
            poNumber,
            orderStatus,
            commodityTypeMdId,
            bookingType,
            customerNameMd,
            supplierNumber,
            deliveryDetails {
                destination {
                    locationMdDesc,
                    locationMdPostcode,
                    locationMdCity,
                    locationMdStreetAddress,
                    locationMdDistrictId,
                    locationMdCountryId,
                    destinationDetail,
                    deliveryDate,
                    deliveryTime,
                },
                origin {
                    locationMdDesc,
                    locationMdPostcode,
                    locationMdCity,
                    locationMdStreetAddress,
                    locationMdDistrictId,
                    locationMdCountryId,
                    originDetail,
                    pickUpDate,
                    pickUpTime,
                }
            },
            items {
                transportOrderItemId,
                productTypeMdId,
                cartonQuantity,
                weight,
                volume,
                temperature,
                product,
            },
            palletsQuantity,
            spacesQuantity,
            totalVolume,
            totalWeight,
            transportOrderLegs {
                transportOrderLoadId,
                destination {
                    locationMdId
                    locationMdDesc,
                    locationMdPostcode,
                    locationMdCity,
                    locationMdStreetAddress,
                    locationMdDistrictId,
                    locationMdCountryId,
                    deliveryDate,
                    deliveryTime,
                },
                origin {
                    locationMdId
                    locationMdDesc,
                    locationMdPostcode,
                    locationMdCity,
                    locationMdStreetAddress,
                    locationMdDistrictId,
                    locationMdCountryId,
                    pickUpDate,
                    pickUpTime,
                },
                carrierMdDesc,
                loadSeqNum,
                loadStatus,
                firstLeg,
                lastLeg,
            }
        }
    }
`;
