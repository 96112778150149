export const eventsToListen = {
    CREATE_ORDER_SUCCESS: 'CREATE_ORDER_SUCCESS',
    UPDATE_ORDER_SUCCESS: 'UPDATE_ORDER_SUCCESS',
    CREATED_ASYNC_JOB: 'CREATED_ASYNC_JOB',
    LOG_ERROR: 'LOG_ERROR'
};

export const eventsToDispatch = {
    LOG_ERROR: 'LOG_ERROR',
    CREATED_ASYNC_JOB: 'CREATED_ASYNC_JOB',
};