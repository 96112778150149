import React, { useCallback, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { ActivityTile, ActivityTileProps } from '../common/ActivityTile';
import { omit } from 'lodash';
import Favouriteicon from '../../../assets/Favouriteicon.png';
import { GET_TRANSPORT_ORDER_COUNT } from '../../../graphql/Queries/getTransportOrderCount';
import { GetOrderListCountResponse, GetOrderListCountRequest } from '../../../models/api/get-transport-order-count';
import { FavouriteModal } from '../common/FavouriteModal';
import { useSelector } from 'react-redux';
import { RoleType } from '../../../models/user-roles';
import { selectUserDetails } from '../../../store/user/user.selectors';
import { GetLoadListCountRequest, GetLoadListCountResponse } from '../../../models/api/get-load-list-count';
import { GET_LOAD_LIST_COUNT } from '../../../graphql/Queries/getLoadListCount';

const FavouriteTile = (props: Omit<ActivityTileProps, 'value' | 'url' | 'title' | 'image' |'onClick' | 'hideArrow'>) => {
    
    const userObj = useSelector(selectUserDetails);
    const userRoleType = userObj.user?.roletype;
    const [favouriteModal, setFavouriteModal] = useState(false);

    const [getAllLoads, { data: bookingLoadCount, loading }] = useLazyQuery<GetLoadListCountResponse, GetLoadListCountRequest >(GET_LOAD_LIST_COUNT, {
        variables: {
            filterCommodity: [],
            filterOrigins: [],
            filterCustomers: [],
            loadCollectionStartDate: '',
            loadCollectionEndDate: '',
            loadDeliveryStartDate: '',
            filterDestinations: [],
            filterLoadStatus: [],
            loadDeliveryEndDate: '',
            isFavourite: true,
            isTenderActioned: false,
            searchKeyWord: '',
            isPodNotSubmitted: false,
            missingPodDocument: false,
            variationsInPallets: false,
            variationsInSpaces: false,
            collectionDateTimeNotConfirmed: false
        }, 
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-and-network'
    });
    const [getAllPos, { data: bookingCount, loading: loadingPo }] = useLazyQuery<GetOrderListCountResponse, GetOrderListCountRequest>(GET_TRANSPORT_ORDER_COUNT, {
        variables: {
            keywords: '',
            filterOrderStatus: [],
            filterOrderToLocation: [],
            filterOrderCustomer: [],
            startDate: null,
            endDate:   null,
            pickupStartDate: null,
            pickupEndDate: null,
            isDeliveryDateSelected: false,
            isPalletsSelected: false,
            isSpacesSelected: false,
            isDeclineSelected: false,
            isMissedPickupSelected: false,
            isFavourite: true,
        }, 
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-and-network'
    });
    
    const getAllData = useCallback(() => {
        if (RoleType.INTERNAL === userRoleType) {
            getAllPos();
            getAllLoads();
        } else if (RoleType.CARRIER === userRoleType) {
            getAllLoads();
        } else if (RoleType.CUSTOMER === userRoleType) {
            getAllPos();
        }
    }, [getAllLoads, getAllPos, userRoleType])

    useEffect(() => {
        getAllData();
    }, [getAllData]);

    const handleModal = () => {
        setFavouriteModal(!favouriteModal);
    }
    const totalCount = (bookingCount?.getOrderListCount ?? 0 ) + (bookingLoadCount?.getLoadListCount ?? 0);

    return (
        <>
            <ActivityTile 
                value={(loading || loadingPo) ? '' : `${totalCount ?? ''}`} 
                title="Favourites" 
                data-testid="test-tile-value"
                onClick={totalCount > 0 ? () => handleModal() : undefined}
                url={totalCount === 0 ? (
                    RoleType.CARRIER === userRoleType 
                        ? '/load/list?favourites=isFavourite'
                        : '/booking/list?favourites=isFavourite'
                ) : undefined}
                image={Favouriteicon} 
                hideArrow={true}
                edit={props.edit as any} 
                {...omit(props, 'edit')}
            />
            {favouriteModal &&
                <FavouriteModal onClose={() => {
                    setFavouriteModal(false);
                    getAllData();
                }}
                currentTab={(bookingCount?.getOrderListCount && bookingCount?.getOrderListCount > 0) ? 0 : 1}
                /> 
            } 
        </>
    );
            
}
export default  FavouriteTile;