import './Header.scss';
import {
    Box,
    Typography,
    IconButton,
    Divider,
    Button} from  '@material-ui/core';
import './helpDrawer.scss';


const HelpDrawer = ({ onClose }: { onClose: () => void }) => {
    return (
        <Box className="help-drawer-popup">
            <Box sx={{ width: 360 }} role="presentation">  
                <Box display="flex" alignItems='center' className="header-drawer">
                    <Box><IconButton className="icon"><i className="ri-question-fill" style={{ color: 'secondary', backgroundColor: "secondary" }} /></IconButton></Box>
                    <Typography variant="h3" component='span' color="textPrimary">Help</Typography>
                    <IconButton size="small" onClick={onClose} style={{ marginLeft: "auto", marginRight: "20px" }} >
                        <i className="ri-close-fill icon"></i>
                    </IconButton>
                </Box>
                <Divider />
                <Box px={2} className='feedback-section'>
                    <Typography variant="subtitle1" color="textPrimary">Resources</Typography>
                    <Button 
                        className ="training-button"
                        color='secondary'
                        variant="contained"    
                        href="https://docs.google.com/spreadsheets/d/1YxKECMULykXG7AP_WnHfyXdl7oJdTKlc-DrL651NoIQ/edit#gid=182565173" rel="noreferrer" target="_blank"         
                    >
                        <Box display='flex' alignItems='center'>
                            <Typography variant="subtitle2" >Visit our training site</Typography>
                            <i className="ri-external-link-line iconexternal"></i> 
                            </Box>

                    </Button>        
                </Box>
                <Divider />
                <Box px={2} className="drawer-info">
                    <Typography variant="subtitle1" color="textPrimary">Reach out</Typography>
                    <Typography variant="body2" color="textPrimary">If you have any questions please contact Primary Connect Customer Service on:</Typography>
                    <Box>
                        <Typography variant="body2" className="contact-number">
                            <i className="ri-phone-fill"></i>
                            <span> <a href="tel:1300000000">1800 PRIMARY</a> </span>
                        </Typography>
                    </Box>
                    <Box >
                        <Typography variant="body2" className="contact-number" >
                            <i className="ri-mail-fill" />
                            <span > <a href="mailto:customerservice@primaryconnect.com.au">customerservice@primaryconnect.com.au</a></span>
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
export default HelpDrawer;