import { gql } from '@apollo/client';

export const FAVOURITE_FLAG_LOAD = gql`
    mutation flagorUnFlagLoad(
        $isFavourite: Boolean!,
        $loadIds: [String]
        ) {
            flagorUnFlagLoad(
            isFavourite: $isFavourite,
            loadIds: $loadIds
        )
    }
`;